import React from 'react';

// const documentRoot = "/documents/"
// const documents = [
//   { title: "Document title 1",fileName: "sample.pdf" },
//   { title: "Document title 2", fileName: "document_2.pdf"},
//   { title: "Document title 3", fileName: "document_3.pdf"},
//   { title: "Instrukcja obsługi urządzenia XYZ", fileName: "instrukcja_xyz.pdf" },
//   { title: "Raport z badania rynku", fileName: "raport_rynkowy.pdf" },
//   { title: "Prezentacja na konferencję", fileName: "prezentacja_konferencja.pdf" },
//   { title: "Umowa o współpracy", fileName: "umowa_wspolpracy.pdf" },
//   { title: "CV Jana Kowalskiego", fileName: "cv_kowalski.pdf" },
//   { title: "Protokoł z zebrania", fileName: "protokol_zebrania.pdf" },
//   { title: "Faktura VAT nr 123456", fileName: "faktura_123456.pdf" },
//   { title: "Plan marketingowy", fileName: "plan_marketingowy.pdf" },
//   { title: "Specyfikacje techniczne", fileName: "specyfikacje_techniczne.pdf" },
//   { title: "Zezwolenie na budowę", fileName: "zezwolenie_na_budowe.pdf" },
//   { title: "Regulamin świadczenia usług", fileName: "regulamin.pdf" },
//   { title: "Instrukcja BHP", fileName: "instrukcja_bhp.pdf" },
//   { title: "Opinia prawna", fileName: "opinia_prawna.pdf" },
//   { title: "Harmonogram projektu", fileName: "harmonogram_projektu.pdf" },
//   { title: "Zaproszenie na ślub", fileName: "zaproszenie_slub.pdf" },
//   { title: "Podanie o pracę", fileName: "podanie_o_prace.pdf" },
//   { title: "Wyniki badań", fileName: "wyniki_badan.pdf" },
//   { title: "Mapa dojazdu", fileName: "mapa_dojazdu.pdf" },
//   { title: "Karta gwarancyjna", fileName: "karta_gwarancyjna.pdf" },
//   { title: "Tłumaczenie przysięgłe", fileName: "tlumaczenie.pdf" }
// ]


const FileLink = ({documents, documentRoot}) => {
  return (
    <div className="documents-wrapper">
      <h5>Dokumenty do pobrania</h5>
      <ul>
      {documents.map(document => (
        <li key={document.fileName}>
          <a href={documentRoot + document.fileName} target="_blank" rel="noopener noreferrer" download>
            {document.title}
          </a>
        </li>
      ))}
    </ul>
    </div>
  );
}

export default FileLink;