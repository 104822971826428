// src/pages/Home.js
import React from 'react';
import Jumbotron from '../Components/Jumbotron/Jumbotron.';
import MetaTags from '../Components/MetaTags';

const images = [
  // { url: '/images/example_0.jpeg', alt: 'First image' },
  { url: '/images/okladka_1.jpg', alt: 'Wakacyjne zdjęcie' },
  { url: '/images/home/home_carousel_1.webp', alt: 'Wakacyjne zdjęcie' },
  { url: '/images/home/home_carousel_2.webp', alt: 'Wakacyjne zdjęcie' },
  { url: '/images/home/home_carousel_3.webp', alt: 'Wakacyjne zdjęcie' },
  { url: '/images/home/home_carousel_4.webp', alt: 'Wakacyjne zdjęcie' }
];

function Home() {
  return (
    <div>
      <MetaTags title="Strona Główna | Biuro Podróży Rajza" description="Strona Główna Biura Podróży Rajza" />
      <div className="container-jumbotron">
        <Jumbotron images={images}/>  
      </div>


      <div className="container">
        <div className="row">
          <h2 className="mt-3 mb-3">Biuro Podróży RAJZA</h2>
          <p>Tak! To nadal MY!</p>
          <div className="col-sm-12 col-lg-6">
            <img src='/images/home/home_carousel_0.jpg' alt="Zdjęcie zespołu biura podróży Rajza" className="home-content-image"/>
          </div>
          <div className="col-sm-12 col-lg-6">
            <p> Niby nowe – a jednak tak dobrze Wam znane, w niezmienionym składzie - Wiola, Anita, Basia wraz z pracownikami - Ewą i Izą. Z przyjemnością pragniemy Wam przedstawić rezultat naszej intensywnej pracy - <b>Biuro Podróży RAJZA.</b> </p>  
            <p>W ścisłej współpracy z <b>Biurem Podróży KULIG TRAVEL</b>, pod czujnym okiem i patronatem Janusza Kuliga, zostałyśmy docenione i uprzywilejowane, co zaowocowało <b>przekazaniem biura</b> w nasze ręce - tak powstało Biuro Podróży RAJZA s.c. Lata tradycji, wspólnych wyjazdów, godziny spotkań oraz rozmów telefonicznych – jesteśmy świadome odpowiedzialności i zaufania jakim nas obdarzyliście. Z naszym byłym już szefem u boku będziemy się czuć bezpiecznie i spokojnie. Mamy nadzieję, że Wy również!</p>
            <p>A skąd sam pomysł na nazwę? RAJZA - bo jesteśmy stąd, ze Śląska! Większość z Was wie, co to słowo oznacza - podróż, wycieczkę. Wybór wydał się oczywisty!  Zmieniona nazwa biura - ale te same tradycje, doświadczenie oraz profesjonalizm. Zapraszamy do zapoznania się z nową ofertą - cieszymy się, że będziemy Wam towarzyszyć w tak ważnym czasie jakim jest Wasz wypoczynek!</p>
          </div>
         
          
          
          
          <p>Specjalizujemy się we wczasach <b>autokarowych nad Bałtykiem</b> (w tym turnusach zdrowotnych z zabiegami), <b>w górach</b> oraz <b>innych regionach Polski</b>, a także <b>wyjazdach zagranicznych</b> (Chorwacja, Czarnogóra, Bośnia i Hercegowina, Bułgaria). Istotną część oferty stanowić będą <b>wycieczki krajowe i zagraniczne</b>, które przygotowujemy przede wszystkim dla Klientów grupowych (terminy oraz szczegóły wyjazdów ustalamy indywidualnie, zgodnie z Waszym życzeniem). Dla Klientów chcących otrzymać ofertę „2 w 1” stworzyłyśmy wyjazdy zawierające w sobie wypoczynek oraz aktywne zwiedzanie.</p>
          <p>Naszym kochanym Seniorom możemy zaproponować wygodne położenie ośrodków, udogodnienia na miarę ich potrzeb oraz wcześniej wspomniane turnusy zdrowotne z zabiegami rehabilitacyjnymi. Nie zapominamy również o turnusach wakacyjnych - z większym zapleczem atrakcji dla milusińskich. </p>
          <p>Jako organizator wyjazdów możemy dopasować propozycję wypoczynku do indywidualnych życzeń Klienta - bo przecież to Wasz pomysł na spędzenie upragnionych wakacji jest najlepszy! Jesteśmy do Waszej dyspozycji!</p>
          <p className="text-end mt-3 mb-5 fs-4">Zespół Biura Podróży RAJZA</p>
        </div>
      </div>
      


    </div>
  );
}

export default Home;