// src/pages/PolitykaPrywatnosci.js
import React from 'react';
import FileLink from '../Components/FileLink/FileLink';
import MetaTags from '../Components/MetaTags';

const documentRoot = "/documents/owu/"
const documents = [
  { title: "Ogólne Warunki Uczestnictwa",fileName: "Ogolne_Warunki_Uczestnictwa_BP_RAJZA.pdf" }
]

function OWU() {
  return (
    <div className="container">
      <MetaTags title="Ogólne Warunki Uczestnictwa | Biuro Podróży Rajza" description="Ogólne Warunki Uczestnictwa" />

      <div className="row">
        <div className="pt-3 pb-5 regulations">



          <h1>OGÓLNE WARUNKI UCZESTNICTWA W IMPREZACH TURYSTYCZNYCH ORGANIZOWANYCH PRZEZ Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń</h1>

          <FileLink
            documentRoot={documentRoot}
            documents={documents}
          />

          <h3 className="h5">I. POSTANOWIENIA OGÓLNE </h3>
          <ol>
            <li>Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń (dalej: BP RAJZA) jest organizatorem turystyki w rozumieniu ustawy z dnia 24 listopada 2017r. o imprezach turystycznych i powiązanych usługach turystycznych (Dz. U. z 2017 r., poz. 2361). </li>
            <li>BP RAJZA jako organizator turystyki posiada wpis w rejestrze organizatorów turystyki i przedsiębiorców ułatwiających nabywanie powiązanych usług turystycznych prowadzonym przez Marszałka Województwa pod numerem <b>1805.</b></li>
            <li>
              Przez użyte w Warunkach Uczestnictwa określenia należy rozumieć:
              <br />
              <ol className="letters">
                <li>warunki uczestnictwa - warunki uczestnictwa w imprezach turystycznych organizowanych przez BP RAJZA;</li>
                <li>Podróżny - każdy, kto chce zawrzeć umowę o udział w imprezie turystycznej z BP RAJZA lub jest uprawniony do podróżowania na podstawie zawartej w zakresie stosowania Ustawy;</li>
                <li>Agent - przedsiębiorca, który na podstawie umowy agencyjnej sprzedaje lub oferuje do sprzedaży imprezy turystyczne utworzone przez BP RAJZA; </li>
                <li>umowa - umowa o imprezę turystyczną zawierana między BP RAJZA a Podróżnym,</li>
                <li>strony umowy - BP RAJZA oraz Podróżny zawierający umowę;</li>
                <li>impreza - impreza turystyczna organizowana przez BP RAJZA;</li>
                <li>umowa - zgłoszenie - Umowa o udział w imprezie turystycznej (formularz przygotowany przez BP RAJZA, na podstawie, którego Podróżny składa BP RAJZA ofertę zawarcia umowy o imprezę);</li>
                <li>Ustawa - ustawa z dnia 24 listopada 2017 r. o imprezach turystycznych i powiązanych usługach turystycznych (Dz. U. z 2017 r., poz. 2361);</li>
                <li>trwały nośnik - materiał lub narzędzie umożliwiające Podróżnemu lub BP RAJZA przechowanie informacji kierowanych osobiście do niego, w sposób umożliwiający dostęp do informacji w przyszłości przez odpowiedni czas do celów, jakim te informacje służą, i które pozwalają na odtworzenie przechowywanych informacji w niezmienionej postaci;</li>
                <li>niedogodności - niewykonanie lub nienależyte wykonanie usług turystycznych objętych imprezą turystyczną </li>
                <li>nieuniknione i nadzwyczajne okoliczności - sytuacja pozostająca poza kontrolą strony powołującej się na taką sytuację, której skutków nie można było uniknąć, nawet gdyby podjęto wszelkie rozsądne działania;</li>
              </ol>
            </li>
          </ol>



          <h3 className="h5">II. ZAWARCIE UMOWY I OBOWIĄZKI INFORMACYJNE </h3>
          <ol>
            <li>Informacje zamieszczone na stronie internetowej BP RAJZA oraz inne informacje przekazane Podróżnemu przed złożeniem przez niego BP RAJZA oferty zawarcia umowy stanowią jedynie zaproszenie do zawarcia umowy w rozumieniu art. 71 ustawy z dnia 23 kwietnia 1964r. Kodeks cywilny (t. jedn. Dz.U. z 2017r., poz. 459 ze zm.), chyba że co innego wynika wyraźnie z ich treści.</li>
            <li>Do zawarcia umowy dochodzi w drodze złożenia oferty przez Podróżnego i jej przyjęcia przez BP RAJZA. </li>
            <li>W przypadku zawarcia umowy na rzecz osoby (osób) trzeciej (trzecich), osoba zawierająca umowę wskazuje tę osobę (osoby) w chwili składania oferty zawarcia umowy lub w terminie określonym w umowie - zgłoszeniu. </li>
            <li>Przed zawarciem umowy o imprezę BP RAJZA udziela Podróżnemu standardowych informacji o których mowa w art. 39 ust. 1 i 3 ustawy za pomocą odpowiedniego formularza informacyjnego. Otrzymanie tych informacji Podróżny potwierdza na umowie - zgłoszeniu</li>
            <li>BP RAJZA lub Agent, w przypadku gdy impreza turystyczna jest sprzedawana za jego pośrednictwem, udziela Podróżnemu zanim Podróżny ten zwiąże się jakąkolwiek umową o udział w imprezie turystyczną lub odpowiadającą jej ofertą, informacji o których mowa w art. 40 ust. 1 Ustawy. BP RAJZA lub wspomniany Agent przed zawarciem umowy o udział w imprezie turystycznej informuje Podróżnego o każdej zmianie tych informacji.  </li>
            <li>Niezwłocznie po zawarciu umowy BP RAJZA lub Agent udostępnia Podróżnemu na trwałym nośniku kopię umowy lub potwierdzenie jej zawarcia. </li>
            <li>Podróżny jest uprawniony do żądania kopii umowy o udział w imprezie turystycznej w postaci papierowej, jeżeli umowa ta została zawarta w jednoczesnej fizycznej obecności stron.   </li>
            <li>Przed rozpoczęciem imprezy BP RAJZA dostarcza Podróżnemu niezbędne pokwitowania, vouchery i bilety oraz informacje o planowanym czasie wyjazdu i - w stosownych przypadkach - terminie odprawy, a także o planowanych godzinach przystanków pośrednich, połączeń transportowych i przyjazdu. </li>
            <li>Podróżny, który zawarł umowę poza lokalem przedsiębiorstwa w rozumieniu art. 2 pkt 2 ustawy z dnia 30 maja 2014r. o prawach konsumenta (Dz. U. z 2017r., poz. 683) otrzymuje kopię lub potwierdzenie zawarcia umowy o udział w imprezie turystycznej w postaci papierowej lub, jeżeli wyrazi na to zgodę, na innym trwałym nośniku. </li>
          </ol>

          
          <h3 className="h5">III. CENA</h3>
          <ol>
            <li>W ramach informacji o których mowa w pkt. II.5 BP RAJZA lub Agent udziela Podróżnemu informacji o cenie imprezy łącznie z podatkami oraz w razie potrzeby z wszelkimi dodatkowymi opłatami i innymi kosztami lub - jeśli nie można zasadnie oczekiwać, aby koszty te zostały obliczone przed zawarciem umowy o udział w imprezie turystycznej - informację o rodzaju dodatkowych kosztów, którymi Podróżny może zostać obciążony. Jeżeli przed zawarciem umowy nie zostały zrealizowane wskazane w poprzednim punkcie obowiązki informacyjne, Podróżny nie ponosi tych opłat lub kosztów. </li>
            <li>W przypadku braku zapłaty w terminie określonym w umowie - zgłoszeniu BP RAJZA może odstąpić od umowy, co rodzi skutki określone w pkt. VI.1 niniejszych Warunków Uczestnictwa.</li>
            <li>Cena imprezy obejmuje VAT. </li>
          </ol>

          
          <h3 className="h5">IV. ZMIANA WARUNKÓW UMOWY, IMPREZA ZASTĘPCZA I ROZWIĄZANIE UMOWY</h3>
          <ol>
            <li>
              Po zawarciu umowy podwyższenie cen jest możliwe wyłącznie jako bezpośredni skutek zmiany:
              <br />
              <ol>
                <li>ceny przewozów pasażerskich wynikających ze zmiany kosztów paliwa lub innych źródeł zasilania;</li>
                <li>wysokości podatków lub opłat od usług turystycznych objętych umową o udział w imprezie turystycznej, nałożonych przez podmioty, które nie biorą bezpośredniego udziału w realizacji imprezy turystycznej, w tym podatków turystycznych, opłat lotniskowych lub opłaty za wejście na pokład i zejście na ląd w portach oraz na lotniskach;</li>
                <li>kursów walut mających znaczenie dla danej imprezy turystycznej.</li>
              </ol>
            </li>
            <li>Cena ustalona w umowie nie może być podwyższona w okresie 20 dni przed datą rozpoczęcia imprezy turystycznej.</li>
            <li>BP RAJZA powiadamia Podróżnego na trwałym nośniku, w sposób jasny i zrozumiały, o zmianie ceny oraz uzasadnia podwyżkę i wskazuje sposób jej obliczenia.</li>
            <li>Jeżeli umowa o udział w imprezie turystycznej przewiduje możliwość podwyższenia ceny, Podróżny ma prawo do obniżki ceny odpowiadającej obniżeniu kosztów, o których mowa w pkt. IV.1, które nastąpiło po zawarciu umowy, a przed rozpoczęciem imprezy turystycznej.</li>
            <li>W przypadku obniżenia ceny BP RAJZA może odliczyć od zwrotu należnego podróżnemu rzeczywiste koszty obsługi. Na żądanie podróżnego BP RAJZA przedstawia dowód poniesionych kosztów obsługi.</li>
            <li>
              BP RAJZA przed rozpoczęciem imprezy turystycznej może dokonać jednostronnie zmiany warunków umowy o udział w imprezie turystycznej także w przypadku, gdy łącznie spełnia następujące warunki:
              <br />
              <ol>
                <li>zmiana jest nieznaczna;</li>
                <li>poinformuje Podróżnego o zmianie w sposób jasny, zrozumiały i widoczny, na trwałym nośniku.</li>
              </ol>
            </li>
            <li>
              W sytuacjach gdy BP RAJZA przed rozpoczęciem imprezy turystycznej:
              <br />
              <ol>
                <li>jest zmuszony zmienić główne właściwości usług turystycznych, o których mowa w art. 40 ust. 1 pkt 1 Ustawy, lub</li>
                <li>nie może spełnić specjalnych wymagań o których Podróżny powiadomił BP RAJZA i na które strony umowy wyraziły zgodę, lub</li>
                <li>proponuje podwyższenie ceny przekraczające 8% całkowitej ceny imprezy turystycznej zgodnie z art. 45 ust. 2  Ustawy <br />
                - niezwłocznie powiadamia o tym Podróżnego na trwałym nośniku. BP RAJZA może jednocześnie zaoferować Podróżnemu zastępczą imprezę turystyczną, w miarę możliwości o tej samej lub wyższej jakości.</li>
              </ol>
            </li>
            <li>
              W powiadomieniu, o którym mowa w pkt. IV.7, BP RAJZA w sposób jasny, zrozumiały i widoczny informuje Podróżnego o:
              <br />
              <ol>
                <li>zmianach warunków umowy o udział w imprezie turystycznej oraz o ewentualnym wpływie tych zmian na cenę;</li>
                <li>rozsądnym terminie, w którym Podróżny poinformuje BP RAJZA o swojej decyzji, o której mowa w pkt. IV.9;</li>
                <li>odstąpieniu od umowy o udział w imprezie turystycznej za zwrotem wszystkich wniesionych wpłat i bez obowiązku wniesienia opłaty za odstąpienie od umowy w przypadku braku odpowiedzi Podróżnego we wskazanym terminie;</li>
                <li>zastępczej imprezie turystycznej oraz jej cenie, jeśli jest oferowana.</li>
              </ol>
            </li>
            <li>
              Podróżny w terminie wyznaczonym przez BP RAJZA informuje go, że:
              <br />
              <ol>
                <li>przyjmuje proponowaną zmianę umowy o udział w imprezie turystycznej albo</li>
                <li>odstępuje od umowy o udział w imprezie turystycznej za zwrotem wszystkich wniesionych wpłat i bez obowiązku wniesienia opłaty za odstąpienie, albo</li>
                <li>odstępuje od umowy o udział w imprezie turystycznej oraz przyjmuje zastępczą imprezę turystyczną.</li>
              </ol>
            </li>
            <li>Jeżeli zmiany umowy o udział w imprezie turystycznej lub zastępcza impreza turystyczna, o których mowa w pkt. IV.9, prowadzą do obniżenia jakości lub kosztów imprezy turystycznej, Podróżny jest uprawniony do odpowiedniego obniżenia ceny.</li>
            <li>W przypadku gdy umowa o udział w imprezie turystycznej zostanie rozwiązana zgodnie z pkt IV.8.3 albo IV.9.2 BP RAJZA nie później niż w terminie 14 dni od dnia rozwiązania umowy o udział w imprezie turystycznej zwraca wpłaty dokonane przez Podróżnego lub w jego imieniu. Podróżny jest zwolniony od wniesienia opłaty za odstąpienie od umowy o udział w imprezie turystycznej. Przepisy art. 50 ust. 2-8 Ustawy stosuje się odpowiednio.</li>
          </ol>

          
          <h3 className="h5">V. PRZENIESIENIE UPRAWNIEŃ I PRZEJĘCIE OBOWIĄZKÓW PODRÓŻNEGO</h3>
          <ol>
            <li>Podróżny może bez zgody BP RAJZA przenieść na osobę spełniającą warunki udziału w imprezie turystycznej wszystkie przysługujące mu z tytułu umowy o udział w imprezie uprawnienia, jeżeli jednocześnie osoba ta przejmuje wszystkie wynikające z tej umowy obowiązki. </li>
            <li>Przeniesienie uprawnień i przejęcie obowiązków o których mowa w pkt. V.1 jest skuteczne wobec BP RAJZA, jeżeli Podróżny zawiadomi go o tym na trwałym nośniku w rozsądnym terminie. Zawiadomienie złożone nie później niż 7 dni przed rozpoczęciem imprezy turystycznej uważa się w każdym przypadku za złożone w rozsądnym terminie. </li>
            <li>Jeżeli przeniesienie uprawnień i przejęcie obowiązków o których mowa w pkt. V.1, będzie wiązać się dla BP RAJZA z dodatkowymi kosztami, żądając ich zapłaty ma on obowiązek wykazać je Podróżnemu. Koszty te muszą być zasadne i nie mogą przekraczać rzeczywistych kosztów poniesionych przez BP RAJZA na skutek przeniesienia umowy o udział w imprezie turystycznej. </li>
            <li>Za nieuiszczoną część ceny imprezy turystycznej oraz koszty poniesione przez BP RAJZA w wyniku zmiany Podróżnego uczestniczącego w imprezie turystycznej, Podróżny i osoba przejmująca jego uprawnienia odpowiadają solidarnie. </li>
          </ol>

          
          <h3 className="h5">VI. ODSTĄPIENIE OD UMOWY </h3>
          <ol>
            <li>Podróżny może odstąpić od umowy o udział w imprezie turystycznej w każdym czasie przed jej rozpoczęciem. W przypadku odstąpienia od umowy Podróżny jest zobowiązany <b>do zapłaty opłaty odpowiadającej cenie imprezy turystycznej pomniejszonej o zaoszczędzone koszty lub wpływy z tytułu alternatywnego wykorzystania danych usług turystycznych.</b> Opłata ta podlega potrąceniu z wpłaty dokonanej przez podróżnego. Na żądanie podróżnego <b>BP RAJZA</b> uzasadnia wysokość opłat za odstąpienie od umowy o udział w imprezie turystycznej.</li>
            <li>Podróżny może odstąpić od umowy o udział w imprezie turystycznej przed rozpoczęciem imprezy turystycznej bez ponoszenia opłaty za odstąpienie w przypadku wystąpienia nieuniknionych i nadzwyczajnych okoliczności występujących w miejscu docelowym lub jego najbliższym sąsiedztwie, które mają znaczący wpływ na realizację imprezy turystycznej lub przewóz podróżnych do miejsca docelowego. Podróżny może żądać wyłącznie zwrotu wpłat dokonanych z tytułu imprezy turystycznej, bez odszkodowania lub zadośćuczynienia w tym zakresie.</li>
            <li>
              BP RAJZA podaje informacyjnie, że ukształtowane historycznie średnie opłaty za odstąpienie od Umowy - względem całkowitej wartości zawartej Umowy - kształtują się następująco: 
              <br />
              <ul>
                <li>do 40 dni przed datą wyjazdu - stała opłata manipulacyjna 160,00 zł/os.,</li>
                <li>od 39 do 21 dni przed datą wyjazdu - do 30% ceny Imprezy,</li>
                <li>od 20 do 8 dni przed datą wyjazdu - do 70% ceny Imprezy,</li>
                <li>od 7 do 2 dni przed datą wyjazdu - do 80% ceny Imprezy,</li>
                <li>na 1 dzień przed datą wyjazdu i mniej i w dniu wyjazdu - do 90% ceny Imprezy.</li>
              </ul>
            </li>
            <li>
              BP RAJZA może rozwiązać umowę o udział w imprezie turystycznej i dokonać pełnego zwrotu Podróżnemu wpłat dokonanych z tytułu imprezy turystycznej, bez dodatkowego odszkodowania lub zadośćuczynienia, jeżeli:
              <br />
              <ol>
                <li>
                  liczba osób, które zgłosiły się do udziału w imprezie turystycznej, jest mniejsza niż minimalna liczba osób podana w umowie o udział w imprezie turystycznej, a BP RAJZA powiadomił Podróżnego o rozwiązaniu umowy o udział w imprezie turystycznej w terminie określonym w umowie o udział w imprezie turystycznej, lecz nie później niż na
                  <br />
                  <ol className="letters">
                    <li>20 dni przed rozpoczęciem imprezy turystycznej trwającej ponad 6 dni, </li>
                    <li>7 dni przed rozpoczęciem imprezy turystycznej trwającej 2-6 dni,</li>
                    <li>48 godzin przed rozpoczęciem imprezy turystycznej trwającej krócej niż 2 dni, lub </li>
                  </ol>
                </li>
                <li>BP RAJZA nie może zrealizować umowy o udział w imprezie turystycznej z powodu nieuniknionych i nadzwyczajnych okoliczności i powiadomił Podróżnego o rozwiązaniu umowy o udział w imprezie turystycznej niezwłocznie przed rozpoczęciem imprezy turystycznej.</li>
              </ol>
            </li>
            <li>BP RAJZA dokonuje zwrotu poniesionych opłat i wpłat, o których mowa w pkt.IV.2 i 3, w terminie 14 dni od dnia rozwiązania umowy o udział w imprezie turystycznej. Przepis art. 47 ust. 2 Ustawy stosuje się odpowiednio.</li>
            <li>Konsument, który zawarł umowę o udział w imprezie turystycznej poza lokalem przedsiębiorstwa w rozumieniu art. 2 pkt 2 ustawy z dnia 30 maja 2014r. o prawach konsumenta, może w terminie 14 dni od dnia jej zawarcia odstąpić od niej bez podawania przyczyny i ponoszenia kosztów, chyba że negocjacje ustne, na podstawie których została zawarta umowa, były prowadzone w oparciu o wcześniejsze zamówienie złożone przez konsumenta. Przepisy art. 30, art. 31, art. 32 ust. 1 i 2, art. 35, art. 37 i art. 38 pkt 1 ustawy z dnia 30 maja 2014r. o prawach konsumenta stosuje się odpowiednio.</li>
          </ol>
          

          
          <h3 className="h5">VII. REALIZACJA UMOWY O UDZIAŁ W IMPREZIE TURYSTYCZNEJ I ODPOWIEDZIALNOŚĆ BP RAJZA</h3>
          <ol>
            <li>Podróżny ma prawo do wszystkich świadczeń wynikających z umowy. </li>
            <li>BP RAJZA ponosi odpowiedzialność za wykonanie usług turystycznych objętych umową o udział w imprezie turystycznej, bez względu na to, czy usługi te mają być wykonane przez BP RAJZA, czy przez innych dostawców usług turystycznych. </li>
            <li>Podróżny zawiadamia BP RAJZA niezwłocznie, w miarę możliwości w trakcie trwania imprezy turystycznej, z uwzględnieniem okoliczności danej sprawy, o stwierdzeniu niezgodności. </li>
            <li>Jeżeli którakolwiek z usług turystycznych nie jest wykonywana zgodnie z umową o udział w imprezie turystycznej, BP RAJZA usuwa niezgodność, chyba że jest to niemożliwe albo wiąże się z kosztami, które są niewspółmiernie wysokie w stosunku do zakresu niezgodności i wartości usług turystycznych, których one dotyczą. W przypadku nieusunięcia niezgodności postanowienia art. 50 Ustawy stosuje się odpowiednio. </li>
            <li>Jeżeli BP RAJZA nie usunie niezgodności w rozsądnym terminie wyznaczonym przez Podróżnego, Podróżny może dokonać tego sam i wystąpić o zwrot poniesionych, niezbędnych wydatków. Podróżny nie jest zobowiązany do wyznaczenia terminu, jeżeli BP RAJZA odmówi usunięcia niezgodności lub gdy z okoliczności wynika, że niezgodność powinna być usunięta niezwłocznie. </li>
            <li>Jeżeli BP RAJZA w czasie trwania danej imprezy turystycznej nie wykonuje przewidzianych w umowie o udział w imprezie turystycznej usług stanowiących istotną część tej imprezy, jest obowiązany, bez obciążania Podróżnego dodatkowymi kosztami, wykonać w ramach tej imprezy odpowiednie świadczenie zastępcze, również w przypadku, gdy uzgodniony w umowie o udział w imprezie turystycznej powrót podróżnego do miejsca rozpoczęcia podróży nie został zapewniony. </li>
            <li>Jeżeli jakość świadczeń zastępczych jest niższa od jakości usługi określonej w programie imprezy turystycznej, BP RAJZA przyznaje podróżnemu odpowiednie obniżenie ceny imprezy turystycznej. </li>
            <li>Podróżny może odrzucić zaproponowane świadczenie zastępcze tylko wtedy, gdy nie są one porównywalne z tym, co zostało uzgodnionego w umowie o udział w imprezie turystycznej, lub jeżeli przyznana obniżka ceny jest nieodpowiednia. </li>
            <li>W przypadku gdy niezgodność istotnie wpływa na realizację imprezy turystycznej, a BP RAJZA nie zdoła usunąć tej niezgodności w rozsądnym terminie wyznaczonym przez Podróżnego, Podróżny ma prawo do rozwiązania umowy o udział w imprezie turystycznej bez opłaty za jej rozwiązanie. </li>
            <li>Jeżeli impreza turystyczna obejmuje transport Podróżnych, BP RAJZA zapewnia powrót Podróżnego do kraju równoważnym środkiem transportu niezwłocznie i bez obciążania Podróżnego dodatkowymi kosztami. Przepisy art. 50 Ustawy stosuje się odpowiednio. </li>
            <li>Jeżeli nie jest możliwe zaproponowanie świadczeń zastępczych lub Podróżny je odrzuci zgodnie z pkt. VII.8, wówczas Podróżny jest uprawniony do uzyskania obniżenia ceny lub odszkodowania, lub zadośćuczynienia, bez rozwiązania umowy o udział w imprezie turystycznej. </li>
            <li>W przypadku gdy niemożliwe jest zapewnienie powrotu Podróżnego do kraju zgodnie z umową o udział w imprezie turystycznej z powodu nieuniknionych i nadzwyczajnych okoliczności, BP RAJZA ponosi koszty niezbędnego zakwaterowania Podróżnego, w miarę możliwości o kategorii równoważnej do określonej w umowie o udział w imprezie turystycznej, przez okres 3 nocy. Ograniczenia czasu zapewniania podróżnemu niezbędnego zakwaterowania nie stosuje się w przypadku osób o ograniczonej sprawności ruchowej, zgodnie z definicją zawartą w art. 2 lit. a rozporządzenia (WE) nr 1107/2006 Parlamentu Europejskiego i Rady z dnia 5 lipca 2006r. w sprawie praw osób niepełnosprawnych oraz osób o ograniczonej sprawności ruchowej podróżujących drogą lotniczą (Dz.Urz. UE L 204 z 26.07.2006, str. 1), oraz wszelkich osób im towarzyszących, kobiet w ciąży i osób w wieku poniżej 18 lat bez opieki, jak również osób wymagających szczególnej opieki medycznej, pod warunkiem że BP RAJZA został powiadomiony o sytuacji tych osób co najmniej 48 godzin przed rozpoczęciem imprezy turystycznej.</li>
            <li>Uprawnienie, o którym mowa w pkt. VII.12, nie wyłącza stosowania przepisów korzystniejszych w tym zakresie. </li>
            <li>BP RAJZA nie może powoływać się na nieuniknione i nadzwyczajne okoliczności w celu ograniczenia odpowiedzialności, o której mowa w pkt. VII.12 i 13, jeżeli przedsiębiorca świadczący usługi transportowe nie może powoływać się na takie okoliczności na podstawie innych przepisów.  </li>
            <li>Podróżnemu przysługuje obniżka ceny za każdy okres, w trakcie którego stwierdzono niezgodność, chyba że została ona spowodowana wyłącznym działaniem lub zaniechaniem podróżnego.</li>
            <li>Podróżnemu przysługuje odszkodowanie lub zadośćuczynienie za poniesione szkody lub krzywdy, których doznał w wyniku niezgodności. BP RAJZA niezwłocznie wypłaca odszkodowanie lub zadośćuczynienie.</li>
            <li>
              Podróżnemu nie przysługuje odszkodowanie lub zadośćuczynienie za niezgodność w przypadku, gdy BP RAJZA udowodni, że:
              <br />
              <ol>
                <li>winę za niezgodność ponosi podróżny;</li>
                <li>winę za niezgodność ponosi osoba trzecia, niezwiązana z wykonywaniem usług turystycznych objętych umową o udział w imprezie turystycznej,
                a niezgodności nie dało się przewidzieć lub uniknąć;</li>
                <li>niezgodność została spowodowana nieuniknionymi i nadzwyczajnymi okolicznościami.</li>
              </ol>
            </li>
            <li>Roszczenia, o których mowa w pkt. VII.15 i 16, przedawniają się z upływem 3 lat.</li>
            <li>Jeżeli przepisy szczególne ograniczają zakres albo warunki, na jakich odszkodowanie lub zadośćuczynienie jest wypłacane przez dostawcę usług turystycznych, które są częścią imprezy turystycznej, takie same ograniczenia stosuje się do BP RAJZA.</li>
            <li>W przypadkach innych, niż określone pkt. VII.19, odszkodowanie dla Podróżnego od BP RAJZA jest ograniczone do trzykrotności ceny imprezy turystycznej, o ile odszkodowanie to nie dotyczy szkody na osobie lub szkody spowodowanej umyślnie lub w wyniku niedbalstwa.</li>
            <li>Obniżka ceny, o której mowa w pkt.VII.15, odszkodowanie lub zadośćuczynienie, o których mowa w pkt. VII.16, podlegają odpowiedniemu obniżeniu
            w przypadku skorzystania przez Podróżnego z obniżenia ceny lub odszkodowania, o których mowa w rozporządzeniu (WE) nr 261/2004 Parlamentu Europejskiego i Rady z dnia 11 lutego 2004 r. ustanawiającym wspólne zasady odszkodowania i pomocy dla pasażerów w przypadku odmowy przyjęcia na pokład albo odwołania lub dużego opóźnienia lotów, uchylającym rozporządzenie (EWG) nr 295/91 (Dz.Urz. UE L 46 z 17.02.2004, str. 1), rozporządzeniu (WE) nr 1371/2007 Parlamentu Europejskiego i Rady z dnia 23 października 2007 r. dotyczącym praw i obowiązków pasażerów w ruchu kolejowym (Dz.Urz. UE L 315 z 03.12.2007, str. 14), rozporządzeniu Parlamentu Europejskiego i Rady (WE) nr 392/2009 z dnia 23 kwietnia 2009r. w sprawie odpowiedzialności przewoźników pasażerskich na morskich drogach wodnych z tytułu wypadków (Dz.Urz. UE L 131 z 28.05.2009, str. 24), rozporządzeniu (UE) nr 1177/2010 i rozporządzeniu (UE) nr 181/2011 albo w innych przepisach.</li>
            <li>Podróżny może kierować wiadomości, żądania lub skargi związane z realizacją imprezy turystycznej bezpośrednio do Agenta, za pośrednictwem którego została ona nabyta. Agent niezwłocznie przekazuje wiadomości, żądania lub skargi, o których mowa w zd. 1, od Podróżnego do BP RAJZA. Wiadomość, żądanie lub skargę wniesione do agenta turystycznego w danym dniu uważa się za wniesione z tym dniem do organizatora.</li>
            <li>
              BP RAJZA niezwłocznie udziela odpowiedniej pomocy Podróżnemu, który znalazł się w trudnej sytuacji, w tym w okolicznościach, o których mowa w pkt. VII.12. Pomoc ta polega w szczególności na udzieleniu:
              <br />
              <ol>
                <li>odpowiednich informacji dotyczących świadczeń zdrowotnych, władz lokalnych oraz pomocy konsularnej;</li>
                <li>Podróżnemu pomocy w skorzystaniu ze środków porozumiewania się na odległość, w tym ze środków komunikacji elektronicznej oraz w skorzystaniu ze świadczeń zastępczych.</li>
              </ol>
              BP RAJZA może żądać opłaty z tytułu udzielenia pomocy, o której mowa w zd. 1, jeżeli trudna sytuacja powstała z wyłącznej winy umyślnej podróżnego lub w wyniku jego rażącego niedbalstwa. Wysokość opłaty nie może przewyższać rzeczywistych kosztów poniesionych przez BP RAJZA.
            </li>
            <li>BP RAJZA oraz Agent ponosi odpowiedzialność odszkodowawczą wobec Podróżnego za swoje błędy w rezerwacji, chyba że odpowiedzialność za błąd ponosi podróżny lub błąd ten powstał na skutek nieuniknionych i nadzwyczajnych okoliczności.</li>
            <li>Podróżny nie może zrzec się w całości lub w części praw wynikających z ustawy.</li>
            <li>Podróżny zobowiązuje się przestrzegać regulamin swojego miejsca zakwaterowania podczas imprezy turystycznej.</li>
          </ol>

          
          <h3 className="h5">VIII. UBEZPIECZENIE PODRÓŻNEGO</h3>
          <ol>
            <li>W przypadku <b>wyjazdów krajowych</b> Organizator ubezpiecza Podróżnych w <b>SIGNAL IDUNA Polska TU S.A.</b> na mocy <b>umowy generalnej nr 528057</b> z dnia 17.09.2024r. od następstw nieszczęśliwych wypadków w zakresie określonym w Ogólnych Warunkach Ubezpieczenia Następstw Nieszczęśliwych Wypadków na terenie Rzeczypospolitej Polskiej, zatwierdzonych uchwałą nr 67/Z/2024 Zarządu SIGNAL IDUNA Polska Towarzystwo Ubezpieczeń S.A. z dnia 07.08.2024r., umowa ubezpieczenia NNW obejmuje świadczenie na wypadek trwałego uszczerbku na zdrowiu (NWI), świadczenie na wypadek śmierci (NWS) do kwoty 10 000 zł; <b>uwaga - ubezpieczenie nie obejmuje m.in. kosztów assistance </b> (np. transport chorego do szpitala bądź innej placówki medycznej, apteki itp.). Odpłatność za niniejszą usługę ponosi Podróżny;</li>
            <li>
              W przypadku wyjazdów za granicę Organizator ubezpiecza Podróżnych w Signal Iduna Polska TU S.A. na mocy umowy generalnej nr 528057 z dnia 17.09.2024r. i zgodnie z przepisami ustawy o usługach turystycznych z dnia 29 sierpnia 1997r. w zakresie określonym w Ogólnych Warunkach Ubezpieczenia Bezpieczne Podróże, zatwierdzonych uchwałą nr 42/Z/2021 Zarządu SIGNAL IDUNA Polska Towarzystwo Ubezpieczeń S.A. z dnia 19.07.2021r., umowa ubezpieczenia Bezpieczne Podróże obejmuje: Bagaż 1 000 zł, NNW 15 000 zł, Koszty Leczenia 30 000 eur i Ratownictwa oraz Assisatnace, w tym nielimitowany transport do kraju zamieszkania, 
              <br />

              <ol>
                <li>Ogólne Warunki Ubezpieczenia Bezpieczne Podróże dostępne są w BP RAJZA. Ochrona ubezpieczeniowa jest rozszerzona o choroby przewlekłe i nowotworowe w zakresie standard. </li>
                <li>Podróżny podpisujący umowę oświadcza za siebie i za innych uczestników imprezy (w zakresie posiadanego upoważnienia), iż każdy Ubezpieczony wyraża zgodę na udostępnienie SIGNAL IDUNA przez podmioty udzielające świadczeń zdrowotnych dokumentacji medycznej oraz przez NFZ nazw i adresów świadczeniodawców (a także zwalnia lekarzy w kraju i za granicą z tajemnicy lekarskiej) w celu ustalenia prawa do świadczenia z zawartej umowy ubezpieczenia i wysokości tego świadczenia. Zgoda jest ważna pod warunkiem zaistnienia zdarzenia ubezpieczeniowego. Dane Ubezpieczonych będą udostępnione do SIGNAL IDUNA Polska TU S.A. z siedzibą przy ul. Siedmiogrodzka 9, 01-204 Warszawa, w celu realizacji umowy ubezpieczenia. Pełna informacja dotycząca przetwarzania danych przez SIGNAL IDUNA Polska TU S.A. znajduje się na stronie <a href="www.signal-iduna.pl/przetwarzanie-danych-osobowych.">www.signal-iduna.pl/przetwarzanie-danych-osobowych </a>.</li>
              </ol>
            </li>
            <li>Podróżny w przypadku zaistnienia zdarzenia ubezpieczeniowego powinien skontaktować się z <b>Centralą Alarmową SIGNAL IDUNA: +48 22 864 55 26,  fax +48 22 575 95 75, sms +48 661 000 888.</b></li>
            <li><b>Podróżny może wykupić ubezpieczenia dodatkowe takie jak: </b> <br /> 
              - doubezpieczenie w ramach Kosztów Leczenia czyli zwiększenie kwoty ubezpieczenia we własnym zakresie lub u Organizatora <br />
              - <b>ubezpieczenie od kosztów rezygnacji</b> z imprezy lub przerwania imprezy we własnym zakresie lub poprzez dokonanie dopłaty u Organizatora. Umowa ubezpieczenia może być zawarta w terminie <b>najpóźniej do 14 dni od daty zawarcia umowy uczestnictwa w imprezie turystycznej (wpłaty całości lub części zaliczki)</b>. W przypadku, gdy do rozpoczęcia imprezy turystycznej lub dnia rozpoczęcia podróży jest mniej niż 30 dni, umowę ubezpieczenia można zawrzeć <b>jedynie w dniu zawarcia umowy</b> uczestnictwa w imprezie turystycznej.    
            </li>
          </ol>

          
          <h3 className="h5">IX. ZABEZPIECZENIE FINANSOWE NA WYPADEK NIEWYPŁACALNOŚCI </h3>
          <ol>
            <li>
              Stosownie do postanowień art. 42 ust. 8 ustawy o imprezach turystycznych i powiązanych usługach turystycznych BP RAJZA jako organizator turystyki potwierdza posiadanie zabezpieczenia, na wypadek swojej niewypłacalności, w zakresie:
              <br />
              <ol className="letters">
                <li>pokrycia kosztów kontynuacji imprezy turystycznej lub kosztów powrotu do kraju, obejmujących w szczególności koszty transportu i zakwaterowania, w tym także w uzasadnionej wysokości koszty poniesione przez podróżnych, w przypadku gdy organizator turystyki, wbrew obowiązkowi, nie zapewnia tej kontynuacji lub tego powrotu,</li>
                <li>pokrycia wpłat wniesionych tytułem zapłaty za imprezę turystyczną, w przypadku gdy z przyczyn dotyczących organizatora turystyki lub osób, które działają w jego imieniu, impreza turystyczna nie została lub nie zostanie zrealizowana, </li>
                <li>pokrycia zwrotu części wpłat wniesionych tytułem zapłaty za imprezę turystyczną odpowiadającą części imprezy turystycznej, która nie została lub nie zostanie zrealizowana z przyczyn dotyczących organizatora turystyki lub osób, które działają w jego imieniu w formie gwarancji ubezpieczeniowej na rzecz Podróżnych nr M528038 wystawionej przez SIGNAL IDUNA Polska Towarzystwo Ubezpieczeń S.A.</li>
              </ol>
            </li>
            <li>Gwarancja jest ważna w okresie od dnia 01.10.2024 do dnia 30.09.2025 i obejmuje ochroną wszystkie umowy o udział w imprezie turystycznej zawarte w okresie obowiązywania gwarancji, chociażby ich wykonanie nie nastąpiło w tym okresie. Suma gwarancyjna wynosi: 217 170,00 zł.</li>
            <li>Oryginał gwarancji znajduje się u Marszałka Województwa Śląskiego z siedzibą ul. Ligonia 46, 40-037 Katowice.</li>
            <li>W przypadku niewypłacalności organizatora marszałek województwa lub upoważniona przez niego jednostka do wydawania dyspozycji wypłaty zaliczki  na pokrycie kosztów kontynuacji imprezy turystycznej lub kosztów powrotu podróżnych do kraju prowadzi działania związane z organizacją powrotu podróżnych do kraju, jeżeli organizator turystyki, wbrew obowiązkowi, nie zapewnia tego powrotu.</li>
            <li>
              Podróżny ubiegający się o zwrot wniesionych wpłat lub ich części w razie niezrealizowania imprezy turystycznej składa zgłoszenie do Gwaranta. Zgłoszenie zawiera imię i nazwisko oraz adres do korespondencji podróżnego poszkodowanego w wyniku niewykonania w całości lub w części zobowiązań wynikających z umowy. Do zgłoszenia należy dołączyć:
              <br />
              <ol className="letters">
                <li>kopię umowy o udział w imprezie turystycznej zawartej między podróżnym a organizatorem turystyki;</li>
                <li>kopię dowodu wpłaty na rzecz organizatora turystyki należności za imprezę turystyczną;</li>
                <li>oświadczenie podróżnego: <br />
                  - stwierdzające niewykonanie przez organizatora turystyki zobowiązań umownych o określonej wartości,
                  <br />
                  - zawierające wskazanie rachunku bankowego lub rachunku w spółdzielczej kasie oszczędnościowo-kredytowej, na który ma nastąpić wypłata środków z zabezpieczenia finansowego albo wskazanie innego sposobu wypłaty z tego zabezpieczenia.
                  <br />
                </li>
              </ol>
            </li>
            <li>W przypadku gdy gwarancja okaże się niewystarczająca na pokrycie kosztów kontynuacji imprezy turystycznej lub kosztów powrotu podróżnych do kraju oraz na pokrycie zwrotu wpłat lub części wpłat to brakujące środki finansowe wypłaca bezpośrednio poszkodowanemu podróżnemu Ubezpieczeniowy Fundusz Gwarancyjny z siedzibą w Warszawie, ul. Płocka 9/11.</li>
            <li>BP RAJZA potwierdza dokonywanie terminowych wpłat składek w należytej wysokości na Turystyczny Fundusz Gwarancyjny.</li>
          </ol>

          
          <h3 className="h5">X. REKLAMACJA I INNE PISMA PODRÓŻNEGO</h3>
          <ol>
            <li>Podróżny może złożyć BP RAJZA pisemną lub e-mailową reklamację najlepiej w terminie 30 dni od zakończenia imprezy.</li>
            <li>
              Reklamacje są rozpatrywane niezwłocznie i nie później niż:
              <br />
              <ol>
                <li>w przypadku zgłoszenia reklamacji w czasie trwania imprezy - w terminie 30 dni od jej zakończenia,</li>
                <li>w przypadku zgłoszenia reklamacji po zakończenia imprezy - w ciągu 30 dni od daty jej złożenia.</li>
              </ol>
            </li>
            <li>
              W wypadku odmowy uwzględnienia reklamacji BP RAJZA uzasadnia na piśmie przyczyny odmowy. 
            </li>
            <li>
              Podróżny może kierować wiadomości, żądania lub skargi związane z realizacją imprezy turystycznej bezpośrednio do Agenta, za pośrednictwem którego została ona nabyta.
            </li>
            <li>
              Agent niezwłocznie przekazuje wiadomości, żądania lub skargi, o których mowa w pkt. IX.4, od Podróżnego do BP RAJZA.
            </li>
            <li>
              Wiadomość, żądanie lub skargę wniesione do Agenta w danym dniu uważa się za wniesione z tym dniem do BP RAJZA.
            </li>
          </ol>

          
          <h3 className="h5">XI. OCHRONA DANYCH OSOBOWYCH</h3>
          <ol>
            <li>
              <b>1. Informacja ogólna o przetwarzaniu danych osobowych</b><br />
              Biuro Podróży RAJZA Spóła Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń, mając świadomość znaczenia przetwarzanych danych osobowych, przykłada najwyższą wagę do zapewnienia im odpowiedniego poziomu bezpieczeństwa. Dane osobowe należą do kluczowych zasobów informacyjnych firmy i jako takim zapewnia się odpowiednią ochronę. Przetwarzanie Państwa danych osobowych odbywa się z poszanowaniem zasad dotyczących przetwarzania: zgodności z prawem, rzetelności i przejrzystości, ograniczenia celu, minimalizacji danych, prawidłowości, ograniczenia przechowywania, integralności i poufności. Wykonując obowiązki administratora danych określone w art. 13 i art. 14 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, BP RAJZA przedstawia informacje należne osobom, których dane przetwarzamy w naszej firmie. Szczegółowa informacja o przetwarzaniu danych osobowych w BP RAJZA znajduje się także na stronie www.rajza.com.pl, w zakładce „RODO”. Wszelkie pytania dotyczące ochrony Państwa danych osobowych możecie Państwo kierować na adres: pisemnie - Biuro Podróży RAJZA s.c. 44-335 Jastrzębie-Zdrój, al. Piłsudskiego 32A lub elektronicznie - biuro@rajza.com.pl
            </li>
            <li>
              <b>Informacja dla klientów nabywających produkty lub usługi od  BP RAJZA</b><br />
              Biuro Podróży RAJZA Spóła Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń informuje - zgodnie z art. 13 ust.1 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” - iż: <br />
              <ul>
                <li>
                  współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.
                </li>
                <li>
                  Pani/Pana dane osobowe przetwarzane będą w związku z zawarciem i wykonaniem umowy, przy czym podstawę prawną ich przetwarzania stanowi art. 6 ust.1 b) RODO - przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, zaś celem przetwarzania jest zawarcie i wykonanie umowy, której przedmiotem jest kupno-sprzedaż produktów lub/i usług oraz doręczanie Pani/Panu korespondencji, 
                </li>
                <li>
                  przetwarzanie Pani/Pana danych jest dokonywane także w oparciu o podstawę prawną, którą stanowi art. 6 ust.1 c) RODO - przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze, polegającego na przechowywaniu dokumentacji przez czas określony w przepisach, zaś celem przetwarzania jest przechowywanie dokumentacji przez czas określony w przepisach, 
                </li>
                <li>
                  przetwarzanie Pani/Pana danych jest dokonywane ponadto w oparciu o podstawę prawną, którą stanowi art. 6 ust. 1 f) RODO - przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora, którymi są obrona i dochodzenie roszczeń,
                </li>
                <li>
                  Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie, operatorom pocztowym w celu doręczania korespondencji,
                </li>
                <li>
                  w przypadku jeśli uczestniczy Pani/Pan w imprezie turystycznej, to dane mogą być ujawniane także  następującym kategoriom odbiorców: partnerom współpracującym z administratorem, dostarczającym poszczególne usługi składające się na imprezę turystyczną, takim jak: hotele, przewoźnicy i firmy transportowe, w tym linie lotnicze, piloci, przewodnicy, lokalni kontrahenci zapewniający organizację czasu lub zwiedzanie w miejscu imprezy lub pobytu, elektroniczne systemy rezerwacji, za pomocą których rezerwowane są poszczególne usługi, etc.,
                </li>
                <li>
                  jeśli uczestniczy Pani/Pan w zagranicznej imprezie turystycznej Pani/Pana dane osobowe mogą być przez administratora danych przekazywane do państw trzecich, poza Europejski Obszar Gospodarczy, gdzie nie stosuje się przepisów europejskich, co wiąże się z tym, że ochrona danych osobowych obywateli Unii Europejskiej może być niewystarczająca; w odniesieniu do części z tych państw Komisja Europejska nie wydała pozytywnej rekomendacji, co do poziomu bezpieczeństwa danych osobowych, 
                </li>
                <li>
                  przekazanie Pani/Pana danych osobowych do państwa trzeciego może być niezbędne w celu wykonania umowy lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną, zaś podstawą prawną przekazania danych do państwa trzeciego jest art. 49 ust. 1 b) lub c) RODO - przekazanie jest niezbędne do wykonania umowy między osobą, której dane dotyczą, a administratorem lub do wprowadzenia w życie środków przedumownych podejmowanych na żądanie osoby, której dane dotyczą, lub przekazanie jest niezbędne do zawarcia lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną,
                </li>
                <li>
                  dane będą przechowywane także po wykonaniu umowy, a ich usunięcie nastąpi nie później niż 1 rok po zdarzeniu, które nastąpi później - zakończeniu okresu obowiązkowego przechowywania dokumentacji księgowej i podatkowej określonego w przepisach lub zakończeniu okresu przedawnienia dochodzenia i obrony roszczeń określonego w przepisach; w zakresie danych przetwarzanych na podstawie art. 6 ust. 1 f) RODO - usunięcie nastąpi bezzwłocznie po uwzględnieniu ewentualnego sprzeciwu,
                </li>
                <li>
                  ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, usunięcia, ograniczenia ich przetwarzania,  prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, 
                </li>
                <li>
                  podanie danych osobowych jest dobrowolne, lecz niezbędne do zawarcia umowy, zaś konsekwencją niepodania danych będzie niemożność zawarcia umowy.
                </li>
                <li>
                  Ponadto, na podstawie art. 21 ust.1 RODO, ma Pani/Pan prawo do wniesienia w dowolnym momencie sprzeciwu - z przyczyn związanych z Pani/Pana szczególną sytuacją - wobec przetwarzania Pani/Pana danych opartego na 6 ust.1 f) RODO.
                </li>
              </ul>
            </li>
            <li>
              <b> Informacja dla podróżnych - osób, które są uczestnikami imprez turystycznych organizowanych przez BP RAJZA na zlecenie podmiotu trzeciego (instytucji, pracodawcy, organizacji, etc.) lub są uczestnikami imprez w wyniku zawarcia na ich rzecz umowy przez osobę trzecią.</b> <br />
              Biuro Podróży RAJZA Spóła Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń, informuje - zgodnie z art. 14 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” - iż: <br />
              <ul>
                <li>współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.</li>
                <li>Pani/Pana dane osobowe otrzymaliśmy od naszego klienta – podmiotu, który zlecił administratorowi organizację imprezy turystycznej, której jest Pani/Pan uczestnikiem, albo od osoby, która zawarła umowę o usługę na Pani/Pana rzecz,</li>
                <li>kategorie odnośnych danych osobowych to: imię i nazwisko, data urodzenia, adres zamieszkania, adres korespondencyjny, seria i nr paszportu, data wydania paszportu, data ważności paszportu, organ, który wydał paszport, nr telefonu, adres e-mail,</li>
                <li>podstawę prawną przetwarzania Pani/Pana danych osobowych stanowi art. 6 ust.1 f) RODO - przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora - interesami tymi są:</li>
                <ol className="letters">
                  <li>wykonanie umowy zawartej z podmiotem zlecającym organizację imprezy lub osobą, która zawarła umowę o usługę na rzecz uczestnika,</li>
                  <li>możliwość kontaktu z uczestnikiem imprezy w celu zapewnienia prawidłowego przebiegu imprezy,</li>
                  <li>możliwość przedstawienia historii uczestnictwa w imprezach turystycznych, na życzenie osoby, której dane dotyczą,</li>
                  <li>dochodzenie roszczeń</li>
                </ol>
                <li>
                  cele przetwarzania to: <br />
                  <ol className="letters">
                    <li>wykonanie umowy zawartej z podmiotem zlecającym organizację imprezy lub osobą, która zawarła umowę o usługę na rzecz uczestnika, w tym m.in. umieszczenie na liście uczestników imprezy, rezerwacja biletów, hoteli, transportu, imprez fakultatywnych, rozpatrzenie ewentualnych reklamacji.</li>
                    <li>możliwość kontaktu z uczestnikiem imprezy w celu zapewnienia prawidłowego przebiegu imprezy.</li>
                    <li>możliwość przedstawienia historii uczestnictwa w imprezach turystycznych, na życzenie osoby, której dane dotyczą,</li>
                    <li>dochodzenie i obrona roszczeń</li>
                  </ol>
                </li>
                <li>jeśli uczestniczy Pani/Pan w zagranicznej imprezie turystycznej Pani/Pana dane osobowe mogą być przez administratora danych przekazywane do państw trzecich, poza Europejski Obszar Gospodarczy, gdzie nie stosuje się przepisów europejskich, co wiąże się z tym, że ochrona danych osobowych obywateli Unii Europejskiej może być niewystarczająca; w odniesieniu do części z tych państw Komisja Europejska nie wydała pozytywnej rekomendacji, co do poziomu bezpieczeństwa danych osobowych,</li>
                <li>przekazanie Pani/Pana danych osobowych do państwa trzeciego może być niezbędne w celu wykonania umowy lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną, zaś podstawą prawną przekazania danych do państwa trzeciego jest art. 49 ust. 1 b) lub c) RODO - przekazanie jest niezbędne do wykonania umowy między osobą, której dane dotyczą, a administratorem lub do wprowadzenia w życie środków przedumownych podejmowanych na żądanie osoby, której dane dotyczą, lub przekazanie jest niezbędne do zawarcia lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną,</li>
                <li>Pani/Pana dane będą ujawniane kategoriom odbiorców wśród których znajdują się: upoważnione i przeszkolone osoby przetwarzające dane, podmioty przetwarzające na podstawie umów powierzenia przetwarzania zawartych na piśmie, operatorzy pocztowi w celu doręczania korespondencji, partnerzy współpracujący z administratorem, dostarczający poszczególne usługi składające się na imprezę turystyczną, tacy jak: hotele, przewoźnicy i firmy transportowe, w tym linie lotnicze, piloci, przewodnicy, lokalni kontrahenci zapewniający organizację czasu lub zwiedzanie w miejscu imprezy lub pobytu, elektroniczne systemy rezerwacji, za pomocą których rezerwowane są poszczególne usługi, etc.,</li>
                <li>dane będą przechowywane przez okres nie dłuższy niż 6 miesięcy po zdarzeniu, które nastąpi wcześniej - zakończeniu okresu przedawnienia dochodzenia i obrony roszczeń określonego w ustawie o imprezach turystycznych i powiązanych usługach turystycznych lub uwzględnienia ewentualnego sprzeciwu,</li>
                <li>ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych</li>
                <li>podanie danych osobowych jest dobrowolne, lecz niezbędne do przedstawienia oferty na żądanie osoby, której dane dotyczą, przed zawarciem umowy, zaś konsekwencją niepodania danych będzie niemożność przedstawienia oferty.</li>
                <li>Ponadto, na podstawie art. 21 ust.1 RODO, ma Pani/Pan prawo do wniesienia w dowolnym momencie sprzeciwu - z przyczyn związanych z Pani/Pana szczególną sytuacją - wobec przetwarzania Pani/Pana danych opartego na 6 ust.1 f) RODO.</li>
              </ul>
            </li>
            <li>
              <b>Informacja dla ubezpieczonych na imprezach zagranicznych - uczestników imprez zagranicznych podlegający obowiązkowemu ubezpieczeniu przez administratora w zakresie NNW i KL</b> <br />
              Biuro Podróży RAJZA Spóła Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń informuje - zgodnie z art. 13 i 14 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” - iż: <br />
              <ul>
                <li>współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.</li>
                <li>Pani/Pana dane osobowe otrzymaliśmy od Pani/Pana, albo od naszego klienta - podmiotu, który zlecił administratorowi organizację imprezy turystycznej, której jest Pani/Pan uczestnikiem, albo od osoby, która zawarła umowę o usługę na Pani/Pana rzecz,</li>
                <li>kategorie odnośnych danych osobowych to: imię i nazwisko, data urodzenia, adres zamieszkania,</li>
                <li>podstawę prawną przetwarzania Pani/Pana dane osobowe stanowi art. 6 ust.1 c) RODO - przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze,</li>
                <li>
                  celem przetwarzania jest dopełnienie obowiązków wynikających z ustaw: 
                  <br />
                  - obowiązkowego ubezpieczenia zakresie NNW i KL uczestników imprez za granicą w na podstawie ustawy o imprezach turystycznych i powiązanych usługach turystycznych,
                  <br />
                  - obowiązku przechowywania dokumentacji wynikającego z przepisów o obowiązkowym okresie przechowywania dokumentacji obowiązkowych ubezpieczeń i dokumentacji księgowo-podatkowej,
                </li>
                <li>Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie, towarzystwu ubezpieczeniowemu wystawiającemu polisę ubezpieczenia, podmiotom udzielającym świadczeń finansowanych z polisy ubezpieczenia,</li>
                <li>Pani/Pana dane osobowe mogą być przez administratora danych przekazywane do państw trzecich, poza Europejski Obszar Gospodarczy, gdzie nie stosuje się przepisów europejskich, co wiąże się z tym, że ochrona danych osobowych obywateli Unii Europejskiej może być niewystarczająca; w odniesieniu do części z tych państw Komisja Europejska nie wydała pozytywnej rekomendacji, co do poziomu bezpieczeństwa danych osobowych,</li>
                <li>przekazanie Pani/Pana danych osobowych do państwa trzeciego może być niezbędne w celu wykonania umowy lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną, zaś podstawą prawną przekazania danych do państwa trzeciego jest art. 49 ust. 1 b) lub c) RODO - przekazanie jest niezbędne do wykonania umowy między osobą, której dane dotyczą, a administratorem lub do wprowadzenia w życie środków przedumownych podejmowanych na żądanie osoby, której dane dotyczą, lub przekazanie jest niezbędne do zawarcia lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną,</li>
                <li>dane będą przechowywane nie dłużej niż 1 rok po zakończeniu okresu obowiązkowego przechowywania dokumentacji obowiązkowych ubezpieczeń i dokumentacji księgowo-podatkowej określonego w przepisach,</li>
                <li>ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,</li>
                <li>podanie danych osobowych w związku z obowiązkowym ubezpieczeniem jest wymagane przez ustawę, zaś konsekwencją niepodania danych będzie brak możliwości udziału w zagranicznej imprezie turystycznej.</li>
              </ul>
            </li>
            <li>
              <b>Informacja dla ubezpieczonych na imprezach krajowych - uczestników imprez krajowych podlegający ubezpieczeniu przez administratora w zakresie NNW i KL na podstawie umowy uczestnictwa</b>
              <br />
              Biuro Podróży RAJZA Spóła Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń informuje - zgodnie z art. 13 i 14 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” - iż:
              <br />
              <ul>
                <li>Współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A,</li>
                <li>Pani/Pana dane osobowe otrzymaliśmy od Pani/Pana, albo od naszego klienta - podmiotu, który zlecił administratorowi organizację imprezy turystycznej, której jest Pani/Pan uczestnikiem, albo od osoby, która zawarła umowę o usługę na Pani/Pana rzecz,</li>
                <li>kategorie odnośnych danych osobowych to: imię i nazwisko, data urodzenia, adres zamieszkania,</li>
                <li>
                  podstawę prawną przetwarzania Pani/Pana dane osobowe stanowi art. 6 ust.1 f) RODO - przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora, którymi są:
                  <br />
                  <ol className="letters">
                    <li>wykonanie umowy zawartej z podmiotem zlecającym organizację imprezy lub osobą, która zawarła umowę o usługę na rzecz uczestnika,</li>
                    <li>dochodzenie lub obrona roszczeń,</li>
                  </ol>
                </li>
                <li>
                  celem przetwarzania jest:
                  <br />
                  <ol className="letters">
                    <li>wykonanie umowy zawartej z podmiotem zlecającym organizację imprezy lub osobą, która zawarła umowę o usługę na rzecz uczestnika,</li>
                    <li>dochodzenie lub obrona roszczeń,</li>
                  </ol>
                </li>
                <li>Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie, towarzystwu ubezpieczeniowemu wystawiającemu polisę ubezpieczenia, podmiotom udzielającym świadczeń finansowanych z polisy ubezpieczenia,</li>
                <li>dane będą przechowywane nie dłużej niż 1 rok po zakończeniu okresu obowiązkowego przechowywania dokumentacji księgowo-podatkowej określonego w przepisach,</li>
                <li>ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,</li>
                <li>podanie danych osobowych w związku z ubezpieczeniem jest wymagane przez umowę o udział w krajowej imprezie turystycznej, zaś konsekwencją niepodania danych będzie brak możliwości udziału w krajowej imprezie turystycznej.</li>
                <li>Ponadto, na podstawie art. 21 ust.1 RODO, ma Pani/Pan prawo do wniesienia w dowolnym momencie sprzeciwu - z przyczyn związanych z Pani/Pana szczególną sytuacją - wobec przetwarzania Pani/Pana danych opartego na 6 ust.1 f) RODO.</li>
              </ul>
            </li>
          </ol>
          
          <h3 className="h5">XII. POSTANOWIENIA KOŃCOWE</h3>
          <ol>
            <li>Postanowienia umowy zawartej przez BP RAJZA z Podróżnym lub oświadczenia podróżnego mniej korzystne dla podróżnych niż postanowienia ustawy są nieważne. W miejsce postanowień umowy mniej korzystnych dla Podróżnego obowiązują przepisy ustawy.</li>
            <li>W zakresie w jakim wynika to ustawy z o imprezach turystycznych i powiązanych usługach turystycznych wynikające z niniejszej umowy prawa Organizatora przysługują także Uczestnikom wyjazdu stanowiącego przedmiot niniejszej umowy</li>
            <li>W razie sprzeczności treści umowy z Warunkami uczestnictwa strony są związane umową.</li>
            <li>W zakresie w jakim spór wynikający z niniejszej umowy może mieć charakter konsumencki, Organizator informuje, że o ile nie ma to charakteru obowiązkowego w świetle obowiązującego prawa, spory wynikające z niniejszej umowy nie mogą być rozpatrywane w oparciu o metody pozasądowego rozwiązywania sporów konsumenckich, o których mowa w ustawie z dnia 23 września 2016r. o pozasądowym rozwiązywaniu sporów konsumenckich (Dz.U. poz. 1823). Pod adresem: http://ec.europa.eu/consumers/odr dostępna jest platforma internetowego systemu rozstrzygania sporów pomiędzy konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR) zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) nr 524/2013 zdnia 21 maja 2013r.(Dz.Urz UE L 165 z 18.06.2013, str. 1).</li>
            <li>W sprawach nieuregulowanych w niniejszym OWU mają zastosowanie powszechnie obowiązujące przepisy prawa polskiego, w szczególności Ustawy oraz Kodeksu cywilnego.</li>
          </ol>

          <p><i><b>Załącznik nr 1</b></i></p>
          <h3 className="h5 text-center">STANDARDOWY FORMULARZ INFORMACYJNY DO UMÓW O UDZIAŁ W IMPREZIE TURYSTYCZNE</h3>
          <p>Zaoferowane Państwu połączenie usług turystycznych stanowi imprezę turystyczną w rozumieniu dyrektywy (UE) 2015/2302. W związku z powyższym będą Państwu przysługiwały wszystkie prawa UE mające zastosowanie do imprez turystycznych. Przedsiębiorstwo BP RAJZA będzie ponosiło pełną odpowiedzialność za należytą realizację całości imprezy turystycznej. Ponadto, zgodnie z wymogami prawa, przedsiębiorstwo BP RAJZA posiada zabezpieczenie w celu zapewnienia zwrotu Państwa wpłat i, jeżeli transport jest elementem imprezy turystycznej, zapewnienia Państwa powrotu do kraju w przypadku, gdyby przedsiębiorstwo BP RAJZA stało się niewypłacalne. Więcej informacji dotyczących najważniejszych praw zgodnie z dyrektywą (UE) 2015/2302 (strona internetowa https://www.konsument.gov.pl/uploads/werjsa%20polska.pdf). Korzystając ze strony internetowej podróżny otrzyma następujące informacje:</p>
          <p>
            Najważniejsze prawa zgodnie z dyrektywą (UE) 2015/2302 
            <br />
            – Przed zawarciem umowy o udział w imprezie turystycznej podróżni otrzymają wszystkie niezbędne informacje na temat imprezy turystycznej.
            <br />
            – Zawsze co najmniej jeden przedsiębiorca ponosi odpowiedzialność za należyte wykonanie wszystkich usług turystycznych objętych umową
            <br />
            – Podróżni otrzymują awaryjny numer telefonu lub dane punktu kontaktowego, dzięki którym mogą skontaktować się z organizatorem turystyki lub agentem turystycznym.
            <br />
            – Podróżni mogą przenieść imprezę turystyczną na inną osobę, powiadamiając o tym w rozsądnym terminie, z zastrzeżeniem ewentualnych dodatkowych kosztów
            <br />
            – Cena imprezy turystycznej może zostać podwyższona jedynie wtedy, gdy wzrosną określone koszty (na przykład koszty paliwa) i zostało to wyraźnie przewidziane w umowie; w żadnym przypadku podwyżka ceny nie może nastąpić później niż 20 dni przed rozpoczęciem imprezy turystycznej. Jeżeli podwyżka ceny przekracza 8% ceny imprezy turystycznej, podróżny może rozwiązać umowę. Jeśli organizator turystyki zastrzega sobie prawo do podwyższenia ceny, podróżny ma prawo do obniżki ceny, jeżeli obniżyły się odpowiednie koszty.
            <br />
            – Podróżni mogą rozwiązać umowę bez ponoszenia jakiejkolwiek opłaty za rozwiązanie i uzyskać pełen zwrot wszelkich wpłat, jeżeli jeden z istotnych elementów imprezy turystycznej, inny niż cena, zmieni się w znaczący sposób. Jeżeli przedsiębiorca odpowiedzialny za imprezę turystyczną odwoła ją przed rozpoczęciem, podróżni mają prawo do zwrotu wpłat oraz, w stosownych przypadkach, do rekompensaty.
            <br />
            – W wyjątkowych okolicznościach - na przykład jeżeli w docelowym miejscu podróży występują poważne problemy związane z bezpieczeństwem, które mogą wpłynąć na imprezę turystyczną - podróżni mogą, przed rozpoczęciem imprezy turystycznej, rozwiązać umowę bez ponoszenia jakiejkolwiek opłaty za rozwiązanie.
            <br />
            – Ponadto podróżni mogą w każdym momencie przed rozpoczęciem imprezy turystycznej rozwiązać umowę za odpowiednią i możliwą do uzasadnienia opłatą.
            <br />
            – Jeżeli po rozpoczęciu imprezy turystycznej jej znaczące elementy nie mogą zostać zrealizowane zgodnie z umową, będą musiały zostać zaproponowane podróżnemu, bez dodatkowych kosztów, odpowiednie alternatywne usługi. W przypadku gdy usługi nie są świadczone zgodnie z umową, co istotnie wpływa na realizację imprezy turystycznej, a organizator turystyki nie zdoła usunąć problemu, podróżni mogą rozwiązać umowę bez opłaty za rozwiązanie.
            <br />
            – Podróżni są również uprawnieni do otrzymania obniżki ceny lub rekompensaty za szkodę w przypadku niewykonania lub nienależytego wykonania usług turystycznych.
            <br />
            – Organizator turystyki musi zapewnić pomoc podróżnemu, który znajdzie się w trudnej sytuacji.
            <br />
            – W przypadku gdy organizator turystyki stanie się niewypłacalny, wpłaty zostaną zwrócone. Jeżeli organizator turystyki stanie się niewypłacalny po rozpoczęciu imprezy turystycznej i jeżeli impreza turystyczna obejmuje transport, zapewniony jest powrót podróżnych do kraju. BP RAJZA wykupiło w Ubezpieczeniowym Funduszu Gwarancyjnym zabezpieczenie na wypadek niewypłacalności. Podróżni mogą kontaktować się z tym podmiotem lub, w odpowiednich przypadkach, z właściwym organem (Urząd Marszałkowski Województwa Śląskiego, ul. Ligonia 46, 40-037 Katowice, e-mail: kancelaria@slaskie.pl telefon: +48 32 207 88 88), jeżeli z powodu niewypłacalności BP RAJZA dojdzie do odmowy świadczenia usług. Strona internetowa, na której można znaleźć Dyrektywa (UE) 2015/2302 przetransponowana do prawa krajowego.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OWU;