import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h3>Biuro Podróży RAJZA s.c.</h3>
            <p>Al. Piłsudskiego 32A</p>
            <p>44-335 Jastrzębie-Zdrój</p>
            <p>tel. 
              <a className="telephone-number" href="tel:324731008">32/473 10 08</a>
              <a className="telephone-number" href="tel:324719900">32/471 99 00</a>
            </p>
            <p> 
              <a href='mailto:biuro@rajza.com.pl'>biuro@rajza.com.pl</a> 
            </p>
          </div>
          <div className="col-sm-12 col-md-6">
            <h3>Obsługa klienta</h3>
            <ul className="">
              <li className="">
                <Link className="" to="/rodo">RODO</Link>
              </li>
              <li className="">
              <Link className="" to="/polityka-prywatnosci/">Polityka Prywatności</Link>
              </li>
              <li className="">
                <Link className="" to="/ubezpieczenie">Ubezpieczenie</Link>
              </li>
              <li className="">
                <Link className="" to="/swiadczenie-uslug-droga-elektroniczna">Regulamin świadczenia usług drogą elektroniczną</Link>
              </li>
              <li className="">
                <Link className="" to="/owu">Ogólne warunki uczestnictwa w imprezach turystycznych</Link>
              </li>
              <li className="">
                <Link className="" to="/uwagi-organizacyjne">Uwagi Organizacyjne</Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-12">
            <div className="social-media-section">
              <a href="https://www.facebook.com/bprajza">
                <span className="visually-hidden">Fcebook</span>
                <span className="icon icon-facebook"></span>
              </a>
              <a href="https://www.instagram.com/bprajza/">
                <span className="visually-hidden">Instagram</span>
                <span className="icon icon-instagram"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;