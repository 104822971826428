// src/pages/UwagiOrganizacyjne.js
import React from 'react';
import MetaTags from '../Components/MetaTags';

function UwagiOrganizacyjne() {
  return (
    <div className="container">
      <MetaTags title="Uwagi Organizacyjne | Biuro Podróży Rajza" description="Uwagi Organizacyjne" />
      <div className="row">
        <div className="pt-3 pb-5 regulations">

          <h1>UWAGI ORGANIZACYJNE</h1>

          <h4 className="h5">
            WCZASY NAD BAŁTYKIEM i na MAZURACH
          </h4>
          <p>
            Świadczenia - pierwszym świadczeniem jest kolacja lub obiadokolacja (w zależności od świadczeń ośrodka) w dniu przyjazdu, ostatnim - wcześniejsze śniadanie w dniu wyjazdu; cena nie zawiera opłaty klimatycznej płatnej w recepcji obiektu; w przypadku ośrodków, gdzie świadczeniem są śniadania i obiadokolacje suchy prowiant na drogę powrotną również jest płatny dodatkowo.
          </p>

          <h4 className="h5">
            WCZASY W GÓRACH
          </h4>
          <p>
            Świadczenia - pierwszym świadczeniem jest obiad lub obiadokolacja (w zależności od ośrodka) w dniu przyjazdu, ostatnim - śniadanie w dniu wyjazdu; cena nie zawiera opłaty klimatycznej płatnej w recepcji obiektu.
          </p>

          <h4 className="h5">
            WCZASY ZAGRANICZNE
          </h4>
          <p>
            Świadczenia - pierwszym świadczeniem jest obiadokolacja drugiego dnia turnusu, ostatnim - śniadanie w przedostatni dzień turnusu, zabezpieczenie bagaży, czas wolny, wyjazd w godzinach popołudniowych; zakwaterowanie zazwyczaj następuje w godzinach 15:00 - 16:00, godziny posiłków ustala hotel; cena nie zawiera opłaty klimatycznej płatnej w hotelu w wysokości zależnej od cennika terytorialnego; bilety wstępu oraz przewodnicy lokalni płatne dodatkowo; wjazd do danego państwa NA PODSTAWIE WAŻNEGO DOWODU OSOBISTEGO LUB PASZPORTU, zalecamy zabranie karty EKUZ (EUROPEJSKIEJ KARTY UBEZPIECZENIA ZDROWOTNEGO) - dostępnej w oddziałach lub delegaturze NFZ. 
          </p>

          <h4 className="h5">
            POKOJE 1-OSOBOWE
          </h4>
          <p>
            W niektórych ośrodkach za dodatkową opłatą istnieje możliwość zakwaterowania w pokoju 1-osobowym (liczba takich pokoi jest jednak ograniczona). 
          </p>

          <h4 className="h5">
            DOKWATEROWANIE
          </h4>
          <p>
            Istnieje możliwość dokwaterowania do drugiej osoby, jednak w przypadku rezygnacji jednej z tych osób, Uczestnik jest zobowiązany do dopłaty wg. cennika ośrodka.
          </p>

          <h4 className="h5">
            UBEZPIECZENIE
          </h4>
          <p>
            Każdy Uczestnik jest ubezpieczony w Signal Iduna Polska TU S.A. Zachęcamy do wykupienia <b>dodatkowego ubezpieczenia – od kosztów rezygnacji</b>. Więcej informacji - tel. 32/47 31 008
          </p>
          


        </div>
      </div>
    </div>
  );
}

export default UwagiOrganizacyjne;