// src/pages/Wczasy.js
import React from 'react';
import NewVacationList from '../Components/VacationList/NewVacationList.js';
import MetaTags from '../Components/MetaTags';

// TODO add helmet here
function Wczasy() {

  return (
    <div className="container">
      <MetaTags title="Lista ofert wczasowych | Biuro Podróży Rajza" description="Lista ofert wczasowych" />
      <div className="row">
        <NewVacationList />
      </div>
    </div>
  );
}

export default Wczasy;