import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

const MetaTags = ({ title, description }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default MetaTags;