import React, { useState } from "react";
import { Link } from "react-router-dom";
import vacation from "../../Data/Vacations";
import './VacationList.scss'; // Import pliku CSS

// Funkcja pomocnicza do usuwania polskich znaków i zastępowania spacji myślnikami
const removePolishChars = (text) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ /g, "-")
    .replace(/ą/g, "a")
    .replace(/ć/g, "c")
    .replace(/ę/g, "e")
    .replace(/ł/g, "l")
    .replace(/ń/g, "n")
    .replace(/ó/g, "o")
    .replace(/ś/g, "s")
    .replace(/ź/g, "z")
    .replace(/ż/g, "z")
    .replace(/Ą/g, "A")
    .replace(/Ć/g, "C")
    .replace(/Ę/g, "E")
    .replace(/Ł/g, "L")
    .replace(/Ń/g, "N")
    .replace(/Ó/g, "O")
    .replace(/Ś/g, "S")
    .replace(/Ź/g, "Z")
    .replace(/Ż/g, "Z")
    .toLowerCase();
};

// Wyciągnięcie unikalnych typów wakacji do filtracji
const vacationTypes = [...new Set(vacation.map((item) => item.vacationType))];

const NewVacationList = () => {
  const [filter, setFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  // Filtracja wakacji na podstawie wybranego typu i wyszukiwania
  const filteredVacations = vacation.filter((item) => {
    const matchesType = filter ? item.vacationType === filter : true;
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesType && matchesSearch;
  });

  return (
    <div className="elements-list pt-3">
      {/* Renderowanie przycisków do filtrowania */}
      <div className="row filters-wrapper">
        <div className="button-filters col-sm-12">
          <div className="filter-type-wrapper">
            <span className="filter-label">Kategorie</span>
            <div className="buttons mb-3">
              <button
                className={!filter ? "active" : ""}
                onClick={() => setFilter(null)}
              >
                Wszystkie
              </button>
              {vacationTypes.map((type) => (
                <button
                  key={type}
                  className={filter === type ? "active" : ""}
                  onClick={() => setFilter(type)}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

      </div>

      {/* Renderowanie pola wyszukiwania */}
      <div className="input-filter col-sm-12 mb-3">
        <div className="filter-type-wrapper input">
          <label for="search-input">Wyszukaj po tytule</label>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Wyszukaj po tytule"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              id="search-input"
            />
            <button onClick={() => setSearchTerm("")}>Wyczyść</button>
          </div>
        </div>
      </div>
      
      </div>

      <div className="row">

      {/* Renderowanie listy wakacji */}
      {filteredVacations.map((item, index) => (
        <div key={index} className="col-6 col-md-4 col-lg-3 mb-3">

          <Link to={`/details/${removePolishChars(item.title)}`} className="link-more">
            <img src={item.images[0].url} alt={item.images[0].alt} />
            <h4>{item.title}</h4>
            <span className="price">{item.price ? (`od ${item.price} zł`) : ("Zobacz więcej")} </span>
          </Link>
        </div>
      ))}

      </div>

      {/* Wyświetlanie komunikatu, jeśli nie znaleziono wyników */}
      {filteredVacations.length === 0 && <h3 className="text-center mb-5 mt-5">Nie znaleziono wakacji.</h3>}
    </div>
  );
};

export default NewVacationList;