// src/pages/Ubezpieczenie.js
import React from 'react';
import FileLink from '../Components/FileLink/FileLink';
import MetaTags from '../Components/MetaTags';

const documentRoot = "/documents/ubezpieczenie/"
const documents = [
  { title: "OWU Bezpieczne Rezerwacje",fileName: "OWU_BEZPIECZNE_REZERWACJE.pdf" },
  { title: "OWU Bezpieczne Podróże", fileName: "OWU_Bezpiecznie_Podroze.pdf"},
  { title: "OWU NNW", fileName: "OWU_NNW.pdf"},
  { title: "Ubezpieczenie", fileName: "UBEZPIECZENIE.pdf" },
]


function Ubezpieczenie() {
  return (
    <div className="container">
      <MetaTags title="Ubezpieczenie | Biuro Podróży Rajza" description="Ubezpieczenie" />
      <div className="row">
        <div className="pt-3 pb-5 regulations">

          <h1>UBEZPIECZENIE</h1>
          
          <FileLink 
            documentRoot={documentRoot}
            documents={documents}
          />

          <ol>
            <li>
              W przypadku <b>wyjazdów krajowych</b> Organizator ubezpiecza Podróżnych w SIGNAL IDUNA Polska TU S.A. na mocy umowy generalnej nr <b>528057</b> z dnia 17.09.2024r. od następstw nieszczęśliwych wypadków w zakresie określonym w Ogólnych Warunkach Ubezpieczenia Następstw Nieszczęśliwych Wypadków na terenie Rzeczypospolitej Polskiej, zatwierdzonych uchwałą nr 67/Z/2024 Zarządu SIGNAL IDUNA Polska Towarzystwo Ubezpieczeń S.A. z dnia 07.08.2024r., umowa ubezpieczenia NNW obejmuje świadczenie na wypadek trwałego uszczerbku na zdrowiu (NWI), świadczenie na wypadek śmierci (NWS) do kwoty 10 000 zł; uwaga - ubezpieczenie nie obejmuje m.in. kosztów assistance (np. transport chorego do szpitala bądź innej placówki medycznej, apteki itp.). Odpłatność za niniejszą usługę ponosi Podróżny;
            </li>
            <li>
              W przypadku <b>wyjazdów za granicę</b> Organizator ubezpiecza Podróżnych w Signal Iduna Polska TU S.A. na mocy umowy generalnej nr <b>528057</b> z dnia 17.09.2024r. i zgodnie z przepisami ustawy o usługach turystycznych z dnia 29 sierpnia 1997r. w zakresie określonym w Ogólnych Warunkach Ubezpieczenia Bezpieczne Podróże, zatwierdzonych uchwałą nr 42/Z/2021 Zarządu SIGNAL IDUNA Polska Towarzystwo Ubezpieczeń S.A. z dnia 19.07.2021r., umowa ubezpieczenia Bezpieczne Podróże obejmuje: Bagaż 1 000 zł, NNW 15 000 zł, Koszty Leczenia 30 000 eur i Ratownictwa oraz Assisatnace, w tym nielimitowany transport do kraju zamieszkania
              <br/>
              <ul>
                <li>
                  Ogólne Warunki Ubezpieczenia Bezpieczne Podróże dostępne są w BP RAJZA. Ochrona ubezpieczeniowa jest rozszerzona o choroby przewlekłe i nowotworowe w zakresie standard.
                </li>
                <li>
                  Podróżny podpisujący umowę oświadcza za siebie i za innych uczestników imprezy (w zakresie posiadanego upoważnienia), iż każdy Ubezpieczony wyraża zgodę na udostępnienie SIGNAL IDUNA przez podmioty udzielające świadczeń zdrowotnych dokumentacji medycznej oraz przez NFZ nazw i adresów świadczeniodawców (a także zwalnia lekarzy w kraju i za granicą z tajemnicy lekarskiej) w celu ustalenia prawa do świadczenia z zawartej umowy ubezpieczenia i wysokości tego świadczenia. Zgoda jest ważna pod warunkiem zaistnienia zdarzenia ubezpieczeniowego. Dane Ubezpieczonych będą udostępnione do SIGNAL IDUNA Polska TU S.A. z siedzibą przy ul. Siedmiogrodzka 9, 01-204 Warszawa, w celu realizacji umowy ubezpieczenia. Pełna informacja dotycząca przetwarzania danych przez SIGNAL IDUNA Polska TU S.A. znajduje się na stronie <a href="www.signal-iduna.pl/przetwarzanie-danych-osobowych">www.signal-iduna.pl/przetwarzanie-danych-osobowych</a>   
                </li>
              </ul>
            </li>
            <li>
              Podróżny w przypadku zaistnienia zdarzenia ubezpieczeniowego powinien skontaktować się z Centralą Alarmową SIGNAL IDUNA: +48 22 864 55 26,  fax +48 22 575 95 75, sms +48 661 000 888.
            </li>
            <li>
              Podróżny może wykupić <b>ubezpieczenia dodatkowe</b> takie jak
              <br />
              <ul>
                <li>
                  doubezpieczenie w ramach Kosztów Leczenia czyli zwiększenie kwoty ubezpieczenia we własnym zakresie lub u Organizatora
                </li>
                <li>
                  <b>ubezpieczenie od kosztów rezygnacji</b> z imprezy lub przerwania imprezy we własnym zakresie lub poprzez dokonanie dopłaty u Organizatora. Umowa ubezpieczenia może być zawarta w terminie najpóźniej do 14 dni od daty zawarcia umowy uczestnictwa w imprezie turystycznej (wpłaty całości lub części zaliczki). W przypadku, gdy do rozpoczęcia imprezy turystycznej lub dnia rozpoczęcia podróży jest mniej niż 30 dni, umowę ubezpieczenia można zawrzeć jedynie w dniu zawarcia umowy uczestnictwa w imprezie turystycznej.
                </li>
              </ul>
            </li>
          </ol>

        </div>
      </div>
    </div>
  );
}

export default Ubezpieczenie;