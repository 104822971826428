


// old approach start


import './App.scss';
import MainNavigation from './MainNavigation/MainNavigation';
import Footer from './Footer/Footer';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './Pages/Home';
import Wczasy from './Pages/Wczasy';
import Dokumenty from './Pages/Dokumenty';
import Wycieczki from './Pages/Wycieczki';
import Kontakt from './Pages/Kontakt';
import PolitykaPrywatnosci from './Pages/PolitykaPrywatnosci';
import SwiadczenieUslugDrogaElektroniczna from './Pages/SwiadczenieUslugDrogaElektroniczna';
import OWU from './Pages/OWU';
import Rodo from './Pages/RODO';
import Ubezpieczenie from './Pages/Ubezpiezenie';
import UwagiOrganizacyjne from './Pages/UwagiOrganizacyjne';
import 'bootstrap/dist/css/bootstrap.min.css';


import NewVacationList from './Components/VacationList/NewVacationList';
import NewVacationDetails from './Components/VacationList/NewVacationDetails';

import TripsList from './Components/VacationList/TripList';
import TripDetails from './Components/VacationList/TripDetails';

// remember needs to have rout ir noutes to preapare good routing for all elements


const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo({top: 0, left: 0,  behavior: 'instant'});
  }, [pathname]);
}

function App() {
  
  return (
    <BrowserRouter>
      
      <div className="App">
      <ScrollToTop />
        <header>
          <MainNavigation />      

        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/wczasy" element={<Wczasy />} />
            <Route path="/wycieczki" element={<Wycieczki />} />
            <Route path="/dokumenty" element={<Dokumenty />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/" element={<NewVacationList />} />
            <Route path="/details/:id" element={<NewVacationDetails />} />

            <Route path="/" element={<TripsList />} />
            <Route path="/wycieczki-detale/:id" element={<TripDetails />} />

            <Route path="/uwagi-organizacyjne" element={<UwagiOrganizacyjne />} />
            <Route path="/ubezpieczenie" element={<Ubezpieczenie />} />
            <Route path="/polityka-prywatnosci" element={<PolitykaPrywatnosci />} />
            <Route path="/swiadczenie-uslug-droga-elektroniczna" element={<SwiadczenieUslugDrogaElektroniczna />} />
            <Route path="/owu" element={<OWU />} />
            <Route path="/rodo" element={<Rodo />} />
            
            
          </Routes>
        </main>
        <Footer />
      </div>


    


    </BrowserRouter>
  );
}

export default App;
