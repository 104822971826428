import React from "react";
import { useParams, Link } from "react-router-dom";
import vacation from "../../Data/Vacations";
import Jumbotron from "../Jumbotron/Jumbotron.";
import MetaTags from "../MetaTags";

const removePolishChars = (text) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ /g, "-")
    .replace(/ą/g, "a")
    .replace(/ć/g, "c")
    .replace(/ę/g, "e")
    .replace(/ł/g, "l")
    .replace(/ń/g, "n")
    .replace(/ó/g, "o")
    .replace(/ś/g, "s")
    .replace(/ź/g, "z")
    .replace(/ż/g, "z")
    .replace(/Ą/g, "A")
    .replace(/Ć/g, "C")
    .replace(/Ę/g, "E")
    .replace(/Ł/g, "L")
    .replace(/Ń/g, "N")
    .replace(/Ó/g, "O")
    .replace(/Ś/g, "S")
    .replace(/Ź/g, "Z")
    .replace(/Ż/g, "Z")
    .toLowerCase();
};

const NewVacationDetails = () => {
  const { id } = useParams();
  const vacationItem = vacation.find(
    (item) => removePolishChars(item.title) === id
  );

  if (!vacationItem) {
    return <div>Nie znaleziono wakacji</div>;
  }

  const vacationItemMapPath = vacationItem.localization.mapUrl;
  let pageTitle = vacationItem.title + " | Rajza"

  return (
    <>
     <MetaTags title={pageTitle} description={`Szczegóły oferty ${pageTitle}`} />
      <div className="container-jumbotron">
        <Jumbotron images={vacationItem.images} />
      </div>

      <div className="container">
        <div className="details-text">
          {/* add ifs if element exist  */}
          <h1>{vacationItem.title}</h1>
          {
            vacationItem.description.length > 0 &&
            <div>
              {vacationItem.description.map((item, index) => (
                <p>{item}</p>
              ))}
            </div>
          }

          <div className="row">
            <div className="col-12 col-lg-6">
              {/* dates */}
              {
                vacationItem.terms.length > 0 &&
                <div>
                  <table className="dates-table">
                    <thead>
                      <tr>
                        <td>Data</td>
                        <td>Cena</td>
                      </tr>
                    </thead>
                    <tbody>
                      {vacationItem.terms.map((term, index) => (
                        <tr key={index}>
                          <td>
                            {term.startDate} - {term.endDate}
                          </td>
                          <td>
                            {term.price} PLN
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              }

              {
                vacationItem.services.length > 0 &&
                <div>
                  <h3 className="h5">Świadczenia zawarte w cenie </h3>
                  <ul>
                  {vacationItem.services.map((item, index) => (
                    <li>{item}</li>
                  ))}
                  </ul>
                </div>
              }
              <p>{vacationItem.disclaimer}</p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="localization-container">
                <h3>Lokalizacja</h3>
                <div className="map-address-container">
                  <div className="row">
                    <div className="col-sm-12">
                      <address>
                        <span>{vacationItem.localization.addressName}</span>
                        <span>{vacationItem.localization.addressStreet}</span>
                        <span>{vacationItem.localization.addressCityCode}</span>
                        <span><a href={vacationItem.localization.addressPage}>{vacationItem.localization.addressPage}</a></span>
                      </address>
                    </div>
                    { vacationItemMapPath &&
                      <div className="col-sm-12">
                        <img src={`/images/maps/${vacationItemMapPath}`} alt={`Mapa, adres: ${vacationItem.localization.addressName} ${vacationItem.localization.addressStreet} ${vacationItem.localization.addressCityCode}`} />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="back-button-container text-center my-5">
          <Link to="/wczasy/" className="btn btn-back">Cofnij</Link>
        </div>

      </div>
    </>
  );
};

export default NewVacationDetails;