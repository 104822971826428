import React from "react";
import { useParams, Link } from "react-router-dom";
import trips from "../../Data/Trips";
import Jumbotron from "../Jumbotron/Jumbotron.";
import MetaTags from "../MetaTags";

const removePolishChars = (text) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ /g, "-")
    .replace(/ą/g, "a")
    .replace(/ć/g, "c")
    .replace(/ę/g, "e")
    .replace(/ł/g, "l")
    .replace(/ń/g, "n")
    .replace(/ó/g, "o")
    .replace(/ś/g, "s")
    .replace(/ź/g, "z")
    .replace(/ż/g, "z")
    .replace(/Ą/g, "A")
    .replace(/Ć/g, "C")
    .replace(/Ę/g, "E")
    .replace(/Ł/g, "L")
    .replace(/Ń/g, "N")
    .replace(/Ó/g, "O")
    .replace(/Ś/g, "S")
    .replace(/Ź/g, "Z")
    .replace(/Ż/g, "Z")
    .toLowerCase();
};

const TripDetails = () => {
  const { id } = useParams();
  const tripItem = trips.find(
    (item) => removePolishChars(item.title) === id
  );

  if (!tripItem) {
    return <div>Nie znaleziono wakacji</div>;
  }

  // const tripItemMapPath = tripItem.localization.mapUrl; 
  let pageTitle = tripItem.title + " | Rajza"

  return (
    <>
     <MetaTags title={pageTitle} description={`Szczegóły oferty ${pageTitle}`} />
      <div className="container-jumbotron">
        <Jumbotron images={tripItem.images} />
      </div>

      <div className="container">
        <div className="details-text">
          {/* add ifs if element exist  */}
          <h1>{tripItem.title}</h1> 
          {
            tripItem.description.length > 0 &&
            <div>
              {tripItem.description.map((item, index) => (
                <p>{item}</p>
              ))}
            </div>
          }

          <div className="row">
            <div className="col-12 col-lg-6">
              {/* dates */}
              {
                tripItem.terms.length > 0 &&
                <div>
                  <table className="dates-table">
                    <thead>
                      <tr>
                        <td>Data</td>
                        <td>Cena</td>
                      </tr>
                    </thead>
                    <tbody>
                      {tripItem.terms.map((term, index) => (
                        <tr key={index}>
                          <td>
                            {term.startDate} - {term.endDate}
                          </td>
                          <td>
                            {term.price} PLN
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              }

              {
                tripItem.services.length > 0 &&
                <div>
                  <h3 className="h5">Świadczenia zawarte w cenie </h3>
                  <ul>
                  {tripItem.services.map((item, index) => (
                    <li>{item}</li>
                  ))}
                  </ul>
                </div>
              }
            </div>
            <div className="col-12">
              <p>{tripItem.disclaimer}</p>
            </div>
          </div>

        </div>
        <div className="back-button-container text-center my-5">
          <Link to="/wycieczki/" className="btn btn-back">Cofnij</Link>
        </div>

      </div>
    </>
  );
};

export default TripDetails;