// src/pages/Kontakt.js
import React from 'react';
import MetaTags from '../Components/MetaTags';

function Kontakt() {
  return (
    <div className="container contact-page pt-3 pb-3">
      <MetaTags title="Kontakt | Biuro Podróży Rajza" description="Dane kontaktowe Biura Podróży Rajza" />
      <div className="row">
        <h2 className="mb-3 ">Kontakt</h2>
        <address className="col-sm-12 col-md-6">
          <span className="fw-bold">Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń</span>
          <span>Al. Piłsudskiego 32A</span>
          <span className="mb-2">44-335 Jastrzębie-Zdrój</span>
          <span className="fw-bold">Godziny otwarcia:</span>
          <span className="mb-2">pon. - pt.: 8:00 - 16:00</span>
          <span className="mb-2">tel. 32/473 10 08, 32/471 99 00</span>
          <span className="mb-2">e-mail: biuro@rajza.com.pl</span>
          <span className="mb-2">NIP: 633 225 17 87</span>
          <span className="mb-2">REGON: 529454740</span>
          <span className="mb-2">BANK SPÓŁDZIELCZY  78 8470 0001 2015 0081 1013 0001</span>
          <span className="mb-2">Nr wpisu do Rejestru Organizatorów Turystyki i Pośredników Turystycznych Marszałka Województwa Śląskiego: 1805</span>



        </address>
        <div className="col-sm-12 col-md-6">
          <div className="map-container">
            <div className="loader"></div>
            <iframe 
              title="Adres Biura Podróży Rajza"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2567.3098558240736!2d18.593268877699607!3d49.949289771500766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4711530b59e07941%3A0x3b225eb67f2c54b1!2sBiuro%20Podr%C3%B3%C5%BCy%20Rajza%20s.c.%20B.Szula%C5%84czyk%2C%20A.Kuglarz%2C%20W.Ledwo%C5%84!5e0!3m2!1spl!2spl!4v1729164527603!5m2!1spl!2spl"  
              height="450" 
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>

        

      </div>
    </div>
  );
}




export default Kontakt;