// src/pages/RODO.js
import React from 'react';
import FileLink from '../Components/FileLink/FileLink';
import MetaTags from '../Components/MetaTags';

const documentRoot = "/documents/rodo/"
const documents = [
  { title: "RODO",fileName: "RODO.pdf" }
]

function Rodo() {
  return (
    <div className="container">
      <MetaTags title="RODO | Biuro Podróży Rajza" description="RODO" />
      <div className="row">
        <div className="pt-3 pb-5 regulations">

        <h1> RODO </h1>

        <FileLink 
            documentRoot={documentRoot}
            documents={documents}
         />

        <h3 className="h5">INFORMACJA OGÓLNA O PRZETWARZANIU DANYCH OSOBOWYCH</h3>
        <p>
          Szanowni Państwo
          <br />
          W firmie Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń, <b>mając świadomość znaczenia przetwarzanych danych osobowych, przykładamy najwyższą wagę do zapewnienia im odpowiedniego poziomu bezpieczeństwa</b>. Dane osobowe należą do kluczowych zasobów informacyjnych firmy i jako takim zapewniamy odpowiednią ochronę.
        </p>
        <p>
          <b>Przetwarzanie Państwa danych osobowych odbywa się z poszanowaniem zasad dotyczących przetwarzania:</b>
          <br />
          - zgodności z prawem, rzetelności i przejrzystości,
          <br />
          - ograniczenia celu,
          <br />
          - minimalizacji danych,
          <br />
          - prawidłowości,
          <br />
          - ograniczenia przechowywania,
          <br />
          - integralności i poufności.
          <br />
          Wykonując obowiązki administratora danych określone w art. 13 i art. 14 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, przedstawiamy informacje należne osobom, których dane przetwarzamy w naszej firmie.
        </p>
        <p>
          <b>Wszelkie pytania dotyczące ochrony Państwa danych osobowych możecie Państwo kierować na adres:</b>
          <br />
          pisemnie: Biuro Podróży RAJZA s.c., 44-335 Jastrzębie-Zdrój, al. Piłsudskiego 32A lub elektronicznie: biuro@rajza.com.pl
          Pozostajemy z wyrazami szacunku,
          <br />
          Zespół Biura Podróży RAJZA s.c.
        </p>
        <h3 className="h5">INFORMACJA DLA OSÓB ZGŁASZAJĄCYCH SIĘ W PROCESIE REKRUTACJI PRACOWNIKÓW</h3>
        <p>
          Opis kategorii osób, których dotyczy informacja: osoby zgłaszających się w procesie rekrutacji pracowników Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń informuje - zgodnie z art. 13 ust.1 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” – iż
        </p>
        <ul>
          <li>
            współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.
          </li>
          <li>
            podstawę prawną przetwarzania Pani/Pana danych osobowych stanowi art. 6 ust.1 a) RODO - osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych w jednym lub większej liczbie określonych celów,
          </li>
          <li>
            celem przetwarzania jest prowadzenie przez administratora bieżącej i przyszłych rekrutacji pracowników, a także przesyłanie korespondencji,
          </li>
          <li>
            Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie, operatorom pocztowym,
          </li>
          <li>
            dane będą przechowywane nie dłużej niż do czasu wycofania zgody,
          </li>
          <li>
            ma Pani/Pan prawo do wycofania udzielonej zgody w dowolnym momencie,
          </li>
          <li>
            wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem,
          </li>
          <li>
            ma Pani/Pan prawo żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,
          </li>
          <li>
            podanie danych osobowych jest dobrowolne i niezbędne do udziału w rekrutacji, zaś konsekwencją niepodania danych będzie brak możliwości udziału w rekrutacji.
          </li>
        </ul>

        <p>
          Szanowni Państwo,
          <br />
          <b>Prosimy o umieszczenie w składanej aplikacji (podaniu) o pracę klauzuli o następującej treści:</b>
          <br />
        </p>
        <p>
          <i>* Wyrażam zgodę na przetwarzanie przez Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń z siedzibą 44-335 Jastrzębie-Zdrój, al. Piłsudskiego 32A, moich danych osobowych zawartych w przesłanej aplikacji (podaniu) o pracę i życiorysie (CV) w celu prowadzenia bieżącej i przyszłych rekrutacji pracowników. Wiem, że mogę wycofać zgodę w dowolnym momencie.</i>
        </p>
        <p className="text-end mb-5">
          <i>......................................................</i> <br />
          <i>data i czytelny podpis</i>
        </p>
        <p>
          <i>
            * Wiem, że - zgodnie z art. 13 ust.1 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO”:
          </i>
        </p>
        <ul className="fst-italic">
          <li>
            współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.
          </li>
          <li>
            podstawę prawną przetwarzania Pani/Pana danych osobowych stanowi art. 6 ust.1 a) RODO - osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych w jednym lub większej liczbie określonych celów,
          </li>
          <li>
            celem przetwarzania jest prowadzenie przez administratora bieżącej i przyszłych rekrutacji pracowników, a także przesyłanie korespondencji,
          </li>
          <li>
            Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie, operatorom pocztowym,
          </li>
          <li>
            dane będą przechowywane nie dłużej niż do czasu wycofania zgody,
          </li>
          <li>
            ma Pani/Pan prawo do wycofania udzielonej zgody w dowolnym momencie,
          </li>
          <li>
            wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem,
          </li>
          <li>
            ma Pani/Pan prawo żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,
          </li>
          <li>
            podanie danych osobowych jest dobrowolne i niezbędne do udziału w rekrutacji, zaś konsekwencją niepodania danych będzie brak możliwości udziału w rekrutacji.
          </li>
        </ul>
        <p className="text-end mb-5">
          <i>......................................................</i> <br />
          <i>data i czytelny podpis</i>
        </p>
        <p>UWAGA: Brak klauzuli o wskazanej treści sprawi, że nadesłane przez Państwa materiały nie będą rozpatrzone podczas rekrutacji.</p>

        <h3 className="h5">NFORMACJA DLA DOSTAWCÓW</h3>
        <p>
          Opis kategorii osób, których dotyczy informacja: osoby fizyczne, które sprzedały firmie Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń produkty lub usługi Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń informuje - zgodnie z art. 13 ust.1 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” – iż:
        </p>
        <ul>
          <li>
            współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.
          </li>
          <li>
            Pani/Pana dane osobowe: imię i nazwisko (nazwa), adres zamieszkania lub siedziby, NIP i inne dane, które podał Pani/Pan według własnej woli, przetwarzane będą w związku z zawarciem i wykonaniem umowy, przy czym podstawę prawną ich przetwarzania stanowi art. 6 ust.1 b) RODO - przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy, zaś celem przetwarzania jest zawarcie i wykonanie umowy, której przedmiotem jest kupno-sprzedaż produktów lub/i usług, a także doręczanie Pani/Panu korespondencji,
          </li>
          <li>
            przetwarzanie Pani/Pana danych jest dokonywane także w oparciu o podstawę prawną, którą stanowi art. 6 ust.1 c) RODO - przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze, polegającego na przechowywaniu dokumentacji przez czas określony w przepisach, zaś celem przetwarzania jest przechowywanie dokumentacji przez czas określony w przepisach,
          </li>
          <li>
            przetwarzanie Pani/Pana danych jest dokonywane ponadto w oparciu o podstawę prawną, którą stanowi art. 6 ust. 1 f) RODO - przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora, którymi są obrona i dochodzenie roszczeń, a także możliwość kontaktowania się z dostawcą w przyszłości,
          </li>
          <li>
            Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie, operatorom pocztowym w celu doręczania korespondencji,
          </li>
          <li>
            dane będą przechowywane także po wykonaniu umowy, a ich usunięcie nastąpi nie później niż 1 rok po zdarzeniu, które nastąpi później - zakończeniu okresu obowiązkowego przechowywania dokumentacji księgowej i podatkowej określonego w przepisach lub zakończeniu okresu przedawnienia dochodzenia i obrony roszczeń określonego w przepisach; w zakresie danych przetwarzanych na podstawie art. 6 ust. 1 f) RODO – usunięcie nastąpi bezzwłocznie po uwzględnieniu ewentualnego sprzeciwu,
          </li>
          <li>
            ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,
          </li>
          <li>
            podanie danych osobowych jest dobrowolne, lecz niezbędne do zawarcia umowy, zaś konsekwencją niepodania danych będzie niemożność zawarcia umowy.
          </li>
        </ul>
        <p>
          Ponadto, na podstawie art. 21 ust.1 RODO, ma Pani/Pan prawo do wniesienia w dowolnym momencie sprzeciwu - z przyczyn związanych z Pani/Pana szczególną sytuacją - wobec przetwarzania Pani/Pana danych opartego na 6 ust.1 f) RODO.
        </p>

        <h3 className="h5">INFORMACJA DLA KLIENTÓW</h3>
        <p>
          Opis kategorii osób, których dotyczy informacja: osoby nabywające od firmy Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń produkty lub usługi Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń informuje - zgodnie z art. 13 ust.1 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” – iż:
        </p>
        <ul>
          <li>
            współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.
          </li>
          <li>
            Pani/Pana dane osobowe przetwarzane będą w związku z zawarciem i wykonaniem umowy, przy czym podstawę prawną ich przetwarzania stanowi art. 6 ust.1 b) RODO - przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, zaś celem przetwarzania jest zawarcie i wykonanie umowy, której przedmiotem jest kupno-sprzedaż produktów lub/i usług oraz doręczanie Pani/Panu korespondencji,
          </li>
          <li>
            przetwarzanie Pani/Pana danych jest dokonywane także w oparciu o podstawę prawną, którą stanowi art. 6 ust.1 c) RODO - przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze, polegającego na przechowywaniu dokumentacji przez czas określony w przepisach, zaś celem przetwarzania jest przechowywanie dokumentacji przez czas określony w przepisach,
          </li>
          <li>
            przetwarzanie Pani/Pana danych jest dokonywane ponadto w oparciu o podstawę prawną, którą stanowi art. 6 ust. 1 f) RODO - przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora, którymi są obrona i dochodzenie roszczeń,
          </li>
          <li>
            Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie, operatorom pocztowym w celu doręczania korespondencji,
          </li>
          <li>
            w przypadku jeśli uczestniczy Pani/Pan w imprezie turystycznej, to dane mogą być ujawniane także następującym kategoriom odbiorców: partnerom współpracującym z administratorem, dostarczającym poszczególne usługi składające się na imprezę turystyczną, takim jak: hotele, przewoźnicy i firmy transportowe, w tym linie lotnicze, piloci, przewodnicy, lokalni kontrahenci zapewniający organizację czasu lub zwiedzanie w miejscu imprezy lub pobytu, elektroniczne systemy rezerwacji, za pomocą których rezerwowane są poszczególne usługi, etc.,
          </li>
          <li>
            jeśli uczestniczy Pani/Pan w zagranicznej imprezie turystycznej Pani/Pana dane osobowe mogą być przez administratora danych przekazywane do państw trzecich, poza Europejski Obszar Gospodarczy, gdzie nie stosuje się przepisów europejskich, co wiąże się z tym, że ochrona danych osobowych obywateli Unii Europejskiej może być niewystarczająca; w odniesieniu do części z tych państw Komisja Europejska nie wydała pozytywnej rekomendacji, co do poziomu bezpieczeństwa danych osobowych,
          </li>
          <li>
            przekazanie Pani/Pana danych osobowych do państwa trzeciego może być niezbędne w celu wykonania umowy lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną, zaś podstawą prawną przekazania danych do państwa trzeciego jest art. 49 ust. 1 b) lub c) RODO - przekazanie jest niezbędne do wykonania umowy między osobą, której dane dotyczą, a administratorem lub do wprowadzenia w życie środków przedumownych podejmowanych na żądanie osoby, której dane dotyczą, lub przekazanie jest niezbędne do zawarcia lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną,
          </li>
          <li>
            dane będą przechowywane także po wykonaniu umowy, a ich usunięcie nastąpi nie później niż 1 rok po zdarzeniu, które nastąpi później - zakończeniu okresu obowiązkowego przechowywania dokumentacji księgowej i podatkowej określonego w przepisach lub zakończeniu okresu przedawnienia dochodzenia i obrony roszczeń określonego w przepisach; w zakresie danych przetwarzanych na podstawie art. 6 ust. 1 f) RODO – usunięcie nastąpi bezzwłocznie po uwzględnieniu ewentualnego sprzeciwu,
          </li>
          <li>
            ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,
          </li>
          <li>
            podanie danych osobowych jest dobrowolne, lecz niezbędne do zawarcia umowy, zaś konsekwencją niepodania danych będzie niemożność zawarcia umowy.
          </li>
        </ul>
        <p>Ponadto, na podstawie art. 21 ust.1 RODO, ma Pani/Pan prawo do wniesienia w dowolnym momencie sprzeciwu - z przyczyn związanych z Pani/Pana szczególną sytuacją - wobec przetwarzania Pani/Pana danych opartego na 6 ust.1 f) RODO.</p>

        <h3 className="h5">INFORMACJA DLA PODRÓŻNYCH</h3>
        <p>Opis kategorii osób, których dotyczy informacja: podróżni - osoby, które są uczestnikami imprez turystycznych organizowanych na zlecenie podmiotu trzeciego (instytucji, pracodawcy, organizacji, etc.) lub są uczestnikami imprez w wyniku zawarcia na ich rzecz umowy przez osobę trzecią. Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń informuje - zgodnie z art. 14 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” – iż:</p>

        <ul>
          <li>
            współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.
          </li>
          <li>
            Pani/Pana dane osobowe otrzymaliśmy od naszego klienta – podmiotu, który zlecił administratorowi organizację imprezy turystycznej, której jest Pani/Pan uczestnikiem, albo od osoby, która zawarła umowę o usługę na Pani/Pana rzecz,</li>
          <li>
            kategorie odnośnych danych osobowych to: imię i nazwisko, data urodzenia, adres zamieszkania, adres korespondencyjny, seria i nr paszportu, data wydania paszportu, data ważności paszportu, organ, który wydał paszport, nr telefonu, adres e-mail,</li>
          <li>
            podstawę prawną przetwarzania Pani/Pana danych osobowych stanowi art. 6 ust.1 f) RODO - przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora - interesami tymi są:
            
            <ul>
              <li>
                wykonanie umowy zawartej z podmiotem zlecającym organizację imprezy lub osobą, która zawarła umowę o usługę na rzecz uczestnika,
              </li>
              <li>
                możliwość kontaktu z uczestnikiem imprezy w celu zapewnienia prawidłowego przebiegu imprezy,
              </li>
              <li>
                możliwość przedstawienia historii uczestnictwa w imprezach turystycznych, na życzenie osoby, której dane dotyczą,
              </li>
              <li>
                dochodzenie roszczeń,
              </li>
            </ul>

          </li>
          <li>
            cele przetwarzania to:
            
            <ul>
              <li>
                wykonanie umowy zawartej z podmiotem zlecającym organizację imprezy lub osobą, która zawarła umowę o usługę na rzecz uczestnika, w tym m.in. umieszczenie na liście uczestników imprezy, rezerwacja biletów, hoteli, transportu, imprez fakultatywnych, rozpatrzenie ewentualnych reklamacji,
              </li>
              <li>
                możliwość kontaktu z uczestnikiem imprezy w celu zapewnienia prawidłowego przebiegu imprezy,
              </li>
              <li>
                możliwość przedstawienia historii uczestnictwa w imprezach turystycznych, na życzenie osoby, której dane dotyczą
              </li>
              <li>
                dochodzenie i obrona roszczeń,
              </li>
            </ul>

          </li>
          <li>
            jeśli uczestniczy Pani/Pan w zagranicznej imprezie turystycznej Pani/Pana dane osobowe mogą być przez administratora danych przekazywane do państw trzecich, poza Europejski Obszar Gospodarczy, gdzie nie stosuje się przepisów europejskich, co wiąże się z tym, że ochrona danych osobowych obywateli Unii Europejskiej może być niewystarczająca; w odniesieniu do części z tych państw Komisja Europejska nie wydała pozytywnej rekomendacji, co do poziomu bezpieczeństwa danych osobowych,</li>
          <li>
            przekazanie Pani/Pana danych osobowych do państwa trzeciego może być niezbędne w celu wykonania umowy lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną, zaś podstawą prawną przekazania danych do państwa trzeciego jest art. 49 ust. 1 b) lub c) RODO - przekazanie jest niezbędne do wykonania umowy między osobą, której dane dotyczą, a administratorem lub do wprowadzenia w życie środków przedumownych podejmowanych na żądanie osoby, której dane dotyczą, lub przekazanie jest niezbędne do zawarcia lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną,
          </li>
          <li>
            Pani/Pana dane będą ujawniane kategoriom odbiorców wśród których znajdują się: upoważnione i przeszkolone osoby przetwarzające dane, podmioty przetwarzające na podstawie umów powierzenia przetwarzania zawartych na piśmie, operatorzy pocztowi w celu doręczania korespondencji, partnerzy współpracujący z administratorem, dostarczający poszczególne usługi składające się na imprezę turystyczną, tacy jak: hotele, przewoźnicy i firmy transportowe, w tym linie lotnicze, piloci, przewodnicy, lokalni kontrahenci zapewniający organizację czasu lub zwiedzanie w miejscu imprezy lub pobytu, elektroniczne systemy rezerwacji, za pomocą których rezerwowane są poszczególne usługi, etc.,
          </li>
          <li>
            dane będą przechowywane przez okres nie dłuższy niż 6 miesięcy po zdarzeniu, które nastąpi później - zakończeniu okresu przedawnienia dochodzenia i obrony roszczeń określonego w ustawie o imprezach turystycznych i powiązanych usługach turystycznych lub uwzględnienia ewentualnego sprzeciwu,
          </li>
          <li>
            ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
          </li>
        </ul>
        <p>
          Ponadto, na podstawie art. 21 ust.1 RODO, ma Pani/Pan prawo do wniesienia w dowolnym momencie sprzeciwu - z przyczyn związanych z Pani/Pana szczególną sytuacją - wobec przetwarzania Pani/Pana danych opartego na 6 ust.1 f) RODO.
        </p>

        <h3 className="h5">INFORMACJA DLA UBEZPIECZONYCH NA IMPREZACH ZAGRANICZNYC</h3>
        <p>
          Opis kategorii osób, których dotyczy informacja: Uczestnicy imprez zagranicznych podlegający obowiązkowemu ubezpieczeniu przez administratora w zakresie NNW i KL
        </p>
        <p>
          Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.informuje - zgodnie z art. 13 i 14 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” – iż:
        </p>

        <ul>
          <li>
            współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A</li>
          <li>
            Pani/Pana dane osobowe otrzymaliśmy od Pani/Pana albo od naszego klienta – podmiotu, który zlecił administratorowi organizację imprezy turystycznej, której jest Pani/Pan uczestnikiem, albo od osoby, która zawarła umowę o usługę na Pani/Pana rzecz,
          </li>
          <li>
            kategorie odnośnych danych osobowych to: imię i nazwisko, data urodzenia, adres zamieszkania,
          </li>
          <li>
            podstawę prawną przetwarzania Pani/Pana dane osobowe stanowi art. 6 ust.1 c) RODO - przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze,
          </li>
          <li>
            celem przetwarzania jest dopełnienie obowiązków wynikających z ustaw:
            <br />
            <ul>
              <li>
                obowiązkowego ubezpieczenia w zakresie NNW i KL uczestników imprez za granicą na podstawie ustawy o imprezach turystycznych i powiązanych usługach turystycznych,
              </li>
              <li>
                obowiązku przechowywania dokumentacji wynikającego z przepisów o obowiązkowym okresie przechowywania dokumentacji obowiązkowych ubezpieczeń i dokumentacji księgowo-podatkowej,
              </li>
            </ul>
          </li>
          <li>
            Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie, towarzystwu ubezpieczeniowemu wystawiającemu polisę ubezpieczenia, podmiotom udzielającym świadczeń finansowanych z polisy ubezpieczenia,
          </li>
          <li>
            Pani/Pana dane osobowe mogą być przez administratora danych przekazywane do państw trzecich, poza Europejski Obszar Gospodarczy, gdzie nie stosuje się przepisów europejskich, co wiąże się z tym, że ochrona danych osobowych obywateli Unii Europejskiej może być niewystarczająca; w odniesieniu do części z tych państw Komisja Europejska nie wydała pozytywnej rekomendacji, co do poziomu bezpieczeństwa danych osobowych,
          </li>
          <li>
            przekazanie Pani/Pana danych osobowych do państwa trzeciego może być niezbędne w celu wykonania umowy lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną, zaś podstawą prawną przekazania danych do państwa trzeciego jest art. 49 ust. 1 b) lub c) RODO - przekazanie jest niezbędne do wykonania umowy między osobą, której dane dotyczą, a administratorem lub do wprowadzenia w życie środków przedumownych podejmowanych na żądanie osoby, której dane dotyczą, lub przekazanie jest niezbędne do zawarcia lub wykonania umowy zawartej w interesie osoby, których dane dotyczą, między administratorem a inną osobą fizyczną lub prawną,
          </li>
          <li> dane będą przechowywane nie dłużej niż 1 rok po zakończeniu okresu obowiązkowego przechowywania dokumentacji obowiązkowych ubezpieczeń i dokumentacji księgowo-podatkowej określonego w przepisach,
          </li>
          <li>
            ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
          </li>
        </ul>
        <p>Ponadto, na podstawie art. 21 ust.1 RODO, ma Pani/Pan prawo do wniesienia w dowolnym momencie sprzeciwu - z przyczyn związanych z Pani/Pana szczególną sytuacją - wobec przetwarzania Pani/Pana danych opartego na 6 ust.1 f) RODO.</p>

        <h3 className="h5">INFORMACJA DLA DOKONUJĄCYCH REZERWACJI MIEJSC NA IMPREZIE TURYSTYCZNEJ</h3>
        <p>Opis kategorii osób, których dotyczy informacja: Osoby fizyczne, z którymi zawarto umowę o rezerwację miejsca na imprezie turystycznej Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń informuje - zgodnie z art. 13 ust.1 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” – iż:</p>

        <ul>
          <li>
            współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.
          </li>
          <li>
            Pani/Pana dane osobowe przetwarzane będą w związku z zawarciem umowy rezerwacji, przy czym podstawę prawną ich przetwarzania stanowi art. 6 ust.1 b) RODO - przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy,
          </li>
          <li>
            celem przetwarzania jest zawarcie i wykonanie umowy o rezerwację miejsca na konkretnej imprezie turystycznej,
          </li>
          <li>
            Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie,
          </li>
          <li>
            dane będą przechowywane nie dłużej niż 6 miesięcy od daty podania danych przy dokonywaniu rezerwacji,
          </li>
          <li>
            ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, żądania usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,
          </li>
          <li>
            podanie danych osobowych jest dobrowolne, lecz niezbędne do dokonania rezerwacji miejsca na konkretnej imprezie turystycznej, zaś konsekwencją niepodania danych będzie niemożność dokonania takiej rezerwacji.
          </li>
        </ul>

        <h3 className="h5">INFORMACJA DLA OSÓB BĘDĄCYCH PRZEDSTAWICIELAMI LUB PRACOWNIKAMI DOSTAWCÓW ALBO KUPUJĄCYCH PRODUKTY LUB USŁUGI</h3>
        <p>
          Opis kategorii osób, których dotyczy informacja: osoby będące przedstawicielami lub pracownikami dostawców produktów lub usług dla firmy Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń albo kupujących od firmy Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń produkty lub usługi Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń informuje - zgodnie z art. 13 ust.1 i art. 14 ust. 1 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego dalej „RODO” – iż:
        </p>
        <ul>
          <li>
            współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A.
          </li>
          <li>
            podstawę prawną przetwarzania Pani/Pana danych osobowych stanowi art. 6 ust.1 f) RODO - przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora - interesami tymi są:
            <br />

            <ul>
              <li>zawarcie i wykonanie umowy kupna i sprzedaży produktów i usług,</li>
              <li>wykonanie obowiązku przechowywania dokumentacji przez określony w przepisach czas,</li>
              <li>obrona i dochodzenie roszczeń przez administratora,</li>
            </ul>
          </li>
          <li>
            przetwarzanie Pani/Pana danych jest dokonywane także w oparciu o podstawę prawną, którą stanowi art. 6 ust.1 c) RODO - przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze, polegającego na przechowywaniu dokumentacji przez czas określony w przepisach, zaś celem przetwarzania jest przechowywanie dokumentacji przez czas określony w przepisach,
          </li>
          <li>
            cele przetwarzania to:
            <br />
            <ul>
              <li>zawarcie i wykonanie umowy kupna i sprzedaży produktów i usług z pracodawcą osoby lub podmiotem reprezentowanym przez osobę,</li>
              <li>wykonanie obowiązku przechowywania dokumentacji przez określony w przepisach czas,</li>
              <li>obrona i dochodzenie roszczeń przez administratora,</li>
            </ul>
          </li>
          <li>źródłem pochodzenia Pani/Pana danych osobowych jest osoba, której dane dotyczą albo pracodawca osoby, której dane dotyczą lub podmiot reprezentowany przez osobę, której dane dotyczą,</li>
          <li>jeśli źródłem pochodzenia Pani/Pana danych osobowych nie jest osoba, której dane dotyczą to kategorie odnośnych danych osobowych to: imię i nazwisko, miejsce pracy, stanowisko,</li>
          <li>Pani/Pana dane będą ujawniane następującym kategoriom odbiorców: upoważnionym i przeszkolonym osobom przetwarzającym dane, podmiotom przetwarzającym na podstawie umów powierzenia przetwarzania zawartych na piśmie,</li>
          <li>usunięcie danych nastąpi nie później niż 1 rok po zdarzeniu, które nastąpi później - zakończeniu okresu obowiązkowego przechowywania dokumentacji księgowej i podatkowej określonego w przepisach lub zakończeniu okresu przedawnienia dochodzenia i obrony roszczeń określonego w przepisach; w zakresie danych przetwarzanych na podstawie art. 6 ust. 1 f) RODO – usunięcie nastąpi bezzwłocznie po uwzględnieniu ewentualnego sprzeciwu,</li>
          <li>ma Pani/Pan prawo do żądania od administratora dostępu do danych osobowych dotyczących Pani/Pana osoby, żądania ich sprostowania, usunięcia, ograniczenia ich przetwarzania, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</li>
        </ul>
        <p>
          Ponadto, na podstawie art. 21 ust.1 RODO, ma Pani/Pan prawo do wniesienia w dowolnym momencie sprzeciwu - z przyczyn związanych z Pani/Pana szczególną sytuacją - wobec przetwarzania Pani/Pana danych opartego na 6 ust.1 f) RODO.
        </p>






        </div>
      </div>
    </div>
  );
}

export default Rodo;