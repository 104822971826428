// src/pages/PolitykaPrywatnosci.js
import React from 'react';
import FileLink from '../Components/FileLink/FileLink';
import MetaTags from '../Components/MetaTags';

const documentRoot = "/documents/polityka-prywatnosci/"
const documents = [
  { title: "Polityka Prywatności",fileName: "POLITYKA_PRYWATNOSCI.pdf" }
]

function PolitykaPrywatnosci() {
  return (
    <div className="container">
      <MetaTags title="Polityka prywatności | Biuro Podróży Rajza" description="Polityka prywatności" />
      <div className="row">
        <div className="pt-3 pb-3 regulations">

        <h2> POLITYKA PRYWATNOŚCI </h2>

        <FileLink 
            documentRoot={documentRoot}
            documents={documents}
         />

        <p>Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń, operator strony www.rajza.com.pl nie przetwarza, nie udostępnia ani nie wykorzystuje w celach marketingowych danych przekazywanych przez Użytkowników strony www.rajza.com.pl
        (zwanej dalej "Stroną BP RAJZA”).</p>
        <p>Na Stronie BP RAJZA stosuje się mechanizm tzw. cookies czyli plików tekstowych przechowywanych na dysku komputera użytkownika strony. Mechanizm cookies nie ma na celu pozyskiwania jakichkolwiek informacji o indywidualnych użytkownikach strony - stosowany jest w celu zachowania parametrów nawigacyjnych użytkownika oraz gromadzenia danych statystycznych o ruchu użytkowników strony.</p>
        <ol className="letters">
          <li>Użytkownik może usunąć pliki cookies lub zmienić ich ustawienia w przeglądarce internetowej.</li>
          <li>Usunięcie lub zmiana ustawień plików cookies w przeglądarce może się wiązać z utrudnieniami w korzystaniu z Serwisu.</li>
          <li> Jakiego rodzaju ciasteczka są używane na Stronie BP RAJZA?
            <br />
            <ul>
              <li>Ciasteczka sesji: działają one tylko podczas trwania wizyty i pozwalają nam określić, że ta sama osoba porusza się ze strony na stronę. </li>
              <li>Ciasteczka trwałe: pozostają one na Twoim komputerze po odwiedzeniu naszej strony, co oznacza, że witryna może zapamiętać Twoje ustawienia kiedy nas odwiedzasz. </li>
              <li>Ciasteczka osób trzecich: umożliwiają one umieszczenie zawartości zewnętrznej z innych stron internetowych w ramach naszej witryny. </li>
            </ul>
          </li>
          <li>Szczegółowe wyjaśnienia co powoduje usunięcie plików cookies oraz instrukcje, jak to zrobić można znaleźć poniżej w sekcji FAQ.</li>
        </ol>
        <p>Dane pozyskane w przypadku analizy logów serwera (adres IP, domena) wykorzystywane są jedynie w celach prowadzenia statystyk oglądalności strony oraz do administracji serwerem firmy Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń.</p>
        <p>Polityka prywatności stron zewnętrznych, do których odnośniki znajdują się na Stronie BP RAJZA jest regulowana osobnymi postanowieniami.</p>
        <p>W przypadku dodatkowych pytań dotyczących polityki prywatności, prosimy o kontakt z administratorem serwisu: e-mail: <a href="mailto:biuro@rajza.com.pl">biuro@rajza.com.pl</a> W przypadku zmiany obowiązującej polityki prywatności, odpowiednie modyfikacje powyższych zasad zostaną udostępnione na Stronie BP RAJZA.</p>

        <h3>FAQ ciasteczek</h3>
        <p className="fw-bold">Czy mogę zrezygnować z akceptowania cookies?</p>
        <p>Czynności związane z przechowywaniem i wysyłaniem ciasteczek są obsługiwane przez przeglądarki internetowe i są niewidoczne dla użytkownika. Większość używanych przeglądarek domyślnie akceptuje Ciasteczka. Jednakże użytkownik, może ustawić tak przeglądarkę, by móc odrzucać prośby o przechowanie ciasteczek w ogóle lub wybranych ciasteczek.</p>
        <p>Można to zrobić za pomocą ustawień w przeglądarce. Zanim zdecydujesz się zmienić domyślne ustawienia pamiętaj jednak, że wiele Ciasteczek pomaga podniesieniu wygody korzystania ze strony.</p>
        <p>Wyłączenie Ciasteczek może mieć wpływ na to jak będzie się wyświetlać nasza strona w Twojej przeglądarce. Jak wyłączyć obsługę ciasteczek?</p>
        <p className="mb-0">Dla przeglądarki Firefox:</p>
        <p className="mb-0">Na górze okna przeglądarki naciśnij przycisk Firefox i wybierz Opcje. Następnie wybierz panel Prywatność.</p>
        <p className="mb-0">Z menu rozwijanego elementu Program Firefox: wybierz opcję: będzie używał ustawień historii użytkownika.</p>
        <ul>
          <li>Zaznacz Akceptuj ciasteczka, by włączyć, odznacz, by wyłączyć obsługę ciasteczek.</li>
          <li>Wybierz okres przechowywania ciasteczek:<br />
            <ol className="letters">
              <li>Przechowuj: aż wygasną - ciasteczko zostanie usunięte po osiągnięciu daty wygaśnięcia, która jest określana przez witrynę wysyłającą ciasteczko.</li>
              <li>Przechowuj: do zamknięcia programu Firefox - ciasteczka przechowywane na komputerze będą usuwane przy zamknięciu Firefoksa.</li>
              <li>Przechowuj: pytaj za każdym razem - za każdym razem, gdy witryna próbuje wysłać ciasteczko, przeglądarka wyświetli monit z prośbą o podjęcie decyzji w sprawie przechowywania ciasteczka.</li>
            </ol>
          </li>
          <li>Naciśnij przycisk OK, by zamknąć okno opcji.</li>
        </ul>

        <p className="mb-0">Dla przeglądarki Google Chrome:</p>
        <ul>
          <li>Kliknij menu Chrome na pasku narzędzi przeglądarki.</li>
          <li>Wybierz Ustawienia.</li>
          <li>Kliknij Pokaż ustawienia zaawansowane.</li>
          <li>W sekcji Prywatność kliknij przycisk Ustawienia treści.</li>
          <li>W sekcji Pliki cookie możesz zmienić następujące ustawienia plików cookie: <br />
            <ol className="letters">
              <li>Domyślne blokowanie plików cookie <br />
              - Blokowanie wszystkich plików cookie: wybierz Blokuj próby umieszczenia na komputerze danych z witryn. Pamiętaj, że to ustawienie uniemożliwia działanie większości stron, które wymagają zalogowania się. <br />
              - Blokowanie tylko plików cookie innych firm: zaznacz pole wyboru Ignoruj wyjątki i blokuj tworzenie plików cookie innych firm. <br />
              Zaznaczenie tego pola wyboru powoduje odrzucanie pochodzących z witryny plików cookie innych firm, nawet jeśli witryna została dodana do listy Wyjątki i może tworzyć pliki cookie na komputerze.
              </li>
              <li>Domyślne zezwalanie na pliki cookie <br />
              Jeśli chcesz zezwalać zarówno na własne pliki cookie, jak i innych firm, zaznacz Zezwalaj na przechowywanie danych lokalnie. <br />
              Aby akceptować tylko własne pliki cookie, zaznacz pole wyboru Blokuj bez wyjątku wszystkie pliki cookie innych firm.
              </li>
            </ol>
          </li>
        </ul>
        <p className="mb-0">Dla przeglądarki Firefox:</p>
        <ul>
          <li>W oknie przeglądarki kliknij przycisk Narzędzia, a następnie kliknij polecenie Opcje internetowe.</li>
          <li>Kliknij kartę Prywatność, a następnie w obszarze Ustawienia przesuń suwak do najwyższego położenia, aby zablokować wszystkie pliki cookie, lub do najniższego położenia, aby zezwolić na wszystkie pliki cookie, a następnie kliknij przycisk OK.</li>
        </ul>

        </div>
      </div>
    </div>
  );
}

export default PolitykaPrywatnosci;