import React from 'react';
import './styles.scss';

// example data model
// const images = [
//   { url: 'https://picsum.photos/200/300.webp', alt: 'First image' },
//   { url: 'https://picsum.photos/200/300.webp', alt: 'Second image' },
//   { url: 'https://picsum.photos/200/300.webp', alt: 'Third image' },
// ];

const Jumbotron = ({ images }) => {
  return (
    <div id="carouselExample" className="carousel slide">
      <div className="carousel-inner">
        {images.map((image, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <img src={image.url} className="d-block" alt={image.alt} />
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Jumbotron;