import { TRIPS } from './Globals';

const trips = [  
  // mockup
  // {
  //   title: "",
  //   description: [
  //     ""
  //   ],
  //   images: [
  //     {
  //       url: "/images/trips/zdj_1.jpg",
  //       alt: "Zdjęcia Hotelu Paula Pobierowo"
  //     },
  //     {
  //       url: "/images/trips/zdj_2.jpg",
  //       alt: "Zdjęcia Hotelu Paula Pobierowo"
  //     },
  //     {
  //       url: "/images/trips/zdj_3.jpg",
  //       alt: "Zdjęcia Hotelu Paula Pobierowo"
  //     },
  //     {
  //       url: "/images/trips/zdj_4.jpg",
  //       alt: "Zdjęcia Hotelu Paula Pobierowo"
  //     },
  //     {
  //       url: "/images/trips/zdj_5.jpg",
  //       alt: "Zdjęcia Hotelu Paula Pobierowo"
  //     }
  //   ],
  //   terms: [
  //     {
  //       startDate: "",
  //       endDate: "",
  //       price: ""
  //     },
  //     {
  //       startDate: "",
  //       endDate: "",
  //       price: ""
  //     }
  //   ],
  //   services:[ 
  //     "", 
  //     ""
  //   ],
  //   vacationType: TRIPS,
  //   price: 2490,
  //   lowerPrice: 2490,
  //   disclaimer: ""
  // },
  // end of mockup
  {
    title: "WARMIA i MAZURY",
    description: [
      "ZWIEDZANIE i WYPOCZYNEK",
      " Gietrzwałd - Olsztyn - Święta Lipka - Gierłoż (Wilczy Szaniec) - Giżycko - Mikołajki",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godz. rannych, przejazd do GIETRZWAŁDU - zwiedzanie warmińskiego Sanktuarium Maryjnego, zwanego „polskim Lourdes”. Przejazd do OLSZTYNA - stolicy Warmii i Mazur. Spacer wokół rynku Starego Miasta; zabytkowe kamieniczki, gotycki ratusz. Czas wolny. Przyjazd do hotelu, obiadokolacja, nocleg.",
      "2 dzień - śniadanie. Czas wolny na wypoczynek.  Obiadokolacja. Nocleg.",
      "3 dzień - śniadanie, przejazd do ŚWIĘTEJ LIPKI - Częstochowy Północy, gdzie znajduje się Sanktuarium Maryjne, odwiedzane przez pielgrzymów już od XV w., wysłuchanie koncertu organowego. Wyjazd do GIERŁOŻY, zwiedzanie dawnej kwatery Adolfa Hitlera - „Wilczego Szańca”. Następnie przejazd do GIŻYCKA. Zwiedzanie miasta, w programie: Twierdza Boyen, spacer wzdłuż kanału Łuczańskiego, most obrotowy, wieża ciśnień (punkt widokowy). Przyjazd do hotelu, obiadokolacja, nocleg.",
      "4 dzień - śniadanie. Czas wolny na wypoczynek. Obiadokolacja. Nocleg.",
      "5 dzień - śniadanie, wykwaterowanie. Przejazd do MIKOŁAJEK - perły Mazur i mekki żeglarzy. Spacer po miasteczku, rejs statkiem spacerowym (dla chętnych) po jeziorach (według aktualnego rozkładu rejsów). Przyjazd do domu w godzinach wieczornych."
    ],
    images: [
      {
        url: "/images/trips/warmia_1.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK - Gietrzwałd - Olsztyn - Święta Lipka - Gierłoż (Wilczy Szaniec) - Giżycko - Mikołajki"
      },
      {
        url: "/images/trips/warmia_2.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK - Gietrzwałd - Olsztyn - Święta Lipka - Gierłoż (Wilczy Szaniec) - Giżycko - Mikołajki"
      },
      {
        url: "/images/trips/warmia_3.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK - Gietrzwałd - Olsztyn - Święta Lipka - Gierłoż (Wilczy Szaniec) - Giżycko - Mikołajki"
      }
    ],
    terms: [
      {
        startDate: "Termin na zapytanie",
        endDate: "",
        price: "1495"
      }
    ],
    services:[ 
     "przejazd autokarem",
      "4 noclegi",
      "wyżywienie: 4 śniadania i 4 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie NNW, TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 1495,
    lowerPrice: 1495,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty lokalnych przewodników oraz opłaty klimatycznej. "
  },
  {
    title: "KAZIMIERZ DOLNY, SANDOMIERZ i LUBLIN",
    description: [
      "ZWIEDZANIE i WYPOCZYNEK",
      "Kazimierz Dolny - Sandomierz - Nałęczów - Lublin - Kraśnik",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach rannych, przejazd do SANDOMIERZA, zwiedzanie miasta m.in.: Brama Opatowska, Stare Miasto, ratusz z XIV w. (z zewnątrz), Dom Długosza, gotycka katedra Narodzenia NMP z bizantyjsko-ruską polichromią, kościół św. Jakuba, którego mury jako jedyne przetrwały tatarski najazd i zniszczenie całego Sandomierza. Przejazd do hotelu, zakwaterowanie, obiadokolacja i nocleg.",
      "2 dzień - śniadanie. Czas wolny na wypoczynek. Obiadokolacja. Nocleg.",
      "3 dzień - śniadanie. Przejazd do KAZIMIERZA DOLNEGO nazywanego „Perłą nad Wisłą” - zwiedzanie: rynek, zabytkowe kamienice braci Przybyłów z wizerunkami ich patronów, kościół farny św. Jana Chrzciciela i św. Bartłomieja, Góra Trzech Krzyży (wspaniała panorama), ruiny zamku z XIV w., baszta. Czas wolny. Następnie przejazd do NAŁĘCZOWA - zwiedzanie: Park Zdrojowy z pijalnią wód mineralnych, pałac Małachowskich, Muzeum Stefana Żeromskiego tzw. „Chata”. Powrót do hotelu, obiadokolacja, nocleg.",
      "4 dzień - śniadanie. Czas wolny na wypoczynek.  Obiadokolacja. Nocleg.",
      "5 dzień - śniadanie. przejazd do LUBLINA - zwiedzanie m.in. Brama Krakowska, Stare Miasto, Ratusz z gmachem Trybunału Koronnego, Archikatedra Lubelska możliwość zwiedzenia: Zakrystii Akustycznej (słowa wypowiedziane szeptem w jednym z naroży są doskonale słyszane po przeciwnej stronie), Skarbca i Krypt.",
      "Następnie Wzgórze Zamkowe w miarę możliwości rezerwacji zwiedzanie Muzeum Lubelskiego. Powrót do hotelu, obiadokolacja, nocleg.",
      "6 dzień - śniadanie, wykwaterowanie. Przejazd do KRAŚNIKA spacer po mieście oraz zwiedzanie MUZEUM 24 PUŁKU UŁANÓW, które posiada zbiory regionalistyczne, historyczne oraz etnograficzne. Przyjazd do domu w godzinach wieczornych."
    ],
    images: [
      {
        url: "/images/trips/kazimierz_1.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK Kazimierz Dolny - Sandomierz - Nałęczów - Lublin - Kraśnik"
      },
      {
        url: "/images/trips/kazimierz_2.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK Kazimierz Dolny - Sandomierz - Nałęczów - Lublin - Kraśnik"
      },
      {
        url: "/images/trips/kazimierz_3.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK Kazimierz Dolny - Sandomierz - Nałęczów - Lublin - Kraśnik"
      }
    ],
    terms: [
      {
        startDate: "10.06",
        endDate: "15.06.2025",
        price: "1660"
      }
    ],
    services:[ 
      "przejazd autokarem",
      "5 noclegów",
      "wyżywienie: 5 śniadań i 5 obiadokolacji",
      "opieka pilota",
      "ubezpieczenie NNW, TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 1660,
    lowerPrice: 1660,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty lokalnych przewodników oraz opłaty klimatycznej. "
  },
  {
    title: "KASZUBY i GDAŃSK",
    description: [
      "ZWIEDZANIE i WYPOCZYNEK",
      "Toruń - Bytów - Szymbark - Chmielno - Gdańsk - Grudziądz",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach rannych, przejazd do TORUNIA.  Zwiedzanie m.in.: Rynek Staromiejski z Ratuszem wpisany na Światową Listę Dziedzictwa Kulturowego UNESCO, Kamienica pod Gwiazdą, Dwór Artusa, pomnik Kopernika, Katedra św. Janów – miejsce chrztu Mikołaja Kopernika, średniowieczne mury miejskie, bramy (Klasztorna, Mostowa), słynna baszta - Krzywa Wieża, spichlerze. Spacer urokliwymi uliczkami o niepowtarzalnym gotyckim klimacie. Możliwość zakupienia słynnych toruńskich pierników. Przejazd do hotelu, zakwaterowanie, obiadokolacja i nocleg.",
      "2 dzień - śniadanie. Czas wolny na wypoczynek. Obiadokolacja. Nocleg.",
      "3 dzień - śniadanie. Przejazd do BYTOWA zwiedzanie Zamku z XV w. który uważany jest za jedną z największych warowni krzyżackich. Następnie przejazd do SZYMBARKU wizyta w Centrum Edukacji i Promocji Regionu min. najdłuższa Deska Świata, Dom Sybiraka, „Dom do Góry Nogami. Przejazd do Przejazd do CHMIELNA zwiedzanie Pracowni Ceramiki Kaszubskiej rodziny Neclów. Powrót do hotelu obiadokolacja i nocleg.",
      "4 dzień - śniadanie. Czas wolny na wypoczynek.  Obiadokolacja. Nocleg.",
      "5 dzień - śniadanie. Przejazd do GDAŃSKA. spotkanie z przewodnikiem w GDAŃSKU. Następnie spacer uliczkami Starego Miasta. Ratusz Główny, renesansowe kamienice, fontanna Neptuna, Dwór Artusa - siedziba zamożnych kupców gdańskich. Długi Targ, słynny gdański Żuraw (największy średniowieczny dźwig portowy w Europie). Bazylika Mariacka. Przejazd do GDAŃSKA – OLIWY zwiedzanie Archikatedry, w której znajdują się słynne na całym świecie organy. Przejazd do hotelu, obiadokolacja i nocleg.",
      "6 dzień - śniadanie, wykwaterowanie. Przejazd do GRUDZIĄDZA położonego na prawym brzegu Wisły. Spacer po mieście m.in. obronne spichrze z XVI–XVIII w. Grudziądzka Cytadela, fragmenty murów miejskich oraz gotyckie i barokowe kościoły. Przyjazd do domu w godzinach wieczornych."
    ],
    images: [
      {
        url: "/images/trips/kaszuby_1.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK - Toruń - Bytów - Szymbark - Chmielno - Gdańsk - Grudziąd"
      },
      {
        url: "/images/trips/kaszuby_2.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK - Toruń - Bytów - Szymbark - Chmielno - Gdańsk - Grudziąd"
      },
      {
        url: "/images/trips/kaszuby_3.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK - Toruń - Bytów - Szymbark - Chmielno - Gdańsk - Grudziąd"
      },
      {
        url: "/images/trips/kaszuby_4.jpg",
        alt: "ZWIEDZANIE i WYPOCZYNEK - Toruń - Bytów - Szymbark - Chmielno - Gdańsk - Grudziąd"
      }
    ],
    terms: [
      {
        startDate: "22.06",
        endDate: "27.06.2025",
        price: "1695"
      }
    ],
    services:[ 
      "przejazd autokarem",
      "5 noclegów",
      "wyżywienie: 5 śniadań i 5 obiadokolacji",
      "ognisko z pieczeniem kiełbasek (w ramach 1 obiadokolacji)",
      "2 godz. dostęp do strefy saun",
      "dla chętnych możliwość skorzystania z zabiegów rehabilitacyjnych oraz strefy SPA (PŁATNE DODATKOWO – 10% zniżki dla uczestników wycieczki)",
      "opieka pilota",
      "ubezpieczenie NNW, TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 1695,
    lowerPrice: 1695,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty lokalnych przewodników oraz opłaty klimatycznej. "
  },
  {
    title: "WARSZAWA",
    description: [
      "Muzeum Powstania Warszawskiego - Zamek Królewski - Rynek Starego Miasta - Ogród Saski - Grób Nieznanego Żołnierza - Trakt Królewski - Krakowskie Przedmieście - Zespół Pałacowo Parkowy - Łazienki Warszawskie - Pałac Kultury i Nauki - Wilanów",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach rannych, przejazd do WARSZAWY - zwiedzanie Muzeum Powstania Warszawskiego, przejazd do Pałacu Kultury i Nauki - wjazd na XXX piętro, podziwianie panoramy miasta z tarasu widokowego. Następnie spacer po Łazienkach Królewskich, jednym z najpiękniejszych zespołów pałacowo - parkowych w Europie. Przejazd na obiadokolację i nocleg.",
      "2 dzień - śniadanie, spotkanie z przewodnikiem. Zwiedzanie m.in.: Plac Zamkowy, Kolumna Zygmunta, Zamek Królewski (z zewnątrz), Bazylika Archikatedralna, Rynek Starego Miasta, Pomnik Syrenki, Barbakan i Mury miejskie, Pomnik Małego Powstańca, Krakowskie Przedmieście (m.in. pomnik A. Mickiewicza, Pałac Prezydencki z zewnątrz, pomnik Mikołaja Kopernika, Uniwersytet Warszawski, kościół św. Krzyża), Plac Marszałka J. Piłsudskiego, Grób Nieznanego Żołnierza, Ogród Saski. Przejazd na obiadokolację i nocleg.",
      "3 dzień - śniadanie, zwiedzanie kompleksu pałacowo - parkowego w WILANOWIE, należącego niegdyś do Jana III Sobieskiego. Następnie Świątynia Opatrzności Bożej której historia budowy liczy ponad 200 lat i wiąże się z Konstytucją 3 maja z 1791 roku, kiedy uchwałą posłów Sejmu Czteroletniego podjęta została decyzja o wybudowaniu świątyni jako wotum wdzięczności za Konstytucję. Przyjazd do domu w godzinach wieczornych."
    ],
    images: [
      {
        url: "/images/trips/warszawa_1.jpg",
        alt: "Warszawa"
      },
      {
        url: "/images/trips/warszawa_2.jpg",
        alt: "Warszawa"
      },
      {
        url: "/images/trips/warszawa_3.jpg",
        alt: "Warszawa"
      },
      {
        url: "/images/trips/warszawa_4.jpg",
        alt: "Warszawa"
      }
    ],
    terms: [
      {
        startDate: "15.04",
        endDate: "17.04.2025",
        price: "895"
      },
      {
        startDate: "28.05",
        endDate: "30.05.2025",
        price: "895"
      },
      {
        startDate: "03.09",
        endDate: "05.09.2025",
        price: "895"
      }
    ],
    services:[ 
     " przejazd autokarem",
      "2 noclegi",
      "wyżywienie: 2 śniadania,2 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie NNW, TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 895,
    lowerPrice: 895,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty lokalnych przewodników oraz opłaty klimatycznej. "
  },
  {
    title: "PRAGA",
    description: [
      "Hradczany - Zamek Praski - Ogrody Królewskie - Katedra Św. Wita - Stare Miasto (ratusz z XV-wiecznym zegarem „ORLOJ”) - Rynek Staromiejski - plac Wacława - dzielnica Żydowska - REJS PO WEŁTAWIE - Křižíkowa Fontanna",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach rannych, przejazd do Pragi. Zwiedzanie miasta: HRADCZANY: Zamek Praski (z zewnątrz), Katedra św. Wita, ogrody królewskie, panorama Pragi z tarasu widokowego. Przejście przez Małą Stranę (kościół św. Mikołaja) do słynnego Mostu Karola.  Przejazd do hotelu, zakwaterowanie, obiadokolacja, nocleg.",
      "2 dzień - śniadanie, dalsze zwiedzanie stolicy Czech. Nowe Miasto: plac Wacława, kościół Matki Bożej Śnieżnej. Spacer Drogą Królewską m.in.: Brama Prochovska, Miejski Dom, ulica Celetna - jedna z reprezentacyjnych spacerowych ulic miasta. Kontynuacja spaceru w kierunku rynku, po drodze m.in.:",
      "Uniwersytet Karola, Teatr Stanowy. Przejście na rynek Starego Miasta: ratusz z XV-w zegarem „Orloj”,  kościół Marii Panny, pomnik Jana Husa. Czas wolny. Przejazd do hotelu, obiadokolacja. Wieczorem (w miarę dokonania rezerwacji biletów)  KŘIŽÍKOVA FONTANNA - kompleks fontann podświetlonych wszystkimi kolorami tęczy opadających i wznoszących się w rytmie muzyki poważnej, rockowej i pop. Powrót do hotelu, nocleg.",
      "3 dzień - śniadanie. Spacer przez Dzielnicę Żydowską (Josefov) - m.in. Cmentarz Żydowski, Stara Synagoga. Dzielnica słynie również z eleganckich sklepów odzieżowych przy ulicy Pařížskiej. Następnie Rejs po Wełtawie - który pozwala zobaczyć zabytki Pragi wzdłuż brzegów rzeki. Przyjazd do domu w godzinach wieczornych."
    ],
    images: [
      {
        url: "/images/trips/praga_1.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/praga_2.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/praga_3.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/praga_4.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      }
    ],
    terms: [
      {
        startDate: "na zapytanie",
        endDate: "",
        price: "1050"
      }
    ],
    services:[ 
      "przejazd autokarem",
      "2 noclegi",
      "wyżywienie: 2 śniadania i 2 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie NNW, TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 1050,
    lowerPrice: 1050,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu, opłaty klimatycznej oraz opłaty lokalnych przewodników"
  },
  {
    title: "TAJEMNICZY DOLNY ŚLĄSK",
    description: [
      "Zamek Książ - Karpacz - Zamek Czocha - Piechowice/Huta Julia - Świaradów-Zdrój - Osówka",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach porannych. Przejazd do KSIĄŻA. Zwiedzanie zamku, trzeciego co do wielkości, po Malborku i Wawelu zamku w Polsce. Usytuowany na wysokim skalistym urwisku, z trzech stron otoczony jarem, góruje majestatycznie nad całą okolicą, sprawiając oszałamiające wrażenie na zwiedzających. Przejazd do KARPACZA, pięknej miejscowości turystyczno-wypoczynkowej położonej u podnóża Śnieżki ze słynną drewnianą, średniowieczną Świątynią Wang. Przejazd do hotelu w SZKLARSKIEJ PORĘBIE/Jakuszycach, obiadokolacja i nocleg*",
      `2 dzień - śniadanie, przejazd pod Wodospad Szklarski - najpopularniejszy wodospad polskich Karkonoszy. Następnie przejazd do SUCHEJ - zwiedzanie ZAMKU CZOCHA, który jest jednym z najpiękniejszych zabytków Dolnego Śląska. Przejazd do PIECHOWIC - zwiedzanie Huty Julia, która jest najstarszym w Karkonoszach producentem, ciągle wytwarzającym wyroby ze szkła kryształowego. Pokaz tradycyjnego, ręcznego zdobienia szkła kryształowego (możliwość zakupu). Powrót do hotelu, obiadokolacja, nocleg.`,
      "3 dzień - śniadanie, przejazd do  ŚWIERADOWA-ZDRÓJ min. Dom Zdrojowy, Hala spacerowa w Domu Zdrojowym, Pijalnia wody mineralnej. Dla chętnych niezwykły „spacer w chmurach”. Wieża widokowa Sky Walk (to wyjątkowa atrakcja wznosząca się nad miastem Świeradów-Zdrój o wysokości 62 metrów, skąd roztacza się widok na zabudowę uzdrowiska oraz piękno przyrody). Następnie przejazd w okolice GŁUSZYCY do podziemnego kompleksu OSÓWKA, który w czasach II wojny światowej miał ponoć stanowić tajną kwaterę Hitlera lub mieścić ukrytą fabrykę broni - zwiedzanie (zalecane ciepłe ubranie i nieprzemakalne buty). Powrót do domu w godzinach wieczornych.  "
    ],
    images: [
      {
        url: "/images/trips/dolny_slask_1.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/dolny_slask_2.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/dolny_slask_3.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/dolny_slask_4.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/dolny_slask_5.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/dolny_slask_6.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/dolny_slask_7.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      }
    ],
    terms: [],
    services:[ 
      "przejazd autokarem",
      "2 noclegi",
      "wyżywienie: 2 śniadania i 2 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie NNW, TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty lokalnych przewodników oraz opłaty klimatycznej. Program zwiedzania jest ramowy – może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem."
  },
  {
    title: "KRAKÓW i WIELICZKA",
    description: [
      "Wieliczka - Tyniec - Kraków - Łagiewniki",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach rannych. Przejazd do WIELICZKI - zwiedzanie najstarszej na świecie zabytkowej kopalni soli (temperatura w kopalni jest stała i wynosi 14°C). Przejazd do TYŃCA, gdzie znajduje się niemal 1000-letni Zespół Klasztorny Ojców Benedyktynów, jeden z najbogatszych i najważniejszych w Polsce. Przejazd do hotelu, obiadokolacja i nocleg.",
      "2 dzień – śniadanie, zwiedzanie KRAKOWA: Zamek Królewski na Wawelu, skarbnica wiedzy o narodowych dziejach – komnaty królewskie, katedra wawelska. Spotkanie z przewodnikiem lokalnym i spacer do Rynku Głównego ulicą Grodzką: Sukiennice, kościół Mariacki, pomnik Adama Mickiewicza, kościół św. Wojciecha. Przejście pod Bramę Floriańską i pozostałości murów miejskich, czas wolny. Przejazd do hotelu, obiadokolacja, nocleg.",
      "3 dzień – po śniadaniu zwiedzanie dawnej dzielnicy żydowskiej Kazimierz: dawny rynek i ratusz, Stara Synagoga, cmentarz żydowski; kościół Ojców Paulinów na Skałce. Następnie przejazd do Sanktuarium Bożego Miłosierdzia w ŁAGIEWNIKACH, gdzie znajduje się słynący z łask obraz Jezusa Miłosiernego oraz grób św. Faustyny, zwiedzanie, czas wolny. Powrót do domu w godzinach wieczornych. "
    ],
    images: [
      {
        url: "/images/trips/krakow_1.jpg",
        alt: "Zdjęcia Kraków"
      },
      {
        url: "/images/trips/krakow_2.jpg",
        alt: "Zdjęcia Kraków"
      },
      {
        url: "/images/trips/krakow_3.jpg",
        alt: "Zdjęcia Kraków"
      },
      {
        url: "/images/trips/krakow_4.jpg",
        alt: "Zdjęcia Kraków"
      },
      {
        url: "/images/trips/krakow_5.jpg",
        alt: "Zdjęcia Kraków"
      },
      {
        url: "/images/trips/krakow_6.jpg",
        alt: "Zdjęcia Kraków"
      },
      {
        url: "/images/trips/krakow_7.jpg",
        alt: "Zdjęcia Kraków"
      }
    ],
    terms: [],
    services:[ 
      "przejazd autokarem",
      "2 noclegi",
      "wyżywienie: 2 śniadania, 2 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie NNW, TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty lokalnych przewodników oraz opłaty klimatycznej. Program zwiedzania jest ramowy – może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem."
  },
  {
    title: "GÓRY ŚWIĘTOKRZYSKIE",
    description: [
      "Jędrzejów - Tokarnia - Chęciny - Jaskinia Raj - Kielce - Oblęgorek - Święty Krzyż",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach rannych, przejazd do JĘDRZEJOWA - zwiedzanie pierwszego na ziemiach polskich Klasztoru Cystersów oraz Muzeum Przypkowskich, gdzie znajduje się m.in. ekspozycja zegarów słonecznych. Przejazd do Muzeum Wsi Kieleckiej w TOKARNI, gdzie przechowywane są najcenniejsze zabytki budownictwa wiejskiego i małomiasteczkowego, a następnie do CHĘCIN - zwiedzanie ruin gotyckiego zamku, położonego u stóp malowniczego wzniesienia. Przejazd do hotelu na obiadokolację i nocleg.",
      "2 dzień - śniadanie, zwiedzanie jednej z najpiękniejszych jaskiń krasowych naszego kraju - JASKINI RAJ, zachwycającej bogactwem i różnorodnością kalcytowych form naciekowych. Przejazd do KIELC - zwiedzanie miasta: m.in. katedra, Pałac Biskupów Krakowskich (obecnie siedziba Muzeum Narodowego). Przejazd do OBLĘGORKA - Muzeum Henryka Sienkiewicza; najstarszy w Polsce dąb „Bartek” (Zagnańsk). Przejazd na obiadokolację i nocleg.",
      "3 dzień - śniadanie, dalsze zwiedzanie: najstarsze sanktuarium na ziemiach polskich - ŚWIĘTY KRZYŻ - symbol Gór Świętokrzyskich, miejsce kultu i wielu pielgrzymek (dawne opactwo benedyktyńskie, Sanktuarium Relikwii Krzyża Świętego). Powrót w godzinach wieczornych. "
    ],
    images: [
      {
        url: "/images/trips/gory_swietokrzyskie_0.jpg",
        alt: "Zdjęcia GÓRY ŚWIĘTOKRZYSKIE"
      },
      {
        url: "/images/trips/gory_swietokrzyskie_1.jpg",
        alt: "Zdjęcia GÓRY ŚWIĘTOKRZYSKIE"
      },
      {
        url: "/images/trips/gory_swietokrzyskie_2.jpg",
        alt: "Zdjęcia GÓRY ŚWIĘTOKRZYSKIE"
      },
      {
        url: "/images/trips/gory_swietokrzyskie_3.jpg",
        alt: "Zdjęcia GÓRY ŚWIĘTOKRZYSKIE"
      },
      {
        url: "/images/trips/gory_swietokrzyskie_4.jpg",
        alt: "Zdjęcia GÓRY ŚWIĘTOKRZYSKIE"
      },
      {
        url: "/images/trips/gory_swietokrzyskie_5.jpg",
        alt: "Zdjęcia GÓRY ŚWIĘTOKRZYSKIE"
      }
    ],
    terms: [],
    services:[ 
      "przejazd autokarem",
      "2 noclegi",
      "wyżywienie: 2 śniadania, 2 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie NNW, TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty lokalnych przewodników oraz opłaty klimatycznej. Program zwiedzania jest ramowy – może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem."
  },
  {
    title: "BERLIN i POCZDAM",
    description: [
      "Wrocław - Berlin - Poczdam",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach rannych, przejazd do WROCŁAWIA, zwiedzanie miasta. W programie m.in. rynek, ratusz, kamienica Pod Złotym Słońcem, plac Solny, Fontanna z Szermierzem, Uniwersytet Wrocławski. Przejazd do Słubic, zakwaterowanie w hotelu, obiadokolacja, nocleg",
      "2 dzień - śniadanie, przejazd do BERLINA, całodzienne zwiedzanie miasta m.in.: Brama Brandenburska - symbol Berlina, Reichstag, spacer aleją Unter den Linden na Wyspę Muzeów (zwiedzanie Muzeum Pergamonu, jednego z najwspanialszych zbiorów historii starożytnej), Berliner Dom (największa świątynia w Berlinie). Przejazd do letniej rezydencji Fryderyka Wilhelma II Charlottenburg (z zewnątrz), spacer po ogrodach pałacu. Następnie zwiedzanie ruin Gedächtniskirche, Europa-Center, spacer po słynnym bulwarze handlowym Kurfürstendamm. Przejazd do hotelu, obiadokolacja i nocleg. *istnieje możliwość zorganizowania rejsu statkiem po Sprewie.",
      "3 dzień - śniadanie, przejazd do POCZDAMU, miasta wpisanego na Listę Światowego Dziedzictwa Kultury UNESCO. Zwiedzanie jednego z pałaców na terenie rokokowego kompleksu pałacowo-ogrodowego (wspaniała rezydencja Fryderyka Wielkiego), spacer po parku. Powrót do kraju w późnych godzinach wieczornych."
    ],
    images: [
      {
        url: "/images/trips/berlin_poczdam_1.jpg",
        alt: "Zdjęcia BERLIN i POCZDAM"
      },
      {
        url: "/images/trips/berlin_poczdam_2.jpg",
        alt: "Zdjęcia BERLIN i POCZDAM"
      },
      {
        url: "/images/trips/berlin_poczdam_3.jpg",
        alt: "Zdjęcia BERLIN i POCZDAM"
      },
      {
        url: "/images/trips/berlin_poczdam_4.jpg",
        alt: "Zdjęcia BERLIN i POCZDAM"
      },
      {
        url: "/images/trips/berlin_poczdam_5.jpg",
        alt: "Zdjęcia BERLIN i POCZDAM"
      },
      {
        url: "/images/trips/berlin_poczdam_6.jpg",
        alt: "Zdjęcia BERLIN i POCZDAM"
      },
      {
        url: "/images/trips/berlin_poczdam_7.jpg",
        alt: "Zdjęcia BERLIN i POCZDAM"
      }
    ],
    terms: [],
    services:[ 
      "przejazd autokarem",
      "2 noclegi",
      "wyżywienie: 2 śniadania, 2 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie KL i NNW (ochrona rozszerzona o choroby przewlekłe i nowotworowe), TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty lokalnych przewodników oraz opłaty klimatycznej. Program zwiedzania jest ramowy - może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem."
  },
  {
    title: "TRZY STOLICE",
    description: [
      "Morawski Kras - Brno - Wiedeń - Bratysława - Budapeszt - Szentendre",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd z kraju w godz. porannych. Przejazd na MORAWSKI KRAS - zwiedzanie słynnej JASKINI PUNKEVNI; przepaść Macocha. Przejazd do stolicy Moraw - BRNA. Zwiedzanie miasta m.in. wzgórze Petrov z katedrą św. Piotra i Pawła, Zeleninovy Trh, Stary Ratusz z portalem Antona Pilgrama, smokiem i „kołem kołodzieja”, podziemne krypty kościoła Kapucynów, czas wolny w starej części Brna. Przejazd do hotelu w okolicach Znojma, obiadokolacja i nocleg.",
      "2 dzień - śniadanie, przejazd do WIEDNIA: pałac Schönbrunn (letnia rezydencja Habsburgów) - zwiedzanie apartamentów cesarskich, czas wolny na spacer po ogrodach. Przejazd do centrum m.in.: Parlament, Ratusz Miejski, Burgtheater, Ogród Różany, Helden Platz, Hofburg, kościół Augustianów, Staatsoper, czas wolny na kawę po wiedeńsku. Dalsze zwiedzanie: Stephans Dom, Kolumna Dżumy, plac św. Michała. Wjazd na Kahlenberg, wizyta w kaplicy Jana III Sobieskiego, kościół św. Józefa, panorama miasta z tarasu widokowego. Przejazd do hotelu w okolicach Bratysławy, obiadokolacja i nocleg.",
      "3 dzień - śniadanie, zwiedzanie BRATYSŁAWY. Spacer po zabytkowej części stolicy Słowacji. Przejazd do BUDAPESZTU, zwiedzanie: Wyspa Małgorzaty (objazd wyspy kolejką turystyczną), Parlament, bazylika św. Stefana, plac Bohaterów, zamek Vajdahunyadi. Przejazd do SZENTENDRE - spacer po zabytkowej części miasteczka; biesiada węgierska w jednej z czard (kolacja z winem i muzyką). Powrót do Budapesztu. Wyjazd na Górę Gellerta - wieczorna panorama miasta, przejazd do hotelu, nocleg.",
      "4 dzień - śniadanie, dalsze zwiedzanie BUDAPESZTU: przejazd na Wzgórze Zamkowe - zwiedzanie: Zamek Budański, pomnik Eugeniusza Sabaudzkiego, fontanna króla Macieja, pomnik Andrassa Hadika, kościół koronacyjny, baszty rybackie. Czas wolny na kawę/lody w słynnej kawiarni przy hotelu Hilton, godzinny rejs po Dunaju. Wyjazd z Budapesztu, przejazd przez Słowację, powrót do kraju w godz. wieczornych."
    ],
    images: [
      {
        url: "/images/trips/trzy_stolice_1.jpg",
        alt: "Zdjęcia TRZY STOLICE"
      },
      {
        url: "/images/trips/trzy_stolice_2.jpg",
        alt: "Zdjęcia TRZY STOLICE"
      },
      {
        url: "/images/trips/trzy_stolice_3.jpg",
        alt: "Zdjęcia TRZY STOLICE"
      },
      {
        url: "/images/trips/trzy_stolice_4.jpg",
        alt: "Zdjęcia TRZY STOLICE"
      },
      {
        url: "/images/trips/trzy_stolice_5.jpg",
        alt: "Zdjęcia TRZY STOLICE"
      },
      {
        url: "/images/trips/trzy_stolice_6.jpg",
        alt: "Zdjęcia TRZY STOLICE"
      },
      {
        url: "/images/trips/trzy_stolice_7.jpg",
        alt: "Zdjęcia TRZY STOLICE"
      }
    ],
    terms: [],
    services:[ 
      "3 noclegi",
      "wyżywienie: 3 śniadania, 3 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie KL i NNW (ochrona rozszerzona o choroby przewlekłe i nowotworowe), TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty lokalnych przewodników oraz opłaty klimatycznej. Dopłata do wieczoru węgierskiego ok. 20€/os (w zależności od restauracji). Program zwiedzania jest ramowy - może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem."
  },
  {
    title: "KRAJE BENELUXU",
    description: [
      "Amsterdam - Antwerpia - Gandawa - Brugia - Bruksela - Luksemburg",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach rannych, przejazd przez Niemcy do Holandii. Obiadokolacja i nocleg w okolicy Amsterdamu.",
      "2 dzień - śniadanie, przejazd do AMSTERDAMU, największego miasta Holandii i stolicy konstytucyjnej Niderlandów. Rejs statkiem po kanałach, spacer po zabytkowej starówce: targ kwiatowy, stara mennica. Wizyta na republikańskim placu Dam z Kościołem Nowym i Pałacem Królewskim; dzielnica czerwonych latarni (tzw. Małe Mury). Przejazd w okolicę Antwerpii, obiadokolacja i nocleg.",
      "3 dzień - śniadanie, zwiedzanie ANTWERPII, drugiej co do wielkości belgijskiej metropolii. Spacer po mieście: Grote Markt z renesansowym ratuszem, XVI-wiecznymi kamieniczkami i fontanną Brabo; katedra Najświętszej Marii Panny, jedna z najwyższych budowli sakralnych na świecie, w której można podziwiać m.in. obrazy Rubensa. Następnie wizyta w GANDAWIE, siedzibie hrabiów Flandrii. Spacer po starówce i zabytkowych nabrzeżach portowych. Przejazd do BRUGII (malutkiej Wenecji Północy) – doskonale zachowanego średniowiecznego miasta z krętymi uliczkami, biegnącymi wzdłuż malowniczych kanałów. Zwiedzanie m.in.: kościół Najświętszej Marii Panny, rynek, wieża Belfort z XIII-wiecznymi sukiennicami. Obiadokolacja, powrót do hotelu na nocleg.",
      "4 dzień - śniadanie. Przejazd do BRUKSELI, stolicy Belgii, miasta piwa i czekolady. Zwiedzanie (9 godzin): słynny stadion piłkarski w dzielnicy Heysel (z zewnątrz), możliwość zwiedzania parku Mini Europa (zbiór modeli znanych europejskich budowli pomniejszonych w stosunku do oryginału 25 razy) lub Atomium (powiększony model cząsteczki żelaza). Przejazd metrem do centrum. Wizyta w Górnym Mieście: katedra, Pałac Królewski. Spacer po jednym z najpiękniejszych rynków Europy - Grand Place, gotycki ratusz z XV w., Dom Królewski, domy cechowe; Manneken Pis (symbol beztroskiego ducha Brukseli). Przejazd metrem do dzielnicy europejskiej: Parlament Europejski. Następnie przejazd do LUKSEMBURGA, jednej z najbardziej malowniczych stolic Europy z licznymi średniowiecznymi zabytkami. Wieczorny spacer po mieście: plac Konstytucji, gotycka katedra Notre Dame, XVI-wieczny Pałac Książęcy, fragmenty cytadeli. Wyjazd do Polski.",
      "5 dzień - powrót do kraju w godz. popołudniowych."
    ],
    images: [
      {
        url: "/images/trips/kraje_beneluxu_0.jpg",
        alt: "Zdjęcia KRAJE BENELUXU"
      },
      {
        url: "/images/trips/kraje_beneluxu_1.jpg",
        alt: "Zdjęcia KRAJE BENELUXU"
      },
      {
        url: "/images/trips/kraje_beneluxu_2.jpg",
        alt: "Zdjęcia KRAJE BENELUXU"
      },
      {
        url: "/images/trips/kraje_beneluxu_3.jpg",
        alt: "Zdjęcia KRAJE BENELUXU"
      },
      {
        url: "/images/trips/kraje_beneluxu_4.jpg",
        alt: "Zdjęcia KRAJE BENELUXU"
      },
            {
        url: "/images/trips/kraje_beneluxu_5.jpg",
        alt: "Zdjęcia KRAJE BENELUXU"
      }
    ],
    terms: [],
    services:[ 
     " przejazd autokarem",
      "3 noclegi",
      "wyżywienie: 3 śniadania, 3 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie KL i NNW (ochrona rozszerzona o choroby przewlekłe i nowotworowe), TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty za metro, opłaty lokalnych przewodników oraz opłaty klimatycznej. Program zwiedzania jest ramowy - może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem. *2 WARIANT - bez noclegu w ok. Amsterdamu - wyjazd w godz. popołudniowych i całonocny przejazd do Amsterdamu"
  },
  {
    title: "PARYŻ i ZAMKI NAD LOARĄ",
    description: [
      "Wersal - Paryż - Dolina Loary (Chambord, Amboise, Blois)",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd we wczesnych godz. rannych. Przejazd przez Niemcy, dojazd do hotelu we Francji w okolicy Saarbrucken, zakwaterowanie i nocleg (w tym dniu nie ma kolacji).",
      "2 dzień - śniadanie. Przejazd do WERSALU. Zwiedzanie pełnego przepychu pałacu, byłej rezydencji Króla Słońce Ludwika XIV. Spacer po królewskich ogrodach, w których na szczególną uwagę zasługują wielkie fontanny i labirynty z żywopłotów. Przejazd na obiadokolację, a następnie do hotelu w regionie paryskim, zakwaterowanie i nocleg.",
      "3 dzień - śniadanie, zwiedzanie PARYŻA: plac Bastylii, Nowa Opera Paryska, Centrum Pompidou (symbol nowoczesności z zewnętrznymi rurami, rusztowaniami i windami), stare Hale Paryskie, plac Vogezów, bazylika Sacré-Coeur, dzielnica artystów Montmartre, Place du Tertre - jeden z najbardziej malowniczych zakątków Paryża, historyczne miejsce powstania impresjonizmu. Obiadokolacja, wieczorny rejs statkiem po Sekwanie. Powrót do hotelu, nocleg.",
      "4 dzień - śniadanie, dalsze zwiedzanie stolicy Francji: dzielnica La Cité - najstarsza część i kolebka Paryża z katedrą Notre-Dame i Sainte Chapelle, Conciergerie - dawne więzienie, Pałac Sprawiedliwości. Spacer najstarszym mostem na Sekwanie - Pont Neuf, Dzielnica Łacińska: fontanna Saint Michel, Sorbona, Panteon (gdzie spoczywają zasłużeni obywatele Francji, wśród nich Maria Skłodowska-Curie). Chwila odpoczynku w Ogrodzie Luksemburskim. Obiadokolacja, powrót do hotelu, nocleg.",
      "5 dzień - śniadanie, przejazd w stronę DOLINY LOARY - malowniczego regionu z największymi zamkami Francji. W programie: CHAMBORD - zwiedzanie największej i najsłynniejszej rezydencji w dolinie Loary, wspaniałego dzieła architektury renesansu. Ogromna rezydencja ma ponad 400 pomieszczeń, otacza ją rozległy park i lasy. Spacer po ogrodach. Następnie przejazd do AMBOISE, słynnego z pięknego, renesansowego kompleksu zamkowego, gdzie swoje ostatnie 3 lata mieszkał i pracował Leonardo da Vinci oraz do BLOIS z zamkiem, z którym łączy się wiele wydarzeń z historii Francji. Powrót do Paryża, obiadokolacja, nocleg.",
      "6 dzień - śniadanie, zwiedzanie Paryża (przejazdy metrem) - w programie: Łuk Triumfalny - symbol Francji, spacer po Polach Elizejskich, cieszących się sławą najpiękniejszej ulicy świata; imponujący Plac de la Concorde. Spacer przez Ogrody Tuileries. Wizyta w Luwrze - największym w świecie muzeum dzieł malarstwa, rzeźby i rzemiosła artystycznego. Dalsze zwiedzanie: Szkoła Wojskowa, Hôtel des Invalides z grobem Napoleona, Pola Marsowe, wjazd na Wieżę Eiffla. Obiadokolacja, wyjazd do kraju w godz. wieczornych.",
      "7 dzień - powrót do Polski w godzinach popołudniowych."
    ],
    images: [
      {
        url: "/images/trips/paryz_1.jpg",
        alt: "Zdjęcia PARYŻ i ZAMKI NAD LOARĄ"
      },
      {
        url: "/images/trips/paryz_2.jpg",
        alt: "Zdjęcia PARYŻ i ZAMKI NAD LOARĄ"
      },
      {
        url: "/images/trips/paryz_3.jpg",
        alt: "Zdjęcia PARYŻ i ZAMKI NAD LOARĄ"
      },
      {
        url: "/images/trips/paryz_4.jpg",
        alt: "Zdjęcia PARYŻ i ZAMKI NAD LOARĄ"
      }
    ],
    terms: [],
    services:[ 
      "przejazd autokarem",
      "1 nocleg w ok. Saarbrucken, 4 noclegi w rejonie paryskim",
      "wyżywienie: 5 śniadań, 5 obiadokolacji",
      "opieka pilota",
      "ubezpieczenie KL i NNW (ochrona rozszerzona o choroby przewlekłe i nowotworowe), TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty za metro, opłaty lokalnych przewodników oraz opłaty klimatycznej. Program zwiedzania jest ramowy - może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem."
  },
  {
    title: "WZDŁUŻ PÓŁWYSPU APENIŃSKIEGO",
    description: [
      "Florencja - Watykan - Rzym - Monte Casino - Sorrento - Capri - San Giovanni Rotondo - Lanciano - Asyż - Wenecja",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godz. rannych, przejazd przez Czechy i Austrię do Włoch. Zakwaterowanie w hotelu, obiadokolacja, nocleg.",
      "2 dzień - śniadanie, przejazd do FLORENCJI, stolicy Toskanii. Zwiedzanie: kościół Santa Croce z grobowcami słynnych artystów takich, jak Michał Anioł czy Galileusz, plac Signoria, baptysterium, do którego prowadzą słynne Rajskie Wrota, katedra Santa Maria del Fiore, plac Michała Anioła ze wspaniałym widokiem na miasto. Przejazd do hotelu, obiadokolacja, nocleg.",
      "3 dzień - śniadanie, zwiedzanie WATYKANU i RZYMU m.in. Audiencja Generalna (podczas pobytu Ojca Świętego w Rzymie), bazylika i plac św. Piotra, grób Jana Pawła II. Zwiedzanie Rzymu Antycznego: Koloseum (amfiteatr wzniesiony za dynastii Flawiuszów), Forum Romanum, Kapitol (miejsce od czasów antycznych uważane za serce duchowe, polityczne i kulturalne Rzymu), plac Wenecki. Przyjazd do hotelu w okolicy Rzymu, obiadokolacja i nocleg.",
      "4 dzień - śniadanie, dalsze zwiedzanie RZYMU. Place i fontanny: Piazza Navona z Fontanną Czterech Rzek, Piazza Rotonda z Panteonem (antyczną świątynią wszystkich bogów), plac Hiszpański ze słynnymi schodami Hiszpańskimi, Fontanna di Trevi. Przejazd na MONTE CASSINO. Wizyta na polskim cmentarzu wojskowym, położonym u stóp założonego  w VI w. opactwa Benedyktynów. Przejazd do hotelu w ok. Neapolu, zakwaterowanie, obiadokolacja i nocleg.",
      "5 dzień - śniadanie, przejazd do nadmorskiego kurortu SORRENTO, spacer po mieście. Podroż stateczkiem na CAPRI, słynną wyspę wypoczynkową rzymskich cesarzy, znaną z pięknych widoków i śródziemnomorskiej roślinności. Przejazd do hotelu na obiadokolację i nocleg do San Giovanni Rotondo.",
      "6 dzień - śniadanie. Zwiedzanie SAN GIOVANNI ROTONDO m.in. bazylika Matki Bożej Łaskawej i klasztor kapucyński, w którym większość swojego życia spędził O. Pio. Przejazd do LANCIANO, gdzie w VIII stuleciu zdarzył się cud eucharystyczny. Zwiedzanie kościoła Franciszkanów, gdzie obecnie przechowywane jest Najświętsze Ciało i Krew Chrystusa. Przejazd do hotelu w ok. Asyżu, obiadokolacja i nocleg.",
      "7 dzień - śniadanie. Zwiedzanie ASYŻU, rodzinnego miasta św. Franciszka, słynącego z przepięknej, średniowiecznej zabudowy: bazylika św. Franciszka, Piazza Comune ze świątynią Minerwy, bazylika św. Klary; Porcjunkula. Obiadokolacja i nocleg w ok. Rimini.",
      "8 dzień - śniadanie, przyjazd do WENECJI. Rejs statkiem kanałami weneckimi do placu św. Marka. Zwiedzanie miasta m.in. bazylika św. Marka, Pałac Dożów, Most Westchnień. Spacer trasą turystyczną przez Ponte Rialto. Wyjazd w kierunku Polski.",
      "9 dzień - powrót do kraju w godzinach popołudniowych."
    ],
    images: [
      {
        url: "/images/trips/wzdluz_polwyspu_1.jpg",
        alt: "Zdjęcia WZDŁUŻ PÓŁWYSPU APENIŃSKIEGO"
      },
      {
        url: "/images/trips/wzdluz_polwyspu_2.jpg",
        alt: "Zdjęcia WZDŁUŻ PÓŁWYSPU APENIŃSKIEGO"
      },
      {
        url: "/images/trips/wzdluz_polwyspu_3.jpg",
        alt: "Zdjęcia WZDŁUŻ PÓŁWYSPU APENIŃSKIEGO"
      },
      {
        url: "/images/trips/wzdluz_polwyspu_4.jpg",
        alt: "Zdjęcia WZDŁUŻ PÓŁWYSPU APENIŃSKIEGO"
      },
      {
        url: "/images/trips/wzdluz_polwyspu_5.jpg",
        alt: "Zdjęcia WZDŁUŻ PÓŁWYSPU APENIŃSKIEGO"
      },
      {
        url: "/images/trips/wzdluz_polwyspu_6.jpg",
        alt: "Zdjęcia WZDŁUŻ PÓŁWYSPU APENIŃSKIEGO"
      }
    ],
    terms: [],
    services:[ 
      "przejazd autokarem",
      "7 noclegów",
      "wyżywienie: 7 śniadań i 7 obiadokolacji",
      "opieka pilota",
      "ubezpieczenie KL i NNW (ochrona rozszerzona o choroby przewlekłe i nowotworowe), TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty za metro, wjazdówek do miast, opłaty lokalnych przewodników oraz opłaty klimatycznej. Program zwiedzania jest ramowy - może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem."
  },
  {
    title: "BAŁKAŃSKIE SKARBY",
    description: [
      "Bled - Jaskinia Postojna - Medjugorje - Mostar - Dubrownik",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godzinach rannych (ok. godz. 4:00). Przejazd przez Czechy, Austrię do Słowenii. Przejazd nad jezioro Blejskie. Zwiedzanie ZAMKU BLED, malowniczo usytuowanego na skale, wznoszącej się wysoko ponad taflą wody (przepiękne widoki). Zakwaterowanie w hotelu, obiadokolacja, nocleg.",
      "2 dzień - śniadanie. Przejazd do JASKINI POSTOJNA, wpisanej na Listę Światowego Dziedzictwa UNESCO, której korytarze mają ponad 20 km. Atrakcyjną trasę turystyczną wewnątrz jaskini pokonuje się zarówno pieszo (radzimy zabrać ze sobą wygodne obuwie), jak i specjalnym podziemnym pociągiem. Przejazd do hotelu na terenie na Riwierze Makarskiej, obiadokolacja i nocleg.",
      "3 dzień - śniadanie. Czas wolny na wypoczynek na plaży, kąpiele morskie i słoneczne lub możliwość samo-dzielnego zwiedzania okolicy. Obiadokolacja. Nocleg.",
      "4 dzień - śniadanie, przejazd do MEDJUGORIE, które jest jednym z najliczniej odwiedzanych miejsc pielgrzymkowych w Europie. W 1981r. w miejscu zwanym Brdo Ukazanja, miały miejsce objawienia Matki Boskiej, które trwają do dziś. W programie: wejście na Górę Objawień (indywidualna modlitwa), wizyta w kościele parafialnym pod wezwaniem św. Jakuba, spacer po centrum miasteczka. Następnie przejazd do MOSTARU - miasto z czasów osmańskich, położone na rzece Neretwa. Posiada dużą liczbę meczetów oraz uroczy bazar turecki z przepięknym Starym Mostem, który jest wpisany na listę światowego dziedzictwa UNESCO. Spacer po starówce. Powrót do hotelu, obiadokolacja i nocleg.",
      "5 dzień - śniadanie. Czas wolny na wypoczynek na plaży, kąpiele morskie i słoneczne lub możliwość samodzielnego zwiedzania okolicy. Obiadokolacja. Nocleg.",
      "6 dzień - śniadanie, przejazd do DUBROWNIKA. Zwiedzanie „perły Adriatyku”, imponującego miasta otoczonego murami obronnymi, z których można podziwiać jego unikalną zabudowę; niezapomniane zabytki: Brama Pile, Wielka Fontanna Onufrego, kościół Zbawiciela, Pałac Sponza i liczne muzea. Powrót do hotelu, obiadokolacja i nocleg",
      "7 dzień - śniadanie, czas wolny na wypoczynek na plaży, kąpiele morskie i słoneczne lub możliwość samodzielnego zwiedzania okolicy. Obiadokolacja. Nocleg.",
      "8 dzień - śniadanie w formie pakietów, wykwaterowanie z hotelu. Przejazd do Polski. Powrót w godzinach wieczornych."
    ],
    images: [
      {
        url: "/images/trips/balkanskie_skarby_1.jpg",
        alt: "Zdjęcia BAŁKAŃSKIE SKARBY"
      },
      {
        url: "/images/trips/balkanskie_skarby_2.jpg",
        alt: "Zdjęcia BAŁKAŃSKIE SKARBY"
      },
      {
        url: "/images/trips/balkanskie_skarby_3.jpg",
        alt: "Zdjęcia BAŁKAŃSKIE SKARBY"
      },
      {
        url: "/images/trips/balkanskie_skarby_4.jpg",
        alt: "Zdjęcia BAŁKAŃSKIE SKARBY"
      },
      {
        url: "/images/trips/balkanskie_skarby_5.jpg",
        alt: "Zdjęcia BAŁKAŃSKIE SKARBY"
      },
      {
        url: "/images/trips/balkanskie_skarby_6.jpg",
        alt: "Zdjęcia BAŁKAŃSKIE SKARBY"
      }
    ],
    terms: [],
    services:[ 
      "przejazd autokarem,",
      "7 noclegów w hotelu (1 nocleg w Słowenii, 6 noclegów w Chorwacji),",
      "wyżywienie: 7 śniadań i 7 obiadokolacji,",
      "opieka pilota,",
      "ubezpieczenie KL i NNW (ochrona rozszerzona o choroby przewlekłe i nowotworowe), TFG, TFP"
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, opłaty za metro, wjazdówek do miast, opłaty lokalnych przewodników oraz opłaty klimatycznej. Program zwiedzania jest ramowy - może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem."
  },
  {
    title: "JEZIORA ALPEJSKIE",
    description: [
      "Gmunden - Salzburg - St. Gilgen - Mondsee - Hallstatt - Bad Ischl - St. Wolfgang",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godz. rannych, przejazd przez Czechy do GMUNDEN, miasteczka słynącego z ceramiki artystycznej. Spacer po mieście (ratusz, promenada, Zamek Ort). Rejs statkiem po jeziorze Traunsee. Przejazd do hotelu, zakwaterowanie, obiadokolacja, nocleg.",
      "2 dzień - śniadanie, przejazd do SALZBURGA, jednego z najpiękniejszych miast Austrii. Zwiedzanie Starego Miasta z przewodnikiem lok.: pałac Mirabell z kompleksem ogrodów, starówka, katedra, Getreidegasse - najstarsza i najpiękniejsza uliczka miasta (pod nr 9 znajduje się Dom Urodzenia Mozarta). Średniowieczna twierdza Hohensalzburg (wspaniała panorama miasta). Następnie przejazd do ST GILGEN, uroczego miasteczka, w którym urodziła się matka Mozarta. Możliwość wyjazdu kolejką linową na Zwolferhorn (1520 m n.p.m.), taras widokowy. Przejazd do MONDSEE, popularnej miejscowości wakacyjnej. Powrót do hotelu, obiadokolacja, nocleg.",
      "3 dzień - śniadanie, przejazd do HALLSTATT - spacer pięknymi uliczkami miasta, zwiedzanie Pfarrkirche z gotyckimi freskami. Następnie przejazd do najbardziej znanego uzdrowiska w regionie Salzkammergut - BAD ISCHL. Zwiedzanie Kaiservilli, letniej rezydencji cesarza Franciszka Józefa. Przejazd do ST WOLFGANG, słynnej miejscowości pielgrzymkowej. Zwiedzanie: kościół pielgrzymi z przepięknym ołtarzem Pachera, XVI-wieczna fontanna. Powrót do hotelu, obiadokolacja, nocleg.",
      "4 dzień - śniadanie, wyjazd do Polski, przyjazd do kraju w godzinach wieczornych."
    ],
    images: [
      {
        url: "/images/trips/jeziora_alpejskie_1.jpg",
        alt: "Zdjęcia JEZIORA ALPEJSKIE"
      },
      {
        url: "/images/trips/jeziora_alpejskie_2.jpg",
        alt: "Zdjęcia JEZIORA ALPEJSKIE"
      },
      {
        url: "/images/trips/jeziora_alpejskie_3.jpg",
        alt: "Zdjęcia JEZIORA ALPEJSKIE"
      },
      {
        url: "/images/trips/jeziora_alpejskie_4.jpg",
        alt: "Zdjęcia JEZIORA ALPEJSKIE"
      },
      {
        url: "/images/trips/jeziora_alpejskie_5.jpg",
        alt: "Zdjęcia JEZIORA ALPEJSKIE"
      },
      {
        url: "/images/trips/jeziora_alpejskie_6.jpg",
        alt: "Zdjęcia JEZIORA ALPEJSKIE"
      }
    ],
    terms: [],
    services:[ 
      "przejazd autokarem",
      "3 noclegi",
      "wyżywienie: 3 śniadania, 3 obiadokolacje",
      "opieka pilota",
      "ubezpieczenie KL i NNW (ochrona rozszerzona o choroby przewlekłe i nowotworowe), TFG, TFP."
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje napoi do obiadokolacji, biletów wstępu do zwiedzanych obiektów, rejsu statkiem, opłaty lokalnych przewodników oraz opłaty klimatycznej. Program zwiedzania jest ramowy - może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem. "
  },
  {
    title: "WŁOCHY PÓŁNOCNE i ISTRIA",
    description: [
      "Wenecja - Padwy - Werona - Maramare - Triest - Rovinj - Pula",
      "RAMOWY PROGRAM WYCIECZKI:",
      "1 dzień - wyjazd w godz. wieczornych, nocny przejazd przez Czechy i Austrię do Włoch.",
      "2 dzień - przyjazd w okolice WENECJI, zakwaterowanie, rejs statkiem do placu św. Marka. Zwiedzanie miasta m.in. Most Westchnień, bazylika św. Marka, Wieża Zegarowa, Pałac Dożów. Spacer trasą turystyczną do Ponte Rialto. Powrót do hotelu, obiadokolacja i nocleg.",
      "3 dzień - śniadanie, przejazd do PADWY, zwiedzanie m.in. bazylika św. Antoniego, uniwersytet, na którym studiowali m.in. Mikołaj Kopernik i Jan Kochanowski (z zewnątrz), piękny plac Prato della Valle z bazyliką św. Justyny i posągami sławnych mieszkańców miasta. Przejazd do WERONY, należącej obok Wenecji, do najbardziej interesujących miast regionu Veneto: rzymski amfiteatr Arena, Piazza dei Signori, Casa Capuletti ze słynnym balkonem szekspirowskiej Julii. Przejazd do hotelu, obiadokolacja i nocleg.",
      "4 dzień - śniadanie, przejazd do MIRAMARE - zwiedzanie parku w stylu angielskim oraz zamku Maksymiliana Habsburga. Przejazd do TRIESTU, jednego z ciekawszych północnowłoskich miast. Zwiedzanie XIX-wiecznego placu Zjednoczenia, weneckiego zamku i romańsko-gotyckiej katedry San Giusto. Przyjazd na obiadokolację i nocleg do Chorwacji.",
      "5 dzień - śniadanie, wycieczka do miejscowości ROVINJ - jednego z najbardziej malowniczych miejsc w Chorwacji z doskonale zachowanym starym miastem. Następnie REJS STATKIEM PO KANALE LIMSKIM (degustacja rakiji). Powrót do hotelu, czas na plażowanie, obiadokolacja, nocleg.",
      "6 dzień - śniadanie, przejazd do PULI - gospodarczego i turystycznego centrum Istrii. Zwiedzanie m.in. słynny amfiteatr z I stulecia, Łuk Triumfalny Sergiusza, Brama Herkulesa, Złoty Łuk, Świątynia Augusta. Powrót do hotelu, plażowanie, obiadokolacja. Nocleg.",
      "7 dzień - śniadanie, wyjazd do Polski. Powrót do kraju w godzinach wieczornych."
    ],
    images: [
      {
        url: "/images/trips/wlochy_polnocne_istria_2.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/wlochy_polnocne_istria_3.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/wlochy_polnocne_istria_4.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/wlochy_polnocne_istria_5.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/wlochy_polnocne_istria_6.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/wlochy_polnocne_istria_7.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/wlochy_polnocne_istria_8.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/wlochy_polnocne_istria_9.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      },
      {
        url: "/images/trips/wlochy_polnocne_istria_10.jpg",
        alt: "Zdjęcia Hotelu Paula Pobierowo"
      }
    ],
    terms: [],
    services:[ 
      "przejazd autokarem",
      "5 noclegów",
      "wyżywienie: 5 śniadań, 5 obiadokolacji",
      "opieka pilota",
      "ubezpieczenie KL i NNW (ochrona rozszerzona o choroby przewlekłe i nowotworowe), TFG, TFP "
    ],
    vacationType: TRIPS,
    price: 0,
    lowerPrice: 0,
    disclaimer: "UWAGA: cena nie obejmuje biletów wstępu do zwiedzanych obiektów, rejsu statkiem, opłaty lokalnych przewodników, wjazdówek do miast oraz opłaty klimatycznej. Program zwiedzania jest ramowy - może ulec zmianie. Oferta wycieczek skierowana jest dla organizatorów grup. Ceny poszczególnych programów będą kalkulowane na zapytanie. Zainteresowanych prosimy o kontakt z naszym biurem. "
  },

]

export default trips;