// src/pages/Dokumenty.js
import React from 'react';
import FileLink from '../Components/FileLink/FileLink';
import MetaTags from '../Components/MetaTags';

const documentRoot = "/documents/"
const documents = [
  { title: "Katalog Biura Podróży Rajza 2025",fileName: "KATALOG-BP_RAJZA_2025.pdf" },
  { title: "Ogólne Warunki Uczestnictwa", fileName: "/owu/Ogolne_Warunki_Uczestnictwa_BP_RAJZA.pdf"},
  { title: "RODO", fileName: "/rodo/RODO.pdf"},
  { title: "Polityka Prywatności", fileName: "/polityka-prywatnosci/POLITYKA_PRYWATNOSCI.pdf" },
  { title: "Oogólne Warunki Ubezpieczenia Bezpiecznie Rezerwacje", fileName: "/ubezpieczenie/OWU_BEZPIECZNE_REZERWACJE.pdf" },
  { title: "Oogólne Warunki Ubezpieczenia Bezpiecznie Podróże", fileName: "/ubezpieczenie/OWU_Bezpiecznie_Podroze.pdf" },
  { title: "Oogólne Warunki Ubezpieczenia Następstwa Nieszczęliwych Wypadków", fileName: "/ubezpieczenie/OWU_NNW.pdf" },
  { title: "Ubezpieczenie SIGNAL IDUNA Polska TU S.A.", fileName: "/ubezpieczenie/UBEZPIECZENIE.pdf" },
]


function Dokumenty() {
  return (
    <div className="container pt-3 pb-5 documents-page">
      <MetaTags title="Dokumenty do pobrania | Biuro Podróży Rajza" description="Dokumenty do pobrania. Między innymi nasz katalog na najbliższy rok." />
      <h2>Dokumenty do pobrania</h2>
      <FileLink 
        documentRoot={documentRoot}
        documents={documents}
      />
    </div>
  );
}

export default Dokumenty;