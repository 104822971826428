import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation  } from 'react-router-dom';

const MainNavigation = () => {

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Aby nasłuchiwać na zmianę ścieżki

  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    // Zamykamy menu po zmianie ścieżki
    setIsOpen(false);
  }, [location]);

  return (

    <div>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src="/images/logo_rajza.svg" alt="Logo Rajza Travel" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link" to="/wczasy/">Wczasy</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/wycieczki">Wycieczki</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/dokumenty">Dokumenty do pobrania</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </div>
        </div>

      </nav>

    </div>

  );
}

export default MainNavigation;