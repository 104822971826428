const SEA = "Wczasy nad Bałtykiem",
  MOUNTAINS = "Wczasy w górach i inne",
  OTHER_REGIONS = "Inne Regiony",
  HEALTH = "Wczasy zdrowotne",
  ABROAD = "Wczasy zagraniczne",
  BANK_HOLIDAYS = "Świąteczne",
  EASTER = "Wczasy Wielkanocne",
  CURRENCY = "PLN",
  TRIPS = " Wycieczki";


  export { SEA, MOUNTAINS, OTHER_REGIONS, HEALTH, ABROAD, EASTER, BANK_HOLIDAYS, CURRENCY, TRIPS };