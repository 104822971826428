// src/pages/Wycieczki.js
import React from 'react';
import TripsList from '../Components/VacationList/TripList';
import MetaTags from '../Components/MetaTags';

// TODO add helmet here
function Wycieczki() {

  return (
    <div className="container">
      <MetaTags title="Lista ofert wycieczek | Biuro Podróży Rajza" description="Lista ofert wycieczek" />
      <div className="row">
        <TripsList />
      </div>
    </div>
  );
}


export default Wycieczki;