// src/pages/SwiadczenieUslugDrogaElektroniczna.js
import React from 'react';
import FileLink from '../Components/FileLink/FileLink';
import MetaTags from '../Components/MetaTags';

const documentRoot = "/documents/regulamin-swiadczenia-uslug-droga-elektroniczna/"
const documents = [
  { title: "Regulamin świadczenia usług drogą elektroniczą",fileName: "Regulamin_swiadczenia_usług_droga_elektroniczna_BP_RAJZA.pdf" }
]

function SwiadczenieUslugDrogaElektroniczna() {
  return (
    <div className="container">
      <MetaTags title="Regulamin świadczenia usług drogą elektroniczą | Biuro Podróży Rajza" description="Regulamin świadczenia usług drogą elektroniczą" />
      <div className="row">
        <div className="pt-3 pb-3 regulations">

            <h1>REGULAMIN ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ PRZEZ BIURO PODRÓŻY RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń</h1>

            <FileLink 
              documentRoot={documentRoot}
              documents={documents}
            />

            <h2 class="fw-bold">INFORMACJE OGÓLNE</h2>
            <ol>
              <li>Niniejszy regulamin określa rodzaje, zakres i warunki świadczenia usług drogą elektroniczną przez Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń (dalej: BP RAJZA) za pośrednictwem portalu: <a href="https://www.rajza.com.pl" >https://www.rajza.com.pl</a>, prowadzi działalność gospodarczą jako organizator turystyki w oparciu o wpis do Centralnej Ewidencji i Informacji o Działalności Gospodarczej i Rejestru Organizatorów Turystyki i Przedsiębiorców Ułatwiających Nabywanie Powiązanych Usług Turystycznych prowadzonym przez Marszałka Województwa Śląskiego nr 1805.</li>
              <li>BP RAJZA prowadzi działalność pod adresem: Al. Piłsudskiego 32A, 44-335 Jastrzębie-Zdrój; NIP: 633-223-17-87, nr rachunku bankowego: <b>BANK SPÓŁDZIELCZY 78 8470 0001 2015 0081 1013 0001</b>.</li>
              <li>Przez użyte w Regulaminie poniższe określenia należy rozumieć: <br />
                <ol>
                  <li>Podróżny - każdy, kto chce zawrzeć umowę o udział w imprezie turystycznej z BP RAJZA lub jest uprawniony do podróżowania na podstawie zawartej w zakresie stosowania Ustawy;</li>
                  <li>umowa - umowa o imprezę turystyczną zawierana między BP RAJZA a Podróżnym,</li>
                  <li>impreza - impreza turystyczna organizowana przez BP RAJZA,</li>
                  <li>ustawa - ustawa z dnia 24 listopada 2017r. o imprezach turystycznych i powiązanych usługach turystycznych (Dz. U. z 2017 r., poz. 2361).</li>
                  <li>portal – strona internetowa BP RAJZA dostępna pod adresem: <a href="https://www.rajza.com.pl">https://www.rajza.com.pl</a></li>
                </ol>
              </li>
            </ol>

            <h2 class="fw-bold">KONTAKT Z RAJZA</h2>
            <p>Bezpośredni kontakt Podróżnego we wszelkich sprawach związanych z działalnością BP RAJZA jako organizatora turystyki jest możliwy:</p>
            <ol>
              <li>drogą telefoniczną pod nr. tel. 32 471 99 00, 32 473 10 08</li>
              <li>przez adres poczty elektronicznej: <a href="mailto:biuro@rajza.com.pl">biuro@rajza.com.pl</a></li>
              <li>drogą pocztową na adres: BP RAJZA, Al. Piłsudskiego 32A, 44-335 Jastrzębie-Zdrój</li>
            </ol>

            <h2 class="fw-bold">ZASADY ZAWIERANIA UMÓW</h2>
            <ol>
              <li>Zawarte na Portalu BP RAJZA informacje nie stanowią oferty w rozumieniu Kodeksu cywilnego.</li>
              <li>Zawarcie umowy o imprezę turystyczną z BP RAJZA jest możliwe dla Podróżnego spełniającego następujące wymagania techniczne: <br />
                <ol>
                  <li>szerokopasmowe łącze internetowe;</li>
                  <li>przeglądarka internetowa umożliwiająca wyświetlanie stron www;</li>
                  <li>aktywne konto e-mail.</li>
                </ol>
              </li>
              <li>Podróżny w celu zawarcia umowy z BP RAJZA składa wstępne zapytanie w jednej z dwóch możliwych form: <br />
                <ol>
                  <li>przez przesłanie przez Podróżnego wstępnego zapytania na adres poczty elektronicznej BP RAJZA</li>
                  <li>przez kontakt telefoniczny.</li>
                </ol>
              </li>
              <li>BP RAJZA zastrzega sobie prawo do dodatkowej weryfikacji danych Podróżnego, np. w drodze kontaktu telefonicznego.</li>
              <li>Po otrzymaniu wstępnego zapytania Podróżny otrzymuje od BP RAJZA z jego adresu poczty elektronicznej potwierdzenie otrzymania wstępnego zapytania.</li>
              <li>BP RAJZA przesyła Podróżnemu w terminie do 7 dni roboczych na wskazany przez niego adres poczty elektronicznej dokument – umowę-zgłoszenie. Do zawarcia umowy między BP RAJZA a Podróżnym dochodzi na skutek otrzymania przez BP RAJZA skanu podpisanego przez Podróżnego umowy–zgłoszenia w terminie wskazanym w umowie-zgłoszeniu</li>
              <li>Składanie BP RAJZA ofert zawarcia umowy o udział w imprezie turystycznej jest możliwe 24 godziny na dobę przez wszystkie dni w roku.</li>
              <li>BP RAJZA przesyła Podróżnemu drogą elektroniczną wszystkie wymagane przez Ustawę informacje.</li>
            </ol>
            
            <h2 class="fw-bold">ZASADA PŁATNOŚCI</h2>
            <p>Podróżny dokonuje zapłaty ceny w sposób indywidualnie określony w umowie-zgłoszeniu.</p>

            <h2 class="fw-bold">WYŁĄCZENIE ZASTOSOWANIA USTAWY O PRAWACH KONSUMENTA</h2>
            <p>W przypadku gdy stroną zawieranej umowy jest konsument, BP RAJZA informuje, że zgodnie z art. 3 ust. 1 pkt 8 ustawy z dnia 30 maja 2014r. o prawach konsumenta (Dz. U. z 2017r., poz. 683 ze zm.) przepisów tej ustawy nie stosuje się do umowy o udział w imprezie turystycznej. Oznacza to m.in., że Podróżnemu nie przysługuje prawo do odstąpienia od umowy w terminie 14 dni.</p>

            <h2 class="fw-bold">REKLAMACJA</h2>
            <ol>
              <li>Reklamacje związane z korzystaniem z usług portalu należy składać na adres e-mail: <a href="mailto:biuro@rajza.com.pl">biuro@rajza.com.pl</a> lub na wskazany wyżej adres stacjonarny.</li>
              <li>W przypadku reklamacji składanych na podstawie Ustawy, zastosowanie znajdują zasady określone w Ustawie oraz warunkach uczestnictwa.</li>
            </ol>

            <h2 class="fw-bold">OCHRONA DANYCH OSOBOWYCH</h2>
            <ol>
            <li> Współadministratorami Pani/Pana danych osobowych są: Barbara Szulańczyk, Anita Kuglarz, Wioleta Ledwoń - przedsiębiorcy prowadzący działalność jako „Biuro Podróży RAJZA Spółka Cywilna B.Szulańczyk, A.Kuglarz, W.Ledwoń” z siedzibą: 44-335 Jastrzębie-Zdrój al. Piłsudskiego 32A. </li>
            <li> Przetwarzanie danych osobowych Podróżnego odbywa się w celu realizacji umowy, prawnie uzasadnionych interesów administratora lub w innych celach zgodnie z wyrażoną zgodą, jeśli zgoda taka została wyrażona i stanowi właściwą podstawę prawną dla przetwarzania danych osobowych. </li>
            <li> Przetwarzanie danych osobowych Podróżnego będzie trwało nie dłużej, niż jest to konieczne do realizacji określonych celów. Okres przechowywania danych osobowych jest ustalony w ścisłej zgodności z obowiązującymi przepisami prawa. </li>
            <li> Dane osobowe Podróżnego mogą zostać udostępnione na podstawie przepisów prawa, prawnie uzasadnionych interesów administratora lub w celu realizacji umowy podmiotom przetwarzającym - firmom świadczącym usługi na zlecenie Administratora, którym zleca on czynności wymagające przetwarzania danych, w szczególności w zakresie usług księgowych, IT, firmom ubezpieczeniowym, poradniom terapeutycznym i terapeutom prowadzącym zajęcia na turnusie </li>
            <li> Podanie przez Podróżnego danych jest dobrowolne, jednak niezbędne do zawarcia umowy i prawidłowej realizacji usługi. </li>
            <li> Podróżnemu przysługuje prawo do żądania dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, a także prawo wniesienia sprzeciwu wobec przetwarzania, prawo do przenoszenia swoich danych oraz wniesienia skargi do organu nadzorczego. Podróżny ma prawo cofnięcia zgody w dowolnym momencie, jeśli to właśnie zgoda stanowi właściwą podstawę prawną dla przetwarzania danych osobowych. Jeżeli posiadane dane przetwarzane są na potrzeby realizacji innych celów (np. realizacji umowy, dochodzenia roszczeń), mimo wycofania zgody mogą być dalej przetwarzane na innej podstawie prawnej. </li>
            </ol>

            <h2 class="fw-bold">OBSŁUGA PLIKÓW COOKIES</h2>
            <p>Korzystanie ze strony internetowej <a href="https://www.rajza.com.pl" >https://www.rajza.com.pl</a> oznacza zgodę na używanie plików Cookies, , zgodnie z aktualnymi ustawieniami przeglądarki. Jeżeli korzystający ze strony nie wyraża zgody na korzystanie z plików Cookies, może w każdej chwili zmienić ustawienia swojej przeglądarki.</p>

            <h2 class="fw-bold">POSTANOWIENIA KOŃCOWE</h2>
            <p>Zakazuje się dostarczania przez Podróżnych w związku z korzystaniem z Portalu treści o charakterze bezprawnym. W związku z korzystaniem z usług portalu nie istnieją żadne szczególne zagrożenia. BP RAJZA zwraca jednak uwagę, że korzystanie z Portalu w związku ze świadczonymi przez BP RAJZA usługami może wiązać się z ogólnym zagrożeniem po stronie Podróżnego jako użytkownika Internetu. We wszelkich sprawach nie uregulowanych w powyższym regulaminie mają zastosowanie przepisy Kodeksu cywilnego oraz innych aktów prawa powszechnie obowiązujących.</p>
        </div>
      </div>
    </div>
  );
}

export default SwiadczenieUslugDrogaElektroniczna;